<template>
  <div class="container" ref="container">
    <a-spin tip="获取中" :spinning="spinning">
      <a-tabs :default-active-key="1" class="tabs">
        <a-tab-pane :key="index + 1" :tab="item" v-for="(item, index) in tabsList">
          <div class="goodsList">
            <template v-if="tabsDataArr[index].length">
              <div class="listItem" @click="showSkuDetail(it)" v-for="(it, idx) in tabsDataArr[index]" :key="idx">
                <div class="imgBox">
                  <img :src="it.thumbnail.substring(0, it.thumbnail.lastIndexOf('?'))" alt="图片加载失败" />
                </div>
                <div class="goodsInfo">
                  <div class="top">{{ it.goodsName || '抱歉，未获取到呀' }}</div>
                  <div class="center">
                    <span>￥</span>
                    {{ it.price || '抱歉，未获取到呀' }}
                  </div>
                  <!-- <div class="btm">原料剩 80</div> -->
                </div>
              </div>
            </template>
            <template v-else>
              <div class="emptyBox">
                <img src="../../assets/payFail.png" alt="暂无数据图片" />
                <div>暂无商品</div>
              </div>
            </template>
          </div>
        </a-tab-pane>
      </a-tabs>
    </a-spin>
    <!-- 加入购物车modal -->
    <a-modal
      :visible="joinCartVisible"
      @cancel="joinCartCancel"
      destroyOnClose
      width="600px"
      :afterClose="joinCartAfterClose"
      class="joinCartModal"
      :getContainer="() => $refs.container"
    >
      <template slot="title"></template>
      <!-- 内容 -->
      <div class="content">
        <div class="top">
          <div class="name">{{ currentName || '抱歉，未获取到' }}</div>
          <div class="skuItem" v-for="(item, index) in specList" :key="index">
            <div class="skuTitle">{{ item.name || '甜度【推荐5分糖' }}</div>
            <div class="skuList">
              <div :class="['item', checkSpec.includes(it) ? 'choose' : '']" v-for="(it, idx) in item.data" :key="idx" @click="chooseSpec(it, index)">
                {{ it }}
              </div>
            </div>
          </div>

          <div class="skuItem" v-if="materials.length">
            <div class="skuTitle">配料</div>
            <div class="skuList">
              <div :class="['item', obj.checked ? 'choose' : '']" v-for="obj in materials" :key="obj.id" @click="chooseMaterials(obj)">
                {{ obj.name }}
                <span>| ￥{{ obj.price }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="alreadySelSku">
          <span :style="{ color: 'rgba(0, 0, 0, 0.65)' }">已选规格：</span>
          {{ checkSpec.length || checkedMaterialsName.length ? checkSpec.concat(checkedMaterialsName).join('+') : '抱歉，未获取到' }}
        </div>
      </div>
      <template slot="footer">
        <span class="payMoney">
          <span :style="{ color: 'rgba(0, 0, 0, 0.65)' }">总计：</span>
          ￥{{ total || 0 }}
        </span>
        <a-button :loading="!BtnCanUse" @click="joinCartEvent" :disabled="!BtnCanUse" class="joinCartBtn">
          加入购物车
        </a-button>
      </template>
    </a-modal>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex';
import { goodsList, goodsSkuDetail } from '@/api/goods';

export default {
  name: 'goods',
  components: {},
  data() {
    return {
      tabsList: [],
      tabsDataArr: [],
      joinCartVisible: false,
      spinning: false,
      specList: [], // 规格显示
      simpleSpecsList: [], // 过滤所有sku的包含id specs price的集合
      checkSpec: [],
      checkedMaterialsName: [],
      materials: [],
      currentName: '',
      currentGoodsSkuInfo: {},
      price: 0
    };
  },
  mounted() {
    this.getGoodsData(true);
    this.SET_BTN_USE_STATE(true);
  },
  computed: {
    ...mapState(['BtnCanUse', 'AllGoodList', 'AllGoodInfo', 'ShopData']),
    total() {
      let materialPrice = 0;
      this.materials.map(e => {
        if (e.checked) {
          materialPrice += e.price;
        }
      });
      return (this.price + materialPrice).toFixed(2);
    }
  },
  methods: {
    ...mapMutations(['SET_BTN_USE_STATE', 'SET_ALL_GOOD_LIST', 'SET_ALL_GOOD_INFO']),
    // 加入购物车触发
    joinCartEvent() {
      // if (this.currentGoodsSkuInfo.marketEnable !== 'UPPER') {
      this.SET_BTN_USE_STATE(false);
      //   return this.$message.warning('抱歉，商品下架了');
      // }
      // if (!this.currentGoodsSkuInfo.quantity) {
      //   return this.$message.warning('抱歉，商品库存不足');
      // }
      let param = {
        storeId: this.ShopData.id || '',
        cartType: 'CASHIER_CART',
        num: 1,
        skuId: '',
        materials: {}
      };

      this.simpleSpecsList.map(e => {
        if (e.specs === this.checkSpec.join(' ')) {
          param.skuId = e.id;
        }
      });

      // 过滤出选中的小料
      this.materials.map(e => {
        if (e.checked) {
          param.materials[e.id] = 1;
        }
      });
      console.log('添加商品到购物车: ', param);
      if (param.skuId.length > 0 && this.simpleSpecsList.length > 0) {
        // 添加商品到购物车
        this.SET_BTN_USE_STATE(false);
        this.$root.Bus.$emit('cartChange', param);
        this.joinCartVisible = false;
      } else {
        this.$message.warning('抱歉，加入购物车失败');
      }
    },
    joinCartCancel() {
      this.joinCartVisible = false;
    },
    joinCartAfterClose() {
      // 清空选择的小料
      this.checkedMaterialsName = [];
      this.simpleSpecsList = [];
      this.checkSpec = [];
      this.specList = [];
    },
    // 展示商品选择sku详情
    showSkuDetail(item) {
      this.spinning = true;
      this.currentName = item.goodsName || '';
      //  item.marketEnable === 'UPPER'
      if (this.AllGoodInfo[item.id]) {
        this.setSkuDetail(this.AllGoodInfo[item.id]);
      } else {
        // 获取商品sku详情
        goodsSkuDetail(item.id).then(res => {
          if (res) {
            let map = new Map();
            map.set(res.id, res);
            this.SET_ALL_GOOD_INFO(map);
            this.setSkuDetail(res);
          }
          // else {
          //   this.$message.warning('抱歉，商品信息加载失败');
          // }
          this.spinning = false;
          this.joinCartVisible = true;
        });
      }
    },
    setSkuDetail(res) {
      this.currentGoodsSkuInfo = res;
      this.SET_BTN_USE_STATE(true);
      // if (res.marketEnable !== 'UPPER') {
      //   this.SET_BTN_USE_STATE(false);
      // } else {
      //   this.SET_BTN_USE_STATE(true);
      // }
      if (res.skuList && res.skuList.length > 0) {
        res.skuList.map((data, i) => {
          // 获取每个规格id 信息 价格组成集合
          this.simpleSpecsList.push({
            id: data.id,
            specs: data.simpleSpecs.trim(),
            price: data.price
          });
          // 显示第一个sku的价格
          if (i === 0) {
            this.price = data.price;
          }

          if (data.specList.length > 0) {
            for (let i = 1; i < data.specList.length; i += 1) {
              let noSpecName = true;
              this.specList.map((e, index) => {
                let specName = data.specList[i].specName;
                let specValue = data.specList[i].specValue;
                if (e.name === specName) {
                  noSpecName = false;
                  if (!e.data.includes(specValue)) {
                    this.specList[index].data.push(specValue);
                  }
                }
              });

              if (noSpecName) {
                this.specList.push({
                  name: data.specList[i].specName,
                  data: [data.specList[i].specValue]
                });
                this.checkSpec.push(data.specList[i].specValue);
              }
            }
          }
          this.materials = [];
          res.materials.map(e => {
            this.materials.push({
              id: e.id,
              name: e.name,
              price: e.price,
              checked: false
            });
          });
        });
      }
      this.spinning = false;
      this.joinCartVisible = true;
    },
    chooseSpec(it, index) {
      let arr = [];
      for (let i = 0; i < this.checkSpec.length; i++) {
        if (i === index) {
          arr.push(it);
        } else {
          arr.push(this.checkSpec[i]);
        }
      }
      this.checkSpec = arr;
      this.simpleSpecsList.map(e => {
        if (e.specs === arr.join(' ')) {
          this.price = e.price;
        }
      });
    },
    getGoodsData(isFirst = false) {
      if (this.ShopData.id) {
        this.spinning = true;
        if (Object.keys(this.AllGoodList).length > 0 && !isFirst) {
          this.setGoodsData(this.AllGoodList);
        } else {
          goodsList({ storeId: this.ShopData.id || '', type: 'cashier' }).then(res => {
            this.setGoodsData(res);
            for (let i in res) {
              if (res[i].length > 0) {
                res[i].map(e => {
                  // if (e.marketEnable !== 'UPPER') {
                  let map = new Map();
                  map.set(e.id, {});
                  this.SET_ALL_GOOD_INFO(map);
                  // }
                });
              }
            }
          });
        }
      }
    },
    setGoodsData(res) {
      this.SET_ALL_GOOD_LIST(res);
      this.tabsList = Object.keys(res);
      this.tabsDataArr = Object.values(res);
      this.spinning = false;
    },
    chooseMaterials(obj) {
      this.checkedMaterialsName = [];
      this.materials.map(e => {
        if (e.id === obj.id) {
          obj.checked = !obj.checked;
        }
        if (e.checked) {
          this.checkedMaterialsName.push(e.name);
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.container {
  height: 100%;
  display: flex;
  position: relative;
  padding: 0 13px 15px;

  /deep/ .ant-spin-nested-loading {
    width: 100%;
    .ant-spin-container {
      height: 100%;
      > div {
        height: 100%;
      }
      .ant-tabs-content {
        height: 100vh;
      }
    }
  }
  .tabs {
    width: 100%;

    /deep/ .ant-tabs-bar {
      border: none;
    }

    /deep/ .ant-tabs-tabpane {
      height: 100%;
    }
    /deep/ .ant-tabs-nav-container {
      box-shadow: 1px 0px 10px rgb(206, 235, 235);
    }
    /deep/ .ant-tabs-nav {
      > div:first-child {
        background: linear-gradient(270deg, #eaffff 0%, #ffffff 100%);
        color: #000;
        font-size: 20px;
        border-radius: 10px;
        padding: 0 20px;
        font-weight: 500;
        display: flex;
      }
      > .ant-tabs-ink-bar {
        height: 4px;
        border-radius: 7px;
        left: 20px;
        bottom: 4px;
      }
    }

    .goodsList {
      // height: calc(100vh - 215px);
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      overflow: auto;
      align-content: flex-start;
      padding-bottom: 200px;
      position: relative;
      .emptyBox {
        position: absolute;
        left: 50%;
        top: 30%;
        transform: translateX(-50%);
        div {
          margin-top: 15px;
          color: #fff;
          text-align: center;
          font-size: 18px;
        }
      }

      .listItem {
        width: 300px;
        height: 150px;
        background: #fff;
        border-radius: 15px;
        display: flex;
        margin-right: 21px;
        padding: 15px 4px 15px 7px;
        margin: 0 21px 21px 13px;
        .imgBox {
          width: 125px;
          height: 100%;
          background-color: #eee;
          img {
            width: 100%;
            height: 100%;
            border-radius: 8px;
          }
        }
        .goodsInfo {
          height: 100%;
          flex: 1;
          padding-left: 10px;
          .top {
            margin-top: 13px;
            font-size: 17px;
            color: #000;
            height: 78px;
            word-break: break-all;
          }
          .center {
            margin: 5px 0;
            font-size: 17px;
            font-weight: 500;
            color: #ff7a45;
          }
          .btm {
            font-weight: 500;
            font-size: 17px;
            color: #fff;
          }
        }
      }
    }
  }

  .joinCartModal {
    /deep/ .ant-modal-wrap {
      position: absolute;
    }
    /deep/ .ant-modal-mask {
      position: absolute;
    }
    /deep/ .ant-modal-content {
      background-color: rgba(114, 197, 199, 1);
    }
    /deep/ .ant-modal-footer {
      border: none;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #fff;
      height: 54px;
      padding: 0 0 0 24px;

      .joinCartBtn {
        color: #fff;
        display: flex;
        align-items: center;
        font-weight: 500;
        border-radius: 0;
        height: 100%;
        border: none;
        font-size: 18px;
        background: rgba(255, 122, 69, 1);
        span {
          margin-left: 3px;
        }
      }
    }

    /deep/ .ant-modal-body {
      padding: 0;
    }
    .content {
      background: #fff;
      .top {
        padding: 24px;
        color: #000;
      }
      .name {
        font-size: 19px;
        font-weight: 500;
      }
      .skuTitle {
        font-size: 17px;
        font-weight: 500;
        margin-bottom: 10px;
      }
      .skuList {
        display: flex;
        flex-wrap: wrap;
        .item.choose {
          background-color: #43c2c2;
          color: rgba(255, 255, 255, 1);
        }
      }
      .skuItem {
        margin-top: 10px;
      }
      .item {
        background-color: #fff;
        color: rgba(0, 0, 0, 0.65%);
        font-size: 17px;
        border: 1px solid #ccc;
        margin: 0 15px 10px 0;
        border-radius: 6px;
        padding: 5px 28px;
        white-space: nowrap;
        cursor: pointer;

        span {
          font-weight: 500;
          font-size: 14px;
        }
      }
    }
    .alreadySelSku {
      color: rgba(0, 0, 0, 0.85);
      font-size: 15px;
      padding: 10px 24px;
      background-color: #f6f6f9;
    }
  }
}
.payMoney {
  font-weight: 700;
  font-size: 22px;
  color: rgba(255, 122, 69, 1);
  span {
    font-size: 16px;
  }
}
</style>
