<template>
  <div>
    <div class="content">
      <div class="row-sb left">
        <div class="btn-white" v-for="item in [1, 2, 3, 4, 5, 6, 7, 8, 9, '00', 0, '.']" :key="item" @click="chooseNum(item)">
          {{ item }}
        </div>
      </div>
      <div class="right">
        <div class="btn-white" @click="delNum">X</div>
        <div class="btn-white" @click="clearNum">清空</div>
        <div class="btn-green" @click="enterNum">确定</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    clear: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      inputNum: ''
    };
  },
  watch: {
    clear() {
      this.inputNum = '';
    }
  },
  methods: {
    chooseNum(item) {
      if (item === '.') {
        if (this.inputNum.includes('.') || this.inputNum.length === 0) {
          return;
        }
      }
      if (this.inputNum.includes('.') && this.inputNum.split('.')[1].length === 2) {
        return;
      }
      if (item === '00' && (this.inputNum.length === 0 || this.inputNum === '0')) {
        return;
      }
      this.inputNum = this.inputNum + item;
      this.$emit('changeNum', item);
    },
    delNum() {
      this.inputNum = this.inputNum.substr(0, this.inputNum.length - 1);
      this.$emit('delNum', this.inputNum);
    },
    clearNum() {
      this.inputNum = '';
      this.$emit('clearNum');
    },
    enterNum() {
      this.$emit('enterNum', this.inputNum);
    }
  }
};
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  height: 100%;
  padding-top: 2%;
  display: flex;
  justify-content: space-around;
  align-content: space-between;
  background-color: rgba(246, 246, 249, 1);
}

.row-sb {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin btnWhite {
  border-radius: 4px;
  line-height: 60px;
  background-color: #fff;
  height: 60px;
  cursor: pointer;
}

.left {
  width: 72%;
  flex-wrap: wrap;

  .btn-white {
    @include btnWhite;
    width: 32%;
    margin-bottom: 3%;
  }
}

.right {
  width: 24%;
  font-size: 21px;
  .btn-white {
    width: 98%;
    margin-bottom: 9%;
    @include btnWhite;
  }
}

.btn-green {
  line-height: 137px;
  height: 137px;
  border-radius: 10px;
  color: #fff;
  background-color: #72c5c7;
}
</style>
