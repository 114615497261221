<template>
  <div class="entire">
    <div class="title-area">
      <div class="font">商品信息</div>
      <div class="selNum">已选 {{ (cartData.skuList && cartData.skuList.length) || 0 }}</div>
    </div>
    <div class="cart-list Scroll">
      <template v-if="cartData.skuList && cartData.skuList.length">
        <div class="cart-block" v-for="(good, index) in cartData.skuList" :key="good.uuId">
          <div class="sort">{{ (index + 1).toString().padStart(2, 0) }}</div>
          <div class="good">
            <div class="row-sb">
              <div class="name">{{ good.goodsSku.goodsName || '' }}&nbsp;&nbsp;￥{{ good.goodsSku.price }}</div>
              <div class="num">
                <a-icon
                  v-if="good.num === 1"
                  type="minus-circle"
                  theme="filled"
                  :style="{ fontSize: '30px', color: '#b9b5b5' }"
                  @click="operateGood('good-minus', good)"
                />
                <a-icon
                  v-else
                  type="minus-circle"
                  :style="{ fontSize: '30px', color: 'rgba(67, 194, 194, 1)' }"
                  @click="operateGood('good-minus', good)"
                />
                &nbsp;&nbsp;&nbsp;&nbsp;
                <div>{{ good.num || 0 }}</div>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <a-icon type="plus-circle" :style="{ fontSize: '30px', color: 'rgba(67, 194, 194, 1)' }" @click="operateGood('good-add', good)" />
              </div>
              <a-icon type="delete" :style="{ fontSize: '30px', color: 'red' }" @click="operateGood('good-del', good)" />
            </div>
            <div class="norm">{{ good.goodsSku.simpleSpecs }}</div>
            <div class="row-sb material-row" v-for="item in good.materialVOs" :key="item.id">
              <div class="small-material">{{ item.name }}&nbsp;&nbsp;￥{{ item.price }}</div>
              <div class="num">
                <a-icon
                  v-if="item.num === 1"
                  type="minus-circle"
                  theme="filled"
                  :style="{ fontSize: '30px', color: '#b9b5b5' }"
                  @click="operateGood('material-minus', good, item)"
                />
                <a-icon
                  v-else
                  type="minus-circle"
                  :style="{ fontSize: '30px', color: 'rgba(67, 194, 194, 1)' }"
                  @click="operateGood('material-minus', good, item)"
                />
                &nbsp;&nbsp;&nbsp;&nbsp;
                <div>{{ item.num }}</div>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <a-icon
                  type="plus-circle"
                  :style="{ fontSize: '30px', color: 'rgba(67, 194, 194, 1)' }"
                  @click="operateGood('material-add', good, item)"
                />
              </div>
              <a-icon type="delete" :style="{ fontSize: '30px', color: 'red' }" @click="operateGood('material-del', good, item)" />
            </div>
            <!-- price start -->
            <div class="row-sb price-area">
              <div>
                <div class="price-name">
                  单价
                </div>
                <div>
                  <span>&nbsp;&nbsp;￥{{ good.goodsSku.price + cartData.cartList[0].skuList[index].materialSinglePrice }}</span>
                </div>
              </div>
              <div>
                <div class="price-name">
                  总价
                </div>
                <div class="price-all">
                  <span
                    v-if="good.subTotal != cartData.cartList[0].skuList[index].singleReducedPrice + cartData.cartList[0].skuList[index].materialPrice"
                  ></span>
                  <span v-else>￥{{ good.subTotal }}</span>
                  <span
                    v-if="good.subTotal != cartData.cartList[0].skuList[index].singleReducedPrice + cartData.cartList[0].skuList[index].materialPrice"
                    style="margin-left: 10px;"
                  >
                    ￥{{ countAllPrice(1, cartData, index) }}
                  </span>
                </div>
              </div>
            </div>
            <!-- price end -->
            <div style="margin-top: 20px;">
              <button class="Btn-Default" @click="reduced(good)">单品优惠</button>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="emptyBox">
          <img src="../assets/payFail.png" alt="暂无商品图片" />
          <div>请添加商品</div>
        </div>
      </template>
    </div>

    <div class="settlement-area">
      <div class="row-sb settlement-row payInfoBox">
        <div class="clearBox" @click="clearCartCar">
          <img class="icon" src="@/assets/deleteImg.png" alt="logo" @click="operateGood('material-del', good, item)" />
          清空
        </div>
        <div class="all-price">
          总额：
          <span class="price">￥{{ countAllPrice(2, cartData, 0) }}</span>
        </div>
        <div :style="{ display: 'flex' }">
          <div>
            折扣：
            <span class="price">
              ￥{{
                (cartData.priceDetailDTO &&
                  (cartData.priceDetailDTO.totalReducedPrice ||
                    cartData.priceDetailDTO.discountPrice ||
                    cartData.priceDetailDTO.couponPrice ||
                    cartData.priceDetailDTO.faceValue)) ||
                0
              }}&nbsp;&nbsp;
            </span>
          </div>
          <div
            :style="{ color: 'rgba(51, 152, 154, 1)' }"
            v-if="
              cartData.priceDetailDTO &&
              (cartData.priceDetailDTO.totalReducedPrice ||
                cartData.priceDetailDTO.discountPrice ||
                cartData.priceDetailDTO.couponPrice ||
                cartData.priceDetailDTO.faceValue)
            "
          >
            {{ discountType() }}
          </div>
        </div>
      </div>
      <div class="row-sb settlement-row btmBox">
        <div>
          <div>实付</div>
          <div :style="{ color: 'rgba(255, 122, 69, 1)', fontWeight: '500' }">
            ￥{{ (cartData.priceDetailDTO && cartData.priceDetailDTO.flowPrice) || 0 }}
          </div>
        </div>
        <div class="btnBox">
          <button class="btmBtn" @click="thirdPartyOpen">三方优惠</button>
          <button class="btmBtn" @click="defineCartCar">支付</button>
        </div>
      </div>
    </div>

    <PayInfoModel
      :confirmOrder="confirmOrder"
      :totalPrice="cartData.priceDetailDTO && cartData.priceDetailDTO.flowPrice"
      @cancelOrder="cancelOrder"
      @payOrder="payOrder"
    />

    <!-- 单品优惠 -->
    <SingleProductDiscount :show="reducedShow" @cancel="reducedShow = false" @enterNum="reducedConfirm" :totalPrice="singleGoodPrice" />

    <!-- 三方优惠 -->
    <ThirdPartyDiscount :show="thirdShow" @cancel="thirdShow = false" @enterNum="thirdPartyDiscount" :totalPrice="singleGoodPrice" />

    <!-- 支付成功失败弹窗 -->
    <a-modal
      :title="null"
      :footer="null"
      class="payResModal"
      :visible="payResVisible"
      @ok="
        () => {
          this.payResVisible = false;
        }
      "
      @cancel="
        () => {
          this.payResVisible = false;
        }
      "
    >
      <template v-if="payStatus === 1">
        <div>
          <img src="../assets/paySuccess.png" alt="支付成功" />
          <div>支付成功</div>
        </div>
      </template>
      <template v-if="payStatus === 0">
        <div>
          <img src="../assets/payFail.png" alt="支付失败" />
          <div>支付失败,前往订单列表支付！</div>
        </div>
      </template>
      <template v-if="payStatus === '' || payStatus === 2">
        <div>
          <img src="../assets/payLoading.png" alt="支付中" />
          <div>支付中...</div>
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script>
import Util from '@/libs/util';
import { mapState, mapMutations } from 'vuex';
import api from '@/api/cart';
import PayInfoModel from '@/components/PayInfoModel';
import SingleProductDiscount from '@/components/SingleProductDiscount';
import ThirdPartyDiscount from '@/components/ThirdPartyDiscount';
import { message } from 'ant-design-vue';
import { setStore, getStore, removeStore } from '@/libs/storage';

export default {
  props: {
    cartList: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  components: {
    PayInfoModel,
    SingleProductDiscount,
    ThirdPartyDiscount
  },
  data() {
    return {
      confirmOrder: false,
      cartData: {},
      operateTime: 0,
      payType: '', // WECHAT ALIPAY
      payCode: '',
      intervalId: null,
      count: 0,
      reducedShow: false, // 单品优惠
      thirdShow: false, // 三方优惠
      singleGoodPrice: 0,
      uuId: '',
      payIntervalId: null,
      payResVisible: false,
      payStatus: ''
    };
  },
  created() {
    this.getData();

    // 加入购物车
    this.$root.Bus.$on('cartChange', e => {
      if (new Date().getTime() - this.operateTime > 1000) {
        if (Object.keys(e).length > 0) {
          this.changeCart(e);
        } else {
          this.getData();
        }
      }
      this.operateTime = new Date().getTime();
    });
  },
  computed: {
    ...mapState(['BtnCanUse', 'MemberInfo', 'PromotionCheckType', 'ShopData'])
  },
  methods: {
    ...mapMutations(['SET_BTN_USE_STATE', 'SET_CART_GOOD_INFO', 'SET_MEMBER_TOKEN', 'SET_MEMBER_INFO', 'SET_PROMOTION_TYPE', 'SET_BTN_USE_STATE']),
    getData() {
      const userId = this.ShopData.id;
      if (userId) {
        api
          .getCartCheck({
            storeId: userId,
            cartType: 'CASHIER_CART',
            promotionCheckType: this.PromotionCheckType
          })
          .then(res => {
            this.cartData = {};
            this.cartData = res;
            let scopeId = '';
            if (res.skuList && res.skuList.length > 0) {
              let goodsInfo = [];
              res.skuList.map(e => {
                goodsInfo.push({
                  goodsSkuId: e.goodsSku.id,
                  num: e.num,
                  materialPrice: e.materialPrice
                });
                if (scopeId.length == 0) {
                  scopeId = e.goodsSku.id;
                } else {
                  scopeId = scopeId + ',' + e.goodsSku.id;
                }
              });
              this.SET_CART_GOOD_INFO({
                totalPrice: res.priceDetailDTO.goodsPrice + res.priceDetailDTO.materialPrice,
                scopeId,
                goodsInfo
              });
            } else {
              this.SET_CART_GOOD_INFO({});
            }
          });
      }
    },
    // 修改商品数量，小料数量
    operateGood(type, good, item = {}) {
      if (good) {
        let params = {
          cartType: 'CASHIER_CART',
          storeId: this.ShopData.id,
          skuId: good && good.goodsSku.id,
          uuId: good && good.uuId,
          num: good && good.num,
          materials: {}
        };
        good.materialVOs.map(e => {
          params.materials[e.id] = e.num;
        });
        switch (type) {
          case 'good-minus':
            if (good.num === 1) {
              return;
            }
            params.num = good.num - 1;
            break;
          case 'good-add':
            params.num = good.num + 1;
            break;
          case 'good-del':
            params.num = 0;
            break;
          case 'material-minus':
            if (item.num === 1) {
              return;
            }
            params.materials[item.id] = item.num - 1;
            break;
          case 'material-add':
            params.materials[item.id] = item.num + 1;
            break;
          case 'material-del':
            delete params.materials[item.id];
            break;
          default:
            break;
        }

        api.postChangeCart(params).then(res => {
          this.$message.success('修改成功');
          this.getData();
        });
        this.$emit('operateGood');
      }
    },
    // 新增商品或修改商品
    changeCart(item) {
      // let params = item;

      // let nowMaterialIds = ''; // 当前选中的小料组合
      // for (let id in item.materials) {
      //   item.materials[id] = 1;
      //   if (nowMaterialIds.length > 0) {
      //     nowMaterialIds = nowMaterialIds + ',' + id;
      //   } else {
      //     nowMaterialIds = id;
      //   }
      // }

      // let allMaterials = []; // 购物车里所有小料组合列表
      // let materialIds = ''; // 购物车里一种的小料组合
      // let uuIdList = [];
      // let skuList = [];
      // this.cartData.skuList.map(el => {
      //   uuIdList.push(el.uuId);
      //   skuList.push(el.goodsSku.id);
      //   if (el.materialVOs) {
      //     el.materialVOs.map(e => {
      //       if (materialIds.length > 0) {
      //         materialIds = materialIds + ',' + +e.id;
      //       } else {
      //         materialIds = e.id;
      //       }
      //     });
      //   }
      //   allMaterials.push(materialIds);
      //   materialIds = '';
      // });
      // // 购物车里存在相同规格和小料的商品
      // if (allMaterials.indexOf(nowMaterialIds) > -1 && skuList.includes(item.skuId)) {
      //   params.uuId = uuIdList[allMaterials.indexOf(nowMaterialIds)];
      //   params.num = this.cartData.skuList[allMaterials.indexOf(nowMaterialIds)].num + 1;
      // }
      api.postChangeCart(item).then(() => {
        this.$message.success('已加入购物车');
        this.SET_BTN_USE_STATE(true);
        this.getData();
      });
    },
    clearCartCar() {
      if (Object.keys(this.cartData).length === 0 || (this.cartData.skuList && this.cartData.skuList.length === 0)) {
        this.cartData = {};
        this.SET_CART_GOOD_INFO({});
      } else {
        api
          .delClearCart({ storeId: this.ShopData.id, cartType: 'CASHIER_CART' })
          .then(() => {
            api.getCartAll({ storeId: this.ShopData.id, cartType: 'CASHIER_CART' }).then(res => {
              this.cartData = res;
              let scopeId = '';
              if (res.skuList && res.skuList.length > 0) {
                let goodsInfo = [];
                res.skuList.map(e => {
                  goodsInfo.push({
                    goodsSkuId: e.goodsSku.id,
                    num: e.num,
                    materialPrice: e.materialPrice
                  });
                  if (scopeId.length == 0) {
                    scopeId = e.goodsSku.id;
                  } else {
                    scopeId = scopeId + ',' + e.goodsSku.id;
                  }
                });
                this.SET_CART_GOOD_INFO({
                  totalPrice: res.priceDetailDTO.goodsPrice + res.priceDetailDTO.materialPrice,
                  scopeId,
                  goodsInfo
                });
              } else {
                this.SET_CART_GOOD_INFO({});
              }
            });
          })
          .catch(err => {
            console.log('clear cart fail: ', err);
            this.getData();
          });
      }
      this.SET_PROMOTION_TYPE(1);
      this.$emit('clearCartCar');
    },
    defineCartCar() {
      if (this.cartData.skuList.length > 0) {
        this.confirmOrder = true;
      } else {
        this.$message.warning('购物车空！');
      }
      this.$emit('defineCartCar');
    },
    payOrder(obj) {
      let storeId = this.ShopData.id;
      if (!this.BtnCanUse) {
        return;
      }
      this.SET_BTN_USE_STATE(false);
      api
        .postBookOrder({
          cartType: 'CASHIER_CART',
          storeId,
          way: 'CASHIER_CART',
          client: 'CASHIER',
          remark: [
            {
              storeId
            }
          ],
          shippingMethod: 'SELF_PICK_UP'
        })
        .then(order => {
          api
            .getQRCodePay({ sn: order.sn, ...obj })
            .then(res => {
              this.payResVisible = true;
              // payStatus : 0-支付失败 1-支付成功 2-支付中
              if (res.payStatus === 1) {
                this.payStatus = res.payStatus;
                message.success(`顾客成功支付${res.payPrice}元`);
                Util.payVoiceTips(`${obj.payType === 'ALIPAY' ? '支付宝' : obj.payType === 'WECHAT' ? '微信' : ''}成功支付${res.payPrice}元`);
              } else if (res.payStatus === 0) {
                message.error('收款失败：' + res.msg);
                this.payStatus = res.payStatus;
                Util.payVoiceTips('支付失败，请刷新付款码重新支付');
              } else if (res.payStatus === 2) {
                this.payStatus = res.payStatus;
                message.warning(res.msg);
                Util.payVoiceTips('请输入支付密码');
                this.payIntervalId = setInterval(() => {
                  // 查看是否支付成功 返回支付结果
                  api.searchPayRes(order.sn).then(result => {
                    if (result === 'SUCCESS') {
                      this.payStatus = 1;
                      // 轮训查询支付结果 保存当前支付的金额 和方式
                      setStore('trainPayInfo', { payType: obj.payType, payPrice: res.payPrice, sn: order.sn });
                      this.count = 0;
                      this.intervalId = setInterval(this.resCallback, 5000);
                      // 用户支付成功
                      clearInterval(this.payIntervalId);
                    } else if (result === 'USERPAYING') {
                      // 用户支付中 轮训调用
                      this.payStatus = 2;
                      // this.getPayRes(order.sn);
                    } else if (result === 'PAYERROR') {
                      this.payStatus = 0;
                      clearInterval(this.payIntervalId);
                      message.warning('用户支付失败，请前往订单列表支付');
                      Util.payVoiceTips('用户支付失败，请前往订单列表支付');
                    }
                  });
                }, 5000);
              }
              this.SET_MEMBER_TOKEN();
              this.SET_MEMBER_INFO();
              this.getData();
              this.cancelOrder();
            })
            .catch(err => {
              console.log('payOrder: ', err);
              message.error('收款失败，请清空付款码后重试');
              Util.payVoiceTips('收款失败，请刷新付款码重新支付');
            })
            .finally(() => {
              this.SET_BTN_USE_STATE(true);
            });
        });
    },
    // 支付结果查询
    resCallback() {
      // 支付回调接口
      this.count++;
      // 重置信息
      if (this.count >= 9) {
        removeStore('trainPayInfo');
        clearInterval(this.intervalId);
        this.interval = null;
      }
      const trainPayInfo = JSON.parse(getStore('trainPayInfo'));
      api
        .payCallback({
          orderType: 'TRADE',
          sn: trainPayInfo.sn,
          clientType: 'MICRO'
        })
        .then(res => {
          if (res) {
            clearInterval(this.intervalId);
            this.intervalId = null;
            message.success(`顾客成功支付${trainPayInfo.payPrice}元`);
            Util.payVoiceTips(
              `${trainPayInfo.payType === 'ALIPAY' ? '支付宝' : trainPayInfo.payType === 'WECHAT' ? '微信' : ''}成功收款${trainPayInfo.payPrice}元`
            );
          } else {
            message.warning('正在多次查询，用户支付结果，弹出7次后自动消失，若未显示结果，请前往订单列表查询支付结果');
          }
        });
    },
    cancelOrder() {
      this.confirmOrder = false;
      console.log('cancel');
    },
    // 打开单品优惠
    reduced(e) {
      if (this.cartData.skuList.length > 0) {
        this.reducedShow = true;
        this.singleGoodPrice = e.priceDetailDTO.goodsPrice;
        this.uuId = e.uuId;
      } else {
        this.$message.warning('购物车空！');
      }
    },
    // 确认单品优惠
    reducedConfirm(type, val) {
      if (val.length === 0) {
        return;
      }
      this.reducedShow = false;
      let params = {
        cartType: 'CASHIER_CART',
        storeId: this.ShopData.id,
        uuid: this.uuId
      };
      if (type === 0) {
        params.updatePrice = val;
      } else {
        params.updateDiscount = val;
      }
      this.SET_PROMOTION_TYPE(2);
      api.postSReduced(params).then(() => {
        this.getData();
      });
    },
    // 打开三方优惠（代金券）
    thirdPartyOpen() {
      if (this.cartData.skuList.length > 0) {
        this.thirdShow = true;
        this.singleGoodPrice = this.cartData.priceDetailDTO.flowPrice;
      } else {
        this.$message.warning('购物车空！');
      }
    },
    // 三方优惠（代金券）
    thirdPartyDiscount(obj) {
      this.thirdShow = false;

      this.SET_PROMOTION_TYPE(3);
      api.postTReduced({ cartType: 'CASHIER_CART', storeId: this.ShopData.id, ...obj }).then(() => {
        this.getData();
      });
    },
    // 优惠类型显示
    discountType() {
      return ['礼品卡优惠', '优惠券优惠', '单品优惠', '三方优惠'][this.PromotionCheckType];
    },
    countAllPrice(type = 1, cartData, index) {
      // 单份商品的总价
      if (type === 1) {
        return cartData.cartList[0].skuList[index].singleReducedPrice + cartData.cartList[0].skuList[index].materialPrice;
      }
      // 清空购物车 那边的总价
      if (type === 2 && cartData.priceDetailDTO) {
        let money = (cartData.priceDetailDTO.goodsPrice + cartData.priceDetailDTO.materialPrice).toFixed(2);
        if (money.includes('.00')) {
          return money.substring(0, money.length - 3);
        }
        if (money.includes('.') && money[money.length - 1] === '0') {
          return money.substring(0, money.length - 2);
        }
        return money;
      }
      return 0;
    }
  }
};
</script>

<style lang="scss" scoped>
.entire {
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.row-sa {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.row-sb {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

$title-area-height: 60px;

.title-area {
  height: $title-area-height;
  padding: 0 40px;
  font-size: 21px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(247, 248, 250, 1);

  .font {
    color: #000;
  }
  .selNum {
    color: rgba(0, 0, 0, 0.65);
    font-size: 17px;
  }
}

$settlement-height: 150px;

.cart-list {
  position: relative;
  height: calc(100% - #{$title-area-height} - #{$settlement-height});
  overflow-y: scroll;
  .emptyBox {
    position: absolute;
    left: 50%;
    top: 30%;
    transform: translateX(-50%);
    div {
      margin-top: 15px;
      color: #979696;
      text-align: center;
      font-size: 18px;
    }
  }
}

.cart-block {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 20px 0;
  padding: 10px 14px;
  border-bottom: 1px solid #eaeaea;

  .sort {
    margin-right: 10px;
    font-size: 20px;
    color: #72c5c7;
  }

  .good {
    width: 100%;
    text-align: left;
    $good-name-width: calc(100% - 150px);

    .name {
      width: $good-name-width;
      font-size: 17px;
      font-weight: 500;
    }

    .norm {
      margin-top: 6px;
      margin-bottom: 20px;
      font-size: 15px;
      color: rgba(255, 122, 69, 1);
    }

    .material-row {
      margin-bottom: 10px;
    }

    .small-material {
      width: $good-name-width;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 15px;
      color: rgba(0, 0, 0, 0.85%);
    }

    .price-area {
      width: calc(100% - 35px);
      margin-top: 15px;
      font-size: 16px;
      color: #434343;
      > div {
        display: flex;
        align-items: center;
      }

      .price-name {
        font-size: 16px;
        font-weight: 500;
      }

      .price-all {
        font-weight: 500;
        font-size: 20px;
      }
    }
  }

  .num {
    width: 70px;
    display: flex;
    margin-right: 60px;
    justify-content: space-between;
    align-items: center;
  }

  .icon {
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }
}

.settlement-area {
  width: $LEFT_BODY_WIDTH;
  height: $settlement-height;
  position: absolute;
  left: 7;
  bottom: 0;
  font-size: 16px;
  color: #434343;

  .settlement-row {
    padding: 14px 12px;
  }

  .red-font {
    font-size: 16px;
    font-weight: 500;
    color: #cd3939;
  }
  .btmBox {
    padding: 0 0 0 12px;
    height: 71px;
    div:first-child {
      width: 100px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 17px;
    }
  }
}

// modal
.cart-info {
  /deep/ .ant-modal-header {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    color: #434343;
    background-color: #ceebeb;
  }
  /deep/ .ant-modal-content {
    background-color: #72c5c7;
  }
  /deep/ .ant-modal-footer {
    border: none;
    display: flex;
    justify-content: space-between;
    padding: 10px 24px;
    align-items: center;
    background-color: #ceebeb;

    .joinCartBtn {
      color: rgba(114, 197, 199, 1);
      display: flex;
      align-items: center;
      font-weight: 500;
      border-radius: 10px;
      height: 38px;
      span {
        margin-left: 3px;
      }
    }
  }

  /deep/ .ant-modal-body {
    padding: 0;
    min-height: 300px;
    font-size: 32px;
    text-align: center;
  }
}
.clearBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgba(255, 69, 69, 1);
  margin-left: 15px;
  img {
    height: 25px;
    width: 25px;
  }
}
.payInfoBox {
  background-color: rgba(247, 248, 250, 1);
  font-size: 14px;
  color: #000;
  .price {
    font-weight: 500;
  }
}

.btnBox {
  display: flex;
  height: 100%;
  .btmBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    color: #fff;
    min-width: 138px;
    font-size: 15px;
    height: 100%;
  }
  button:first-child {
    background-color: #72c5c7;
  }
  button:last-child {
    border-radius: 0 0 8px 0;
    background-color: rgba(255, 122, 69, 1);
  }
}
.payResModal {
  /deep/ .ant-modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 350px;
    div {
      font-weight: 500;
      font-size: 20px;
      text-align: center;
      margin-top: 20px;
    }
  }
}
</style>
