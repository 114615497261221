// 统一请求路径前缀在libs/axios.js中修改
import { baseUrl, buyerUrl, getRequest, postRequest, putRequest, deleteRequest, getRequestWithNoToken, postRequestWithNoToken } from '@/libs/axios';
import store from '@/store/index';

let header = {};

const setHeaderToken = () => {
  if (store.state.MemberToken.length > 0) {
    header.accessToken = store.state.MemberToken;
  } else {
    delete header.accessToken;
  }
};

const resetHeaderToken = () => {
  if (window.localStorage.getItem('accessToken')) {
    header.accessToken = window.localStorage.getItem('accessToken');
  }
};

/**
 * 根据id/手机号获取会员信息
 * @param {string} memberId * 和下一个任选一不为空
 * @param {string} storeId *
 * @param {string} mobile * 和上一个任选一不为空
 * @param {string} beforeMemberId * 传空字符串代表用的店铺购物车
 * @param {string} cartFlag * 购物车是否不为空: true - 不为空,false - 为空
 * @param {*} header.accessToken member token
 * @returns
 */
const getUserInfo = (params = {}) => {
  resetHeaderToken();
  return getRequest(baseUrl + '/member/user/info', params, header);
};

/**
 * 退出会员登录(购物车不为空调用)
 * @param {string} beforeMemberId * 传空字符串代表用的店铺购物车
 * @param {string} storeId *
 * @returns
 */
const getQuitLogin = (params = {}) => {
  resetHeaderToken();
  return getRequest(baseUrl + '/member/user/exit', params);
};

/**
 * 获取优惠券列表
 * @param {string} memberCouponStatus * 可使用 NEW 已使用 USED 和已过期 EXPIRE
 * @param {string} memberId *
 * @param {string} storeId *
 * @param {string} scopeId * goodsSku.id逗号分隔
 * @param {Number} totalPrice * 商品原价和小料价格
 * @param {Number} goodsInfo *  [
    {
        "goodsSkuId": 1,
        "num": 1,
        "materialPrice": 2.2
    }
]
 * @param {Number} pageNumber *
 * @param {Number} pageSize *
 */
const postCouponList = (params = {}) => {
  let pageNumber = 1;
  let pageSize = 1000;
  let url = '/buyer/store/member/coupon/getCoupons';
  if (params.pageNumber) {
    pageNumber = params.pageNumber;
    delete params.pageNumber;
    url = url + '?pageNumber=' + pageNumber;
  }
  if (params.pageSize) {
    pageSize = params.pageSize;
    delete params.pageSize;
    url = url + '&pageSize=' + pageSize;
  }

  setHeaderToken();
  return postRequest(buyerUrl + url, params, { ...header, 'Content-Type': 'application/json' });
};

/**
 * 使用优惠券
 * @param {string} params.cartType * CASHIER_CART
 * @param {string} params.storeId *
 * @param {string} params.memberId *
 * @param {string} params.used * true
 * @param {*} params.memberCouponId
 * @param {*} header.accessToken member token
 * @returns
 */
const getUseCoupon = (params = {}) => {
  if (store.state.MemberToken.length > 0) {
    header.accessToken = store.state.MemberToken;
  }
  setHeaderToken();
  return getRequest(buyerUrl + '/buyer/trade/carts/select/coupon', params, header);
};

export default {
  getUserInfo,
  getQuitLogin,
  postCouponList,
  getUseCoupon
};
