<template>
  <div class="container" style="width: 320px; font-family: '思源黑体 CN Normal';">
    <!-- <button hover-class="button-hover" @click="print">
      打印
    </button> -->
    <div id="receipt">
      <section>
        <div class="header" style="display: flex; flex-direction: column; align-items: center;">
          <div class="flex-row space-between" style="width: 100%; display: flex; flex-direction: row; align-items: center;">
            <img
              class="logo"
              style="width: 100px; height: 45px; margin-top: 10px; margin-left: 10px;"
              src="https://s0.xinhuo.life/receipt-logo.png"
              alt="logo"
            />
            <h1 style="font-size: 14px; width: 220px; font-family: '思源黑体 CN Normal'; margin-top: 20pt; margin-right: -10px; text-align: right;">
              【{{ ShopData.storeName || '' }}】
            </h1>
          </div>
          <div class="status" style="font-size: 18px; margin-bottom: 14px; font-weight: 800; font-family: '思源黑体 CN Normal';">订单已付款</div>
          <div style="margin-left: 14px;">-------------------------------------------------------</div>
        </div>
      </section>
      <section>
        <div
          class="flex-column pickup margin-top-20"
          style="display: flex; flex-direction: column; align-items: flex-start; margin-top: 14px; margin-left: 14px; margin-right: 14px;"
        >
          <div
            class="pickup-number"
            style="font-size: 20px; font-weight: 800; font-family: '思源黑体 CN Normal';"
            v-if="currentOrder.paymentMethod !== 'ELEME'"
          >
            取餐号：{{ currentOrder.pickUpCode }}
          </div>
          <div
            class="pickup-number"
            style="font-size: 20px; font-weight: 800; font-family: '思源黑体 CN Normal';"
            v-if="currentOrder.paymentMethod === 'ELEME'"
          >
            取餐号：{{ currentOrder.sn }}
          </div>
          <div
            class="order-sn"
            style="font-size: 9pt; font-family: '思源黑体 CN Normal'; margin-bottom: 14px;"
            v-if="currentOrder.paymentMethod !== 'ELEME'"
          >
            订单号：{{ currentOrder.sn }}
          </div>
          <div>-------------------------------------------------------</div>
        </div>
      </section>
      <section>
        <!-- <div class="address bottom-line"></div> -->
      </section>
      <section>
        <div
          style="
            width: 98%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            font-family: '思源黑体 CN Normal';
            margin-bottom: 14px;
            font-size: 16px;
            margin-left: 14px;
          "
        >
          <div style="flex: 3;">商品</div>
          <div style="flex: 1;">数量</div>
          <div style="flex: 1;">价格￥</div>
        </div>
        <!-- 判断不是饿了么 显示打印ui -->
        <template v-if="currentOrder.paymentMethod !== 'ELEME'">
          <div v-for="(item, index) in currentOrder.orderItems" :key="index">
            <div style="display: flex; flex-direction: column; align-items: center; justify-content: flex-start; font-family: '思源黑体 CN Normal';">
              <div style="font-family: '思源黑体 CN Normal'; width: 280px; display: flex; justify-content: space-between;">
                <div class="name" style="">
                  <div style="font-size: 16px; font-weight: 500;">{{ item.name }}</div>
                  <div style="font-size: 11px;">{{ item.specName }}</div>
                </div>
                <div class="price" style="font-size: 11px; text-align: center;">{{ item.num }}</div>
                <div class="quantity" style="font-size: 11px; text-align: right;">{{ item.subTotal ? item.subTotal.toFixed(2) : 0.0 }}</div>
              </div>
              <!-- <div class="name">{{ item.unitPrice }}</div> -->

              <div v-for="(material, idx) in item.materials" :key="idx" style="">
                <div style="font-family: '思源黑体 CN Normal'; width: 280px; display: flex; justify-content: space-between;">
                  <div class="name" style="font-size: 11px; width: 100pt;">{{ material.name }}</div>
                  <!-- <div class="name">{{ item.unitPrice }}</div> -->
                  <div class="price" style="font-size: 11px; width: 20pt; text-align: center;">{{ material.num }}</div>
                  <div class="quantity" style="font-size: 11px; width: 20pt; text-align: right;">
                    {{ material.price ? material.price.toFixed(2) : 0.0 }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <!-- 判断是饿了么 显示打印ui -->
        <template v-else>
          <div v-for="(item, index) in currentOrder.orderItems" :key="index">
            <div style="display: flex; flex-direction: column; align-items: center; justify-content: flex-start; font-family: '思源黑体 CN Normal';">
              <div style="font-family: '思源黑体 CN Normal'; width: 280px; display: flex; justify-content: space-between;">
                <div class="name" style="">
                  <div style="font-size: 16px; font-weight: 500;">{{ item.name }}</div>
                  <div style="font-size: 11px;">{{ item.spaceInfo }}</div>
                </div>
                <div class="price" style="font-size: 11px; text-align: center;">{{ item.num }}</div>
                <div class="quantity" style="font-size: 11px; text-align: right;">{{ item.price ? item.price.toFixed(2) : 0.0 }}</div>
              </div>
              <!-- <div class="name">{{ item.unitPrice }}</div> -->

              <template v-if="item.materials && item.materials.length">
                <div v-for="(material, idx) in item.materials" :key="idx" style="">
                  <div style="font-family: '思源黑体 CN Normal'; width: 280px; display: flex; justify-content: space-between;">
                    <div class="name" style="font-size: 11px; width: 100pt;">{{ material.name }}</div>
                    <!-- <div class="name">{{ item.unitPrice }}</div> -->
                    <div class="price" style="font-size: 11px; width: 20pt; text-align: center;">1</div>
                    <!-- TODO 需要后台返回价格 -->
                    <div class="quantity" style="font-size: 11px; width: 20pt; text-align: right;">
                      {{ material.price ? material.price.toFixed(2) : 0.0 }}
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </template>
        <div style="margin-top: 10px; margin-bottom: 12px; margin-left: 14px;">-------------------------------------------------------</div>
      </section>
      <section>
        <template v-if="currentOrder.paymentMethod !== 'ELEME'">
          <div class="fee margin-top-20" style="font-size: 13px; margin-left: 14px; margin-right: 10px;">
            <div v-if="currentOrder.discountPrice > 0" style="font-family: '思源黑体 CN Normal'; display: flex; justify-content: space-between;">
              <div class="fee-name">折扣：</div>
              <div class="fee-name">-{{ currentOrder.discountPrice ? currentOrder.discountPrice.toFixed(2) : 0.0 }}</div>
            </div>
            <div
              v-if="currentOrder.couponPrice > 0"
              style="font-family: '思源黑体 CN Normal'; display: flex; justify-content: space-between; margin-top: 5px;"
            >
              <div class="fee-name">优惠券：</div>
              <div class="fee-name">-{{ currentOrder.couponPrice ? currentOrder.couponPrice.toFixed(2) : 0.0 }}</div>
            </div>
            <div
              v-if="currentOrder.giftCardPrice > 0"
              style="font-family: '思源黑体 CN Normal'; display: flex; justify-content: space-between; margin-top: 5px;"
            >
              <div class="fee-name">礼品卡：</div>
              <div class="fee-name">-{{ currentOrder.giftCardPrice ? currentOrder.giftCardPrice.toFixed(2) : 0.0 }}</div>
            </div>
            <div style="font-weight: 500; font-family: '思源黑体 CN Normal'; display: flex; justify-content: space-between; margin-top: 5px;">
              <div class="fee-name">合计：</div>
              <div class="fee-name">{{ currentOrder.flowPrice ? currentOrder.flowPrice.toFixed(2) : 0.0 }}</div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="fee margin-top-20" style="font-size: 13px; margin-left: 14px; margin-right: 10px;">
            <div v-if="currentOrder.discountPrice" style="font-family: '思源黑体 CN Normal'; display: flex; justify-content: space-between;">
              <div class="fee-name">折扣：</div>
              <div class="fee-name">{{ currentOrder.discountPrice ? currentOrder.discountPrice + '.00' : 0.0 }}</div>
            </div>
            <div
              v-if="currentOrder.couponPrice"
              style="font-family: '思源黑体 CN Normal'; display: flex; justify-content: space-between; margin-top: 5px;"
            >
              <div class="fee-name">优惠券：</div>
              <div class="fee-name">{{ currentOrder.couponPrice ? currentOrder.couponPrice + '.00' : 0.0 }}</div>
            </div>
            <div
              v-if="currentOrder.giftCardPrice > 0"
              style="font-family: '思源黑体 CN Normal'; display: flex; justify-content: space-between; margin-top: 5px;"
            >
              <div class="fee-name">商品原价：</div>
              <div class="fee-name">-{{ currentOrder.goodPrice ? currentOrder.goodPrice.toFixed(2) : 0.0 }}</div>
            </div>
            <div style="font-weight: 500; font-family: '思源黑体 CN Normal'; display: flex; justify-content: space-between; margin-top: 5px;">
              <div class="fee-name">实付：</div>
              <div class="fee-name">{{ currentOrder.flowPrice ? currentOrder.flowPrice.toFixed(2) : 0.0 }}</div>
            </div>
          </div>
        </template>

        <div style="margin-left: 14px; margin-top: 10px;">-------------------------------------------------------</div>
      </section>
      <section>
        <div
          class="qrcode flex-column margin-top-20"
          style="display: flex; flex-direction: column; align-items: center; margin-top: 10px; margin-bottom: 5px;"
        >
          <img class="qrcode-img" style="width: 150px; height: 150px; margin-bottom: 14px;" src="https://s0.xinhuo.life/qrcode.jpg" alt="qrcode" />
          <!-- <div class="line" /> -->
          <div style="">-------------------------------------------------------</div>
          <div class="guide" style="font-size: 11px; background-color: #ffffff; margin-top: -13pt; width: 100pt; text-align: center;">
            更多优惠请关注官方微信
          </div>
        </div>
      </section>
      <section>
        <div class="introduce bottom-line margin-top-20">
          <h1 class="hint-title" style="font-size: 14px; font-weight: 500; font-family: '思源黑体 CN Normal'; margin-left: 5pt; margin-top: 20px;">
            【格格说的承诺】
          </h1>
          <p style="font-size: 13px; font-family: '思源黑体 CN Normal'; margin-left: 14px; margin-right: 2px; width: 280px;">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;格格说采用草原牧场奶源生牛乳发酵的酸奶，坚持全产业链冷链配送。我们坚持使用鲜果、鲜牛乳、新鲜酸奶等原材料现点现制，食材制作过程中难免口味有差异，如果您对出品有任何不满意，联系店内小伙伴或到任意门店，我们会立即为您免费重新制作一杯。
          </p>
          <h1 class="hint-title" style="font-size: 14px; font-weight: 500; font-family: '思源黑体 CN Normal'; margin-left: 5pt;">
            【格格说，奶源来自大草原】
          </h1>
          <p style="font-size: 13px; font-family: '思源黑体 CN Normal'; margin-left: 14px; margin-right: 2px; width: 280px;">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;欢迎您来喝这一杯格格说酸奶！
            格格说是始创于上海的新中式创意酸奶茶饮品牌。2021年我们创造了第一杯酸奶格乐冰，随后我们又创造出【国风意境酸奶】【格格家的牛乳茶】等多款高人气心意饮品！
            格格说关怀新时代养生青年的声音与诉求，让每一杯美好的饮品，都承载满满的关怀心意。格格说的每一杯饮品都有料、有趣、有态度，诠释一种新式朋克养生的年轻态度。让精神放松点，让生活健康化，一杯好酸奶，让你每天拥有元气满满的好脸色～
            <br />
            诸位小伙伴～以后请多关照啦～
          </p>
        </div>
      </section>
      <section>
        <div
          class="footer flex-column margin-top-20"
          style="
            font-size: 12px;
            font-family: '思源黑体 CN Normal';
            margin-left: 14px;
            margin-top: 40px;
            display: flex;
            flex-direction: column;
            align-items: center;
            letter-spacing: 2px;
          "
        >
          <!-- <div class="order-time">
            <span>开单：{{ currentOrder.createTime }}</span>
          </div> -->
          <div class="order-time">
            <span>下单：{{ currentOrder.paymentTime }}</span>
          </div>
          <div class="store-address" style="width: 230px; text-align: center;">{{ ShopData.storeAddressDetail || '' }}</div>
          <div>欢迎下次光临</div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { getLodop } from '@/libs/lodopFuncs';
import { getReceiptPrinter } from '@/libs/print';
export default {
  name: 'Receipt',
  data() {
    return {
      receipt: {
        id: '',
        date: '',
        total: '',
        items: []
      }
    };
  },
  computed: {
    ...mapState({
      currentOrder: state => state.order.currentOrder,
      ShopData: state => state.ShopData
    })
  },
  mounted() {},

  methods: {
    print() {
      console.log('打印小票~~~');
      let lodop = getLodop();
      lodop.PRINT_INIT('格格说小票');
      lodop.SET_PRINT_MODE('PRINT_PAGE_PERCENT', 'Auto-Width');
      lodop.SET_LICENSES('', 'EF1B22323D58195A5D929538EB7A6466', '', '');
      let receiptPrinter = getReceiptPrinter();
      lodop.SET_PRINTER_INDEX(receiptPrinter.index);
      lodop.ADD_PRINT_HTM(0, 0, '100%', '100%', document.getElementById('receipt').innerHTML);
      // lodop.ADD_PRINT_BARCODE(1200, 90, 160, 160, 'QRCode', 'http://weixin.qq.com/r/ChypsT3Er2DQrXnS90kY');
      lodop.SET_PRINT_PAGESIZE(3, 1385, 45, '');
      // lodop.PREVIEW();
      // lodop.SET_PRINT_COPIES(2);
      lodop.PRINT();
      // lodop.PRINT_DESIGN();
    }
  }
};
</script>

<style lang="scss" scoped>
$BASE-WIDTH: 320px;

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  width: $BASE-WIDTH;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-family: Source Han Sans CN;
  padding-left: 20px;
  padding-right: 20px;
}

.header {
  width: 100%;
  height: 120px;
  padding: 20px 20px;
  //   background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .status {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 28px;
    font-weight: 800;
  }

  .logo {
    width: 148px;
    height: 62px;
  }
}

.pickup {
  width: $BASE-WIDTH;
  align-items: flex-start;
  padding-left: 20px;
  padding-right: 20px;

  .pickup-number {
    font-size: 28px;
    font-weight: 800;
  }
  .order-sn {
    margin-top: 10px;
    margin-bottom: 20px;
  }
}

.address {
  width: 100%;
  height: 50px;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.items {
  width: $BASE-WIDTH;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fee {
  width: $BASE-WIDTH;
  height: 100px;
  display: flex;
  flex-direction: column;
  .fee-name {
    font-size: 22px;
    font-weight: 500;
  }
}

.qrcode {
  width: $BASE-WIDTH;
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;

  .qrcode-img {
    width: 180px;
    height: 180px;
  }
  .guide {
    text-align: center;
    background-color: #ffffff;
    width: 180px;
    font-size: 16px;
    font-weight: 500;
    margin-top: -10px;
  }
}

.introduce {
  width: $BASE-WIDTH;
  //   height: 50px;
  //   background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .hint-title {
    font-size: 18px;
  }
}

.footer {
  width: $BASE-WIDTH;
  line-height: 24px;
  font-size: 16px;
  color: #2c2b25;
  font-weight: 300;
  letter-spacing: 2px;
}

.item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
}

.space-around {
  justify-content: space-around;
}

.space-between {
  justify-content: space-between;
}

.bottom-line {
  border-bottom: 1px dashed #000000;
}

.line {
  border-bottom: 1px dashed #000000;
  width: $BASE-WIDTH;
  margin-top: 20px;
}

.margin-top-20 {
  margin-top: 20px;
}
</style>
