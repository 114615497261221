<template>
  <div class="login">
    <a-row @keydown.enter.native="submitLogin" class="flex">
      <a-col style="width: 368px;">
        <!-- <Header /> -->
        <a-row>
          <a-form :form="form" class="form" @submit="submitLogin">
            <a-form-item>
              <div class="logoBox"><img src="@/assets/login_logo.png" /></div>
            </a-form-item>
            <a-form-item label="账户" :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol">
              <a-input clearable placeholder="请输入账号" autocomplete="off" v-decorator="['username', validateRules.username]" />
            </a-form-item>
            <a-form-item label="密码" :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol">
              <a-input type="password" clearable placeholder="请输入密码" autocomplete="off" v-decorator="['password', validateRules.password]" />
            </a-form-item>
            <a-form-item label="验证码" :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol">
              <a-row>
                <a-col span="12">
                  <a-input clearable placeholder="请输入验证码" autocomplete="off" v-decorator="['captcha', validateRules.captcha]" />
                </a-col>
                <a-col span="12">
                  <img class="VCImg" :src="captchaData" @click="refreshCaptcha" />
                </a-col>
              </a-row>
            </a-form-item>
            <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
              <div :style="{ textAlign: 'center' }">
                <a-button type="primary" :loading="loading" html-type="submit" size="large">
                  <span v-if="!loading">登录</span>
                  <span v-else>登录中...</span>
                </a-button>
              </div>
            </a-form-item>
          </a-form>
        </a-row>
      </a-col>
    </a-row>

    <!-- 引入订单提示音频 -->
    <audio ref="audio" src="@/assets/tipVoice.mp3"></audio>
  </div>
</template>

<script>
import { login, captcha, userMsg, getNotice } from '@/api/index';

import Cookies from 'js-cookie';
import { mapMutations } from 'vuex';
let timeout;
const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 }
};
export default {
  name: 'Login',
  data() {
    return {
      loading: false, // 加载状态
      countTime: 0,
      captchaData: '',
      nonceStr: '',
      intervalId: '',
      form: this.$form.createForm(this, { name: 'form' }),
      formItemLayout,
      validateRules: {
        username: { rules: [{ required: true, message: '请输入账户!' }] },
        password: { rules: [{ required: true, message: '请输入密码!' }] },
        captcha: { rules: [{ required: true, message: '请输入验证码!' }] }
      }
    };
  },
  mounted() {
    // 获取验证码
    this.refreshCaptcha();
    // this.interval = setInterval(() => this.refreshCaptcha(), 150000);
  },
  methods: {
    ...mapMutations(['SET_LOGIN', 'SET_SHOP_DATA', 'SET_NOTICE', 'SET_TAB']),
    countDown(setCount, countTime) {
      clearTimeout(timeout);
      if (countTime > 0) {
        const time = countTime - 1;
        this.setCount(time);
        timeout = setTimeout(() => {
          this.countDown(setCount, time);
          return null;
        }, 1000);
      }
    },
    refreshCaptcha() {
      if (this.countTime === 0) {
        let type = 1;
        captcha({ type }).then(res => {
          this.captchaData = 'data:image/jpeg;base64,' + res.imageBase64;
          this.nonceStr = res.nonceStr;
          this.countDown(this.setCount, 3);
          this.form.setFieldsValue({
            captcha: ''
          });
        });
      }
    },
    setCount(countTime) {
      this.countTime = countTime;
    },
    afterLogin(res) {
      // 登录成功后处理
      let accessToken = res.accessToken;
      let refreshToken = res.refreshToken;
      this.setStore('accessToken', accessToken);
      this.setStore('refreshToken', refreshToken);
      // 获取用户信息
      userMsg().then(res => {
        if (res) {
          this.setStore('saveLogin', this.saveLogin);
          if (this.saveLogin) {
            // 保存7天
            Cookies.set('userInfoSeller', JSON.stringify(res), {
              expires: 7
            });
          } else {
            Cookies.set('userInfoSeller', JSON.stringify(res));
          }
          this.SET_SHOP_DATA(res);
          this.$store.commit('setAvatarPath', res.storeLogo);
          // 加载菜单
          // util.initRouter(this)
          // location.reload();
        } else {
          this.loading = false;
        }
      });
    },
    getNotice() {
      getNotice().then(res => {
        if (res) {
          this.SET_NOTICE(res);
        }
      });
    },
    submitLogin(e) {
      e.preventDefault();
      // 登录操作
      this.form.validateFields((err, values) => {
        if (!err) {
          this.loading = true;
          login({
            username: values.username,
            password: this.md5(values.password),
            captchaType: 'LOGIN',
            captcha: values.captcha,
            nonceStr: this.nonceStr
          })
            .then(res => {
              console.log(res);
              if (res.accessToken && res.refreshToken) {
                this.SET_LOGIN(true);
                // 请求订单通知
                this.intervalId = setInterval(() => {
                  this.getNotice();
                }, 20000);
                // 开启订单提示
                // this.playVoice();
                // 切换全部商品
                this.afterLogin(res);
                this.$router.push({ path: '/goods' });
                this.SET_TAB(0);
                this.$message.success('登录成功');
              } else {
                // this.$message.error(res.message + ',请检查登录信息,重新登录');
                this.loading = false;
                // 获取验证码
                this.refreshCaptcha();
              }
            })
            .catch(() => {
              this.loading = false;
            });
        }
      });
    },
    playVoice() {
      let socket;
      // 当找不到句柄对象即音频资源的时候使用 that
      if (typeof WebSocket === 'undefined') {
        console.log('遗憾：您的浏览器不支持WebSocket');
      } else {
        console.log('恭喜：您的浏览器支持WebSocket');
        // 实现化WebSocket对象 指定要连接的服务器地址与端口建立连接 ws对应http、wss对应https。无法使用wss，浏览器打开WebSocket时报错 注意ws、wss使用不同的端口。
        socket = new WebSocket('ws://localhost:11200/ws/path/asset');
        // 连接打开事件
        socket.onopen = function() {
          console.log('Socket 已打开');
          // socket.send("消息发送测试(From Client)");
        };
        // 收到消息事件
        socket.onmessage = msg => {
          // 当收到消息调用播放音频资源API
          console.log(msg.data);
          this.$refs.audio.play();
        };
        // 连接关闭事件
        socket.onclose = function() {
          console.log('Socket已关闭');
        };
        // 发生了错误事件
        socket.onerror = function() {
          alert('Socket发生了错误');
        };
        // 窗口关闭时，关闭连接
        window.unload = function() {
          console.log('关闭连接Socket');
          socket.close();
        };
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.login {
  height: 100%;
  // background: url('../assets/background.png') no-repeat;
  // background-color: #f0f2f5;
  display: flex;
  background-size: cover;
  align-items: center;
  justify-content: center;
  background-color: #fff;

  .ivu-tabs-nav-container {
    line-height: 2;
    font-size: 17px;
    box-sizing: border-box;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    zoom: 1;
  }
  .verify-con {
    position: absolute;
    top: 150px;
    z-index: 10;
    left: 20px;
  }
  .form {
    padding-top: 1vh;

    .input-verify {
      width: 67%;
    }
  }

  .forget-pass,
  .other-way {
    font-size: 14px;
  }

  .login-btn,
  .other-login {
    margin-top: 3vh;
  }

  .icons {
    display: flex;
    align-items: center;
  }

  .other-icon {
    cursor: pointer;
    margin-left: 10px;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.2);

    :hover {
      color: #2d8cf0;
    }
  }
}
.flex {
  justify-content: center;
}
.VCImg {
  cursor: pointer;
  width: 150px;
  height: 38px;
  margin-left: 18px;
}
.logoBox {
  text-align: center;
  img {
    width: 170px;
  }
}
</style>
