<template>
  <div id="app">
    <kr-print-designer :temp-value="value" :widget-options="widgets" @save="handleSave" />
    <button @click="preview">打印预览</button>
    <button @click="designTemplate">模板</button>
    <button @click="gotoReceipt">收据</button>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { getLodop } from '@/libs/lodopFuncs';
import { getLabelPrinter, getReceiptPrinter } from '@/libs/print';
import { label, widgetOptions, receipt } from '@/constants/constants';
// import { getPrintList, setPrinter } from 'vue-lodop-print-extend';
export default {
  name: 'Template',
  data() {
    return {
      printList: [],
      value: {
        title: '标签',
        width: 400,
        height: 300,
        pageWidth: 40,
        pageHeight: 30,
        tempItems: []
      },
      widgets: widgetOptions
    };
  },
  computed: {
    ...mapState({
      currentOrder: state => state.order.currentOrder,
      ShopData: state => state.ShopData
    })
  },
  created() {
    // this.value = receipt;
    this.value = label;

    // this.value = JSON.parse(this.getStore('labelTemplate'));
    this.printList = this.$lodop.getPrintList();
  },
  mounted() {},

  methods: {
    gotoReceipt() {
      // this.$router.push({
      //   name: 'receipt',
      //   params: {
      //     orderId: this.currentOrder.id
      //   }
      // });
      this.value = receipt;
    },
    designTemplate() {
      let LODOP = getLodop();
      let _self = this;
      const tid = LODOP.PRINT_DESIGN();
      LODOP.On_Return = function (taskID, value) {
        _self.templateCode = value;
        console.log('templateCode:', value);
        this.setStore('labelTemplate', JSON.stringify(value));
      };
    },
    handleSave(value) {
      console.log(JSON.stringify(value));
      this.setStore('labelTemplate', JSON.stringify(value));
    },
    setPrintMode(lodop) {
      lodop.SET_PRINT_MODE('PRINT_PAGE_PERCENT', 'Auto-Width');
      // lodop.SET_PRINTER_INDEX(1);
      lodop.SET_LICENSES('', 'EF1B22323D58195A5D929538EB7A6466', '', '');
    },
    preview() {
      console.log('print~~~~~~~~~:', this.printList);
      if (!this.printList || this.printList.length === 0) {
        this.printList = this.$lodop.getPrintList();
      }

      let labelPrinter = getLabelPrinter(null);
      // let labelPrinter = getReceiptPrinter(null);
      // this.$lodop.setPrinter(labelPrinter.value);
      console.log('print~ after~~~~~~~~:', this.printList, labelPrinter.index);
      let data = [
        {
          name: 'bobo',
          storeName: '长楹天街店'
        }
      ];
      let labelTemplate = JSON.parse(this.getStore('labelTemplate'));
      // let currentOrder = JSON.parse(this.getStore('currentOrder'));
      console.log('labelTemplate:', labelTemplate, this.currentOrder);
      let licenseInfo = {
        strCompanyName: '',
        strLicense: 'EF1B22323D58195A5D929538EB7A6466',
        strLicenseA: '',
        strLicenseB: ''
      };
      this.$lodop.setLicenses(licenseInfo);

      this.$lodop.preview(labelTemplate, this.currentOrder.orderItems, 'Auto-Width', labelPrinter.index, this.setPrintMode);
    }
  }
};
</script>
<style lang="scss" scoped></style>
