<template>
  <div class="entire">
    <div class="left">
      <img class="logo" src="@/assets/logo.png" alt="logo" />
      <div>
        <div class="storeName" @click="toggle">
          {{ shop }}
        </div>
      </div>
    </div>
    <div class="right">
      <!-- tabs -->
      <div v-for="(item, index) in tabs" :key="item + index" class="tabs">
        <div :class="index == tabIndex ? 'check' : ''" @click="chooseTab(index)">{{ item }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex';

export default {
  props: {
    shop: {
      type: String,
      default: 'shopName'
    },
    tabs: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      tabIndex: 0
    };
  },
  watch: {
    // 判断是否有新订单
    Tab: {
      handler(newVal, oldVal) {
        this.tabIndex = newVal;
      }
    }
  },
  computed: {
    ...mapState(['Tab'])
  },
  created() {
    this.tabIndex = this.Tab;
  },
  methods: {
    ...mapMutations(['SET_TAB']),
    chooseTab(index) {
      this.$emit('chooseTab', index);
    },
    toggle() {
      this.SET_TAB(6);
      this.$router.push({ path: '/login' });
    }
  }
};
</script>
<style lang="scss" scoped>
.entire {
  width: 100%;
  height: $TITLE_HEIGHT;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.left {
  width: $LEFT_BODY_WIDTH;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 18px;
  background-color: #33989a;

  .logo {
    width: 114px;
    height: 50px;
    margin-right: 15px;
  }
}

.right {
  width: calc(100% - #{$LEFT_BODY_WIDTH} - 10px);
  height: 100%;
  display: flex;
  // justify-content: space-around;
  align-items: center;
  font-size: 20px;
  // background-color: #33989a;
  background-image: linear-gradient(to right, #33989a 88%, #79b9bc);
  .tabs {
    margin-left: 100px;
    div {
      transition: all 1s;
      font-size: 20px;
      padding: 12px 23px;
      border-radius: 17px;
      font-size: 17px;
      font-weight: 500;
      cursor: pointer;
    }
  }
  .check {
    background: #fff;
    color: #33989a;
  }
}

.storeName {
  position: relative;
  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    right: -21px;
    top: 9px;
    border-radius: 5px;
    border-top: 8px solid #fff;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid transparent;
  }
}
</style>
