// 单品优惠
<template>
  <div>
    <a-modal :visible="show" width="45%" :footer="null" class="single-product-info" @cancel="cancel">
      <template slot="title">单品优惠</template>
      <div class="">
        <div class="cash-content">
          <div class="tabs">
            <div :class="tab === 0 ? 'tab checked' : 'tab'" @click="changeTab(0)" :style="{ borderRight: 'none' }">优惠金额</div>
            <div :class="tab === 1 ? 'tab checked' : 'tab'" @click="changeTab(1)" :style="{ borderLeft: 'none' }">折扣优惠</div>
          </div>
          <div class="row-sb">
            <span>优惠前小计:</span>
            <span>￥ {{ totalPrice }}</span>
          </div>
          <div class="row-sb">
            <span>优惠金额:</span>
            <span>￥ {{ discountPrice }}</span>
          </div>
          <div class="row-sb">
            <span>{{ tab == 0 ? '折后价格' : '折扣率' }}</span>
            <a-input
              ref="discount"
              size="large"
              style="width: 30%; height: 45px;"
              type="text"
              :placeholder="tab == 0 ? '请输入折后价格' : '请输入折扣率(0-10)'"
              v-model="discount"
            />
          </div>
          <div class="row-sb" v-if="tab === 1">
            <span>折后小计:</span>
            <span :style="{ color: 'rgba(255, 122, 69, 1)' }">￥ {{ railPrice }}</span>
          </div>
        </div>

        <Keyboard :clear="clearInput" @changeNum="changeNum" @delNum="delNum" @clearNum="clearNum" @enterNum="enterNum" />
      </div>
    </a-modal>
  </div>
</template>

<script>
import Keyboard from '@/components/Keyboard';
import { message } from 'ant-design-vue';

export default {
  name: 'SingleProductDiscount',
  components: {
    Keyboard
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    totalPrice: {
      type: Number,
      default: 0
    }
  },
  computed: {
    discountPrice() {
      if (this.discount.length == 0) {
        return 0;
      }
      if (this.tab === 0) {
        return (this.totalPrice - this.discount).toFixed(2);
      }
      if (this.tab === 1) {
        if (this.discount > 10) {
          return 0;
        }
        if (this.discount == '0') {
          return this.totalPrice;
        }
        return ((this.totalPrice * this.discount) / 10).toFixed(2);
      }
      return 0;
    },
    railPrice() {
      return (this.totalPrice - this.discountPrice).toFixed(2);
    }
  },
  data() {
    return {
      tab: 0, // 0 优惠金额 1 折扣优惠
      discount: '',
      clearInput: false
    };
  },
  created() {
    this.changeTab();
  },
  methods: {
    changeTab(index = 0) {
      this.tab = index;
      this.discount = '';
      this.clearInput = !this.clearInput;
      this.$nextTick(() => {
        if (this.$refs.discount) {
          this.$refs.discount.focus();
        }
      });
    },
    changeNum(e) {
      if (this.tab === 1 && e !== '.' && !this.discount.includes('.') && this.discount.length > 0) {
        return;
      }
      this.discount = this.discount + e;
    },
    delNum(e) {
      this.discount = e;
    },
    clearNum() {
      this.discount = '';
    },
    enterNum(e) {
      if (e.length === 0) {
        this.$message.warn('不能为空');
      }
      this.$emit('enterNum', this.tab, e);
    },
    cancel() {
      this.tab = 0;
      this.discount = '';
      this.$emit('cancel');
    }
  }
};
</script>

<style lang="scss" scoped>
.row-sa {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.row-sb {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  line-height: 38px;
  span:last-child {
    font-weight: 500;
  }
}

.single-product-info {
  /deep/ .ant-modal-header {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
  }

  /deep/ .ant-modal-body {
    padding: 0;
    min-height: 300px;
    font-size: 32px;
    text-align: center;
  }
}

.tabs {
  margin: 10px auto;
  display: flex;
  justify-content: center;
  align-items: center;

  .tab {
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    border: 1px solid rgba(217, 217, 217, 1);
    transition: all 0.6s;
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
  }

  .checked {
    color: #fff;
    background-color: #72c5c7;
  }
}

.cash-content {
  margin: 20px 30px;
}
</style>
