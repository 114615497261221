<template>
  <div class="container" ref="container">
    <div class="title_box">
      <div class="left" ref="left" :style="{ borderRadius: '10px 0 0 10px' }">
        <div :class="['takeFoodBtn', tabIndex === 1 ? 'activeTab' : '']" @click="tabIndex = 1">
          全部订单
        </div>
      </div>
      <div class="right" ref="right" :style="{ borderRadius: '0 10px 10px 0' }">
        <div
          :class="['takeFoodBtn', tabIndex === 2 ? 'activeTab' : '']"
          :style="{ background: 'linear-gradient(270deg, #eaffff 0%, #ffffff 100%) ' }"
          @click="tabIndex = 2"
        >
          查看配送订单信息
        </div>
      </div>
    </div>

    <div class="content_box">
      <div v-show="tabIndex === 1">
        <div class="searchBox">
          <a-form :form="form" @submit="handleSubmit" layout="inline">
            <a-form-item label="区间">
              <a-select v-decorator="['selectValue', { initialValue: 'today' }]" @change="handleSelectChange" :style="{ width: '150px' }">
                <a-select-option value="today">今天</a-select-option>
                <a-select-option value="yesterday">昨天</a-select-option>
                <a-select-option value="seven">7天内</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="订单日期">
              <!-- :default-value="[moment(getCurrentStartData(), dateFormat), moment(getCurrentEndData(), dateFormat)]" -->
              <a-range-picker
                @change="dateChange"
                :placeholder="['开始日期', '结束日期']"
                format="YYYY-MM-DD HH:mm:ss"
                showTime
                v-model="dateRangeValue"
              />
            </a-form-item>
            <a-form-item label="订单类型">
              <a-select v-decorator="['orderType']" :style="{ width: '150px' }">
                <a-select-option value="PICK_UP">自取</a-select-option>
                <a-select-option value="TAKE_OUT">外卖</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="手机号">
              <a-input v-decorator="['mobile']" placeholder="请输入手机号" :style="{ width: '250px' }" />
            </a-form-item>
            <a-form-item label="订单编号">
              <a-input v-decorator="['orderSn']" placeholder="请输入订单号" :style="{ width: '250px' }" />
            </a-form-item>
            <a-form-item label="取餐号">
              <a-input v-decorator="['pickUpCode']" placeholder="请输入取餐号" :style="{ width: '250px' }" />
            </a-form-item>
            <a-form-item>
              <a-button type="primary" html-type="submit" class="searchBtn" size="large">查询</a-button>
              <a-button class="resetBtn" @click="reset" size="large">重置</a-button>
            </a-form-item>
          </a-form>
          <div class="red">注意：双击某一行可进入详情</div>
          <a-table :columns="columns" :data-source="data" rowKey="id" class="table" :pagination="false" :customRow="tapTabRow" :loading="loading">
            <!-- <a slot="name" slot-scope="text">{{ text }}</a> -->
            <span slot="Pay" slot-scope="text, record">
              <button class="Btn-Default" v-if="record.orderStatus == 'UNPAID'" @click="showPayModel(record)">支付</button>
              <span v-else>已支付</span>
            </span>
            <span slot="goodsPrice" slot-scope="text, record">{{ record.goodsPrice + record.materialPrice || '未获取到' }}</span>
            <!-- <span slot="action">1</span> -->
            <span slot="pickUpTime" slot-scope="text">
              <span v-if="text && typeof text == 'string'">{{ text }}</span>
              <span v-else>立即取餐</span>
            </span>
            <!-- <span slot="discountPrice" slot-scope="text, record">
          <span v-if="record.discountPrice && record.couponPrice && record.giftCardPrice">
            {{ record.discountPrice + record.couponPrice + record.giftCardPrice }}
          </span>
          <span v-else>￥0.00</span>
        </span> -->
            <span slot="orderType" slot-scope="text">
              <span v-if="text == 'PICK_UP'">自取</span>
              <span v-else-if="text == 'TAKE_OUT'">外卖</span>
              <span v-else>无</span>
            </span>
            <span slot="orderStatus" slot-scope="text">
              <span v-if="text == 'PAID'">已下单</span>
              <span v-else-if="text == 'UNPAID'" :style="{ color: 'rgba(255, 122, 69, 1)' }">待支付</span>
              <span v-else-if="text == 'PRODUCTION'">制作中</span>
              <span v-else-if="text == 'COMPLETED'">已完成</span>
              <span v-else-if="text == 'PRODUCED'">制作完成</span>
              <span v-else-if="text == 'CANCELLED'">已取消</span>
              <span v-else-if="text == 'REFUNDED'" :style="{ color: 'rgba(51, 152, 154, 1)' }">已退款</span>
              <span v-else>无</span>
            </span>
            <span slot="deliveryStatus" slot-scope="text">
              <span v-if="text == 1">待接单</span>
              <span v-else-if="text == 2">待取货</span>
              <span v-else-if="text == 3">配送中</span>
              <span v-else-if="text == 4">已完成</span>
              <span v-else-if="text == 5">已取消</span>
              <span v-else-if="text == 8">指派单</span>
              <span v-else-if="text == 9">妥投异常之物品返回中</span>
              <span v-else-if="text == 10">妥投异常之物品返回完成</span>
              <span v-else-if="text == 100">骑士到店</span>
              <span v-else-if="text == 1000">创建达达运单失败</span>
              <span v-else>无</span>
            </span>
          </a-table>
          <div class="pageBox">
            <a-pagination :hideOnSinglePage="true" :current="paramsData.pageNumber" :total="total" @change="pageOnChange" />
          </div>
        </div>
      </div>
      <div v-show="tabIndex === 2">
        <a-spin tip="获取中" :spinning="rightSpinning">
          <div class="orderList">
            <a-collapse v-model="collapseActiveKey" :bordered="false">
              <a-collapse-panel key="1" header="今日订单(点击可折叠)">
                <!-- 今日订单表格 -->
                <a-table :columns="todayOrderColumns" :data-source="todayOrderData" :pagination="false" :loading="deliveryTabLoading">
                  <span slot="orderType" slot-scope="text, record, index">
                    <span v-if="index === 0">收银台</span>
                    <span v-if="index === 1">饿了么</span>
                    <span v-if="index === 2">小程序</span>
                    <span v-if="index === 3">总计</span>
                  </span>
                  <span slot="orderCount" slot-scope="text, record, index">
                    <span v-if="index === 0">{{ record.cashierOrderCount }}</span>
                    <span v-if="index === 1">{{ record.elmOrderCount }}</span>
                    <span v-if="index === 2">{{ record.wechatMPOrderCount }}</span>
                    <span v-if="index === 3">{{ record.totalOrderCount }}</span>
                  </span>
                  <span slot="receivables" slot-scope="text, record, index">
                    <span v-if="index === 0">{{ record.cashierReceivables }}</span>
                    <span v-if="index === 1">{{ record.elmReceivables }}</span>
                    <span v-if="index === 2">{{ record.wechatMPReceivables }}</span>
                    <span v-if="index === 3">{{ record.totalReceivables }}</span>
                  </span>
                  <span slot="actuallyReceived" slot-scope="text, record, index">
                    <span v-if="index === 0">{{ record.cashierActuallyReceived }}</span>
                    <span v-if="index === 1">{{ record.elmActuallyReceived }}</span>
                    <span v-if="index === 2">{{ record.wechatMPActuallyReceived }}</span>
                    <span v-if="index === 3">{{ record.totalActuallyReceived }}</span>
                  </span>
                  <span slot="discounts" slot-scope="text, record, index">
                    <span v-if="index === 0">{{ record.cashierDiscounts }}</span>
                    <span v-if="index === 1">{{ record.elmDiscounts }}</span>
                    <span v-if="index === 2">{{ record.wechatMPDiscounts }}</span>
                    <span v-if="index === 3">{{ record.totalDiscounts }}</span>
                  </span>
                  <span slot="discountCount" slot-scope="text, record, index">
                    <span v-if="index === 0">{{ record.cashierDiscountCount }}</span>
                    <span v-if="index === 1">{{ record.elmDiscountCount }}</span>
                    <span v-if="index === 2">{{ record.wechatMPDiscountCount }}</span>
                    <span v-if="index === 3">{{ record.totalDiscountCount }}</span>
                  </span>
                  <span slot="refundCount" slot-scope="text, record, index">
                    <span v-if="index === 0">{{ record.cashierRefundCount }}</span>
                    <span v-if="index === 1">{{ record.elmRefundCount }}</span>
                    <span v-if="index === 2">{{ record.wechatMPRefundCount }}</span>
                    <span v-if="index === 3">{{ record.totalRefundCount }}</span>
                  </span>
                  <span slot="refunds" slot-scope="text, record, index">
                    <span v-if="index === 0">{{ record.cashierRefunds }}</span>
                    <span v-if="index === 1">{{ record.elmRefunds }}</span>
                    <span v-if="index === 2">{{ record.wechatMPRefunds }}</span>
                    <span v-if="index === 3">{{ record.totalRefunds }}</span>
                  </span>
                </a-table>
              </a-collapse-panel>
              <br />
              <br />
              <a-collapse-panel key="2" header="外卖订单数据(点击可折叠)">
                <div class="takeOutTab">
                  <div :class="[clientType === 'WECHAT_MP' ? 'activeT' : '']" @click="clearTakeOutForm('WECHAT_MP')">
                    小程序
                    <span>{{ this.appletOrderCount || 0 }}</span>
                  </div>
                  <div :class="[clientType === 'ELEME' ? 'activeT' : '']" @click="clearTakeOutForm('ELEME')">
                    饿了么
                    <span>{{ this.elemeOrderCount || 0 }}</span>
                  </div>
                  <!-- <div
                    @click="clientType = 'MEITUAN'"
                    :style="{ color: clientType === 'MEITUAN' ? '#fff' : '#72c5c7', backgroundColor: clientType === 'MEITUAN' ? '#72c5c7' : '#fff' }"
                  >
                    美团
                    <span>{{ 0 }}</span>
                  </div> -->
                </div>

                <a-form layout="inline" :form="takeOutForm" @submit="takeOutListSearch">
                  <a-form-item>
                    <a-input v-decorator="['codeOrMobile']" placeholder="请输入取餐号或手机号"></a-input>
                  </a-form-item>
                  <a-form-item label="下单时间">
                    <a-date-picker v-decorator="['searchDay', { initialValue: getCurrentDate() }]" placeholder="请选择下单时间" format="yyyy-MM-DD" />
                  </a-form-item>

                  <a-form-item label="制作状态">
                    <a-select v-decorator="['orderStatus']" placeholder="请选择订单状态" style="width: 200px;">
                      <a-select-option value="UNPAID" :style="{ color: 'rgba(255, 122, 69, 1)' }">待支付</a-select-option>
                      <a-select-option value="PRODUCTION">制作中</a-select-option>
                      <a-select-option value="COMPLETED">已完成</a-select-option>
                      <a-select-option value="PRODUCED">制作完成</a-select-option>
                      <a-select-option value="CANCELLED">已取消</a-select-option>
                      <a-select-option value="REFUNDED" :style="{ color: 'rgba(51, 152, 154, 1)' }">已退款</a-select-option>
                    </a-select>
                  </a-form-item>
                  <a-form-item label="配送状态">
                    <a-select v-decorator="['deliveryStatus']" placeholder="请选择配送状态" style="width: 200px;">
                      <a-select-option value="1">商户未接单</a-select-option>
                      <a-select-option value="2">商户已接单</a-select-option>
                      <a-select-option value="3">订单取消（接单前）</a-select-option>
                      <a-select-option value="4">订单置为无效（接单后）</a-select-option>
                      <a-select-option value="5">订单强制无效（商家主动取消已接订单）</a-select-option>
                      <a-select-option value="6">订单完结</a-select-option>
                      <a-select-option value="0">待调度</a-select-option>
                      <a-select-option value="20">外卖员已接单</a-select-option>
                      <a-select-option value="30">已取货</a-select-option>
                      <a-select-option value="500">已送达</a-select-option>
                      <a-select-option value="99">已取消</a-select-option>
                    </a-select>
                  </a-form-item>
                  <a-form-item>
                    <a-button type="primary" html-type="submit" size="large">查询</a-button>
                    <a-button @click="clearTakeOutForm('')" class="clearBtn" size="large">清空</a-button>
                  </a-form-item>
                </a-form>

                <!-- 外卖订单数据 -->
                <a-table
                  :columns="deliveryOrderColumns"
                  :data-source="deliveryOrderData"
                  :pagination="false"
                  class="todayOrderTable"
                  :loading="deliveryTabLoading"
                  rowKey="id"
                >
                  <span slot="pickUpCode" slot-scope="text">
                    {{ text || '-' }}
                  </span>
                  <span slot="goodsNum" slot-scope="text">{{ text || '-' }}</span>
                  <span slot="createdTime" slot-scope="text">{{ text || '-' }}</span>
                  <span slot="pickUpTime" slot-scope="text">{{ text || '-' }}</span>
                  <span slot="waitTime" slot-scope="text">{{ text || '-' }}</span>
                  <span slot="orderStatus" slot-scope="text">
                    <span v-if="text === 'UNPAID'">待支付</span>
                    <span v-else-if="text === 'PRODUCTION'">制作中</span>
                    <span v-else-if="text === 'COMPLETED'">已完成</span>
                    <span v-else-if="text === 'PRODUCED'">制作完成</span>
                    <span v-else-if="text === 'CANCELLED'">已取消</span>
                    <span v-else-if="text === 'REFUNDED'">已退款</span>
                    <span v-else>-</span>
                  </span>
                  <span slot="deliveryStatus" slot-scope="text">
                    <span v-if="text === '1'">商户未接单</span>
                    <span v-else-if="text === '2'">商户已接单</span>
                    <span v-else-if="text === '3'">订单取消（接单前）</span>
                    <span v-else-if="text === '4'">订单置为无效（接单后）</span>
                    <span v-else-if="text === '5'">订单强制无效（商家主动取消已接订单）</span>
                    <span v-else-if="text === '6'">订单完结</span>
                    <span v-else-if="text === '0'">待调度</span>
                    <span v-else-if="text === '20'">外卖员已接单</span>
                    <span v-else-if="text === '30'">已取货</span>
                    <span v-else-if="text === '50'">已送达</span>
                    <span v-else-if="text === '99'">已取消</span>
                    <span v-else>-</span>
                  </span>
                  <span slot="mobile" slot-scope="text">{{ text || '-' }}</span>
                  <span slot="courierPhone" slot-scope="text">{{ text || '-' }}</span>

                  <span slot="action" slot-scope="text, record">
                    <a-button @click="toDetail(record.sn, record)" class="lookOrderBtn">查看</a-button>
                  </span>
                </a-table>

                <a-pagination
                  :current="deliveryOrderParams.pageNumber"
                  :total="deliveryOrderTotal"
                  @change="deliveryOrderOnChange"
                  :hideOnSinglePage="true"
                  class="deliveryPage"
                />
              </a-collapse-panel>
            </a-collapse>
          </div>
        </a-spin>
      </div>
    </div>

    <!-- 详情modal -->
    <a-modal
      :visible="detailVisible"
      @cancel="detailHandleCancel"
      :maskClosable="false"
      destroyOnClose
      closable
      :afterClose="detailAfterClose"
      class="detailModal"
      :width="750"
      :getContainer="() => $refs.container"
    >
      <template slot="title">
        <span class="pickUpCode" v-show="clientType !== 'ELEME'">{{ currentOrderInfo.pickUpCode || '无' }}</span>
        <span>订单详情</span>
      </template>
      <!-- 订单信息 -->
      <div>
        <a-descriptions>
          <a-descriptions-item label="订单类型">
            <span v-if="detailType === 'TAKE_OUT'">外卖</span>
            <span v-else-if="detailType === 'PICK_UP'">自提</span>
            <span v-else>饿了么外卖</span>
          </a-descriptions-item>
          <a-descriptions-item label="制作状态">制作中</a-descriptions-item>
          <a-descriptions-item>
            <a-button type="danger" @click="reprintReceipt">重打小票</a-button>
          </a-descriptions-item>
          <a-descriptions-item label="订单总额">
            {{ '￥' + (currentOrderInfo.goodPrice + currentOrderInfo.materialPrice) || '0.00' }}
          </a-descriptions-item>
          <a-descriptions-item label="实收金额">￥{{ currentOrderInfo.flowPrice || '0.00' }}</a-descriptions-item>
          <a-descriptions-item label="优惠金额">
            {{
              '￥' + (currentOrderInfo.discountPrice + currentOrderInfo.couponPrice + currentOrderInfo.giftCardPrice).toString().replace('-', '') ||
              '0.00'
            }}
          </a-descriptions-item>
          <a-descriptions-item label="运费">{{ '￥' + currentOrderInfo.deliveryPrice || '0.00' }}</a-descriptions-item>
          <a-descriptions-item label="下单时间">{{ currentOrderInfo.createTime || '-' }}</a-descriptions-item>
          <!-- <a-descriptions-item label="预计送达时间">2022-01-09 18:10</a-descriptions-item>
          <a-descriptions-item label="订单完成时间">2022-01-09 18:10</a-descriptions-item> -->
          <a-descriptions-item label="预约时间">{{ currentOrderInfo.pickUpTime || '立即取餐' }}</a-descriptions-item>
          <a-descriptions-item label="支付方式">
            <span v-if="currentOrderInfo.paymentMethod === 'CASH'">现金支付</span>
            <span v-else-if="currentOrderInfo.paymentMethod === 'WECHAT'">微信</span>
            <span v-else-if="currentOrderInfo.paymentMethod === 'ALIPAY'">支付宝</span>
            <span v-else-if="currentOrderInfo.paymentMethod === 'WALLET'">余额支付</span>
            <span v-else-if="currentOrderInfo.paymentMethod === 'BANK_TRANSFER'">线下转账</span>
            <span v-else-if="currentOrderInfo.paymentMethod === 'ELEME'">饿了么（跳转支付宝支付）</span>
            <span v-else>-</span>
          </a-descriptions-item>
          <a-descriptions-item label="下单平台" v-if="clientType === 'ELEME'">饿了么</a-descriptions-item>
          <a-descriptions-item label="优惠内容" v-if="clientType !== 'ELEME' && promotionType">
            <span v-if="promotionType.indexOf('PINTUAN') !== -1">拼团</span>
            <span v-else-if="promotionType.indexOf('SECKILL') !== -1">秒杀</span>
            <span v-else-if="promotionType.indexOf('FULL_DISCOUNT') !== -1">满减</span>
            <span v-else-if="promotionType.indexOf('VOUCHER_DISCOUNT') !== -1">
              <!--  三方优惠价格-->
              三方优惠{{ this.currentOrderInfo.totalReducedPrice || '0.00' }}元
            </span>
            <span v-else-if="promotionType.indexOf('POINTS_GOODS') !== -1">积分商品</span>
            <span v-else-if="promotionType.indexOf('KANJIA') !== -1">砍价</span>
            <span v-else-if="promotionType.indexOf('COUPON_ACTIVITY') !== -1">优惠券活动</span>
            <span v-else-if="promotionType.indexOf('SINGLE_DISCOUNT') !== -1">单品优惠</span>
            <span v-else-if="promotionType.indexOf('COUPON') !== -1">
              <span v-if="currentOrderInfo.coupon.couponType == 'PRICE'">
                {{ `优惠券立减￥${this.currentOrderInfo.coupon.price}元券` }}
              </span>
              <span v-if="currentOrderInfo.coupon.couponType == 'PRICE'">
                {{ `优惠券立减￥${this.currentOrderInfo.coupon.price}元券` }}
              </span>
              <span v-else-if="currentOrderInfo.coupon.couponType == 'DISCOUNT'">
                {{ `优惠券${this.currentOrderInfo.coupon.discount}折券` }}
              </span>
              <span v-else-if="currentOrderInfo.coupon.couponType == 'BUY_GIVE'">
                {{ `优惠券买${this.currentOrderInfo.coupon.buy}赠${this.currentOrderInfo.coupon.give}券` }}
              </span>
              <span v-else-if="currentOrderInfo.coupon.couponType == 'TARGET_DISCOUNT'">
                {{ `优惠券第${this.currentOrderInfo.coupon.target}杯，打${this.currentOrderInfo.coupon.discount}折券` }}
              </span>
              <!-- {{ `优惠券优惠￥${currentOrderInfo.discountPrice || 0}元` }} -->
            </span>
            <span v-else-if="promotionType.indexOf('GIFT_CART') !== -1">
              {{ `${currentOrderInfo.giftCardName}礼品卡优惠￥${currentOrderInfo.giftCardPrice || '0.00'}元` }}
            </span>
            <span v-else>无</span>
          </a-descriptions-item>
        </a-descriptions>
        <!-- 门店订单取消原因 -->
        <div v-if="detailType === 'PICK_UP'">
          <div class="cancelReasonTip">注意：门店订单取消原因（取消订单必选）；</div>
          <a-select v-model="cancelReason" style="width: 100%;" placeholder="请选择门店订单取消原因">
            <a-select-option :value="item" v-for="(item, index) in reason" :key="index">
              {{ item }}
            </a-select-option>
          </a-select>
        </div>
        <!-- 外卖订单取消原因 -->
        <div v-if="detailType === 'TAKE_OUT'">
          <div class="cancelReasonTip">注意：外卖订单取消原因（取消订单必选）；</div>
          <a-select v-model="cancelReasonId" style="width: 100%;" placeholder="请选择外卖订单取消原因">
            <a-select-option :value="101">顾客主动取消</a-select-option>
            <a-select-option :value="102">顾客更改配送时间/地址</a-select-option>
            <a-select-option :value="103">备货、包装、货品质量问题取消</a-select-option>
            <a-select-option :value="199">其他接入方原因</a-select-option>
            <a-select-option :value="201">配送服务态度问题取消</a-select-option>
            <a-select-option :value="202">骑手配送不及时</a-select-option>
            <a-select-option :value="203">骑手取货不及时</a-select-option>
            <a-select-option :value="299">其他美团配送原因</a-select-option>
            <a-select-option :value="399">其他原因</a-select-option>
          </a-select>
        </div>
      </div>
      <br />
      <!-- 会员信息 -->
      <div class="top_line">
        <a-descriptions title="会员信息">
          <a-descriptions-item label="会员昵称">{{ currentOrderInfo.consigneeName }}</a-descriptions-item>
          <a-descriptions-item label="联系方式">{{ currentOrderInfo.mobile || '-' }}</a-descriptions-item>
          <a-descriptions-item label="收货地址">{{ currentOrderInfo.consigneeDetail || '-' }}</a-descriptions-item>
          <a-descriptions-item label="备注">{{ currentOrderInfo.remark || '请提前跟商家协商好' }}</a-descriptions-item>
        </a-descriptions>
      </div>

      <!-- 配送信息 -->
      <div class="top_line" v-show="detailType === 'TAKE_OUT' || clientType === 'ELEME'">
        <a-descriptions title="配送信息" v-show="detailType === 'TAKE_OUT'">
          <template v-if="currentOrderInfo.meiTuan">
            <a-descriptions-item label="配送状态" v-if="currentOrderInfo.meiTuan.status === 1">商户未接单</a-descriptions-item>
            <a-descriptions-item label="配送状态" v-if="currentOrderInfo.meiTuan.status === 2">商户已接单</a-descriptions-item>
            <a-descriptions-item label="配送状态" v-if="currentOrderInfo.meiTuan.status === 3">订单取消（接单前）</a-descriptions-item>
            <a-descriptions-item label="配送状态" v-if="currentOrderInfo.meiTuan.status === 4">订单置为无效（接单后）</a-descriptions-item>
            <a-descriptions-item label="配送状态" v-if="currentOrderInfo.meiTuan.status === 5">
              订单强制无效（商家主动取消已接订单）
            </a-descriptions-item>
            <a-descriptions-item label="配送状态" v-if="currentOrderInfo.meiTuan.status === 6">订单完结</a-descriptions-item>
            <a-descriptions-item label="配送状态" v-if="currentOrderInfo.meiTuan.status === 0">待调度</a-descriptions-item>
            <a-descriptions-item label="配送状态" v-if="currentOrderInfo.meiTuan.status === 20">已接单</a-descriptions-item>
            <a-descriptions-item label="配送状态" v-if="currentOrderInfo.meiTuan.status === 30">已取货</a-descriptions-item>
            <a-descriptions-item label="配送状态" v-if="currentOrderInfo.meiTuan.status === 50">已送达</a-descriptions-item>
            <a-descriptions-item label="配送状态" v-if="currentOrderInfo.meiTuan.status === 99">已取消</a-descriptions-item>
            <a-descriptions-item label="骑手姓名">{{ currentOrderInfo.meiTuan.courierName || '-' }}</a-descriptions-item>
            <a-descriptions-item label="骑手联系方式">{{ currentOrderInfo.meiTuan.courierPhone || '-' }}</a-descriptions-item>
            <!-- <a-descriptions-item label="接单时间">{{ currentOrderInfo.meiTuan.courierName || '-' }}</a-descriptions-item>
            <a-descriptions-item label="到店时间">{{ currentOrderInfo.meiTuan.courierName || '-' }}</a-descriptions-item> -->
            <a-descriptions-item label="期望送达时间" v-if="currentOrderInfo.meiTuan.expectedDeliveryTime">
              {{ currentOrderInfo.meiTuan.expectedDeliveryTime | formatDateMill }}
            </a-descriptions-item>
            <a-descriptions-item label="期望送达时间" v-else>-</a-descriptions-item>
          </template>
        </a-descriptions>
        <a-descriptions title="配送信息" v-show="clientType === 'ELEME'">
          <a-descriptions-item><h3>因三方平台限制，无法展示骑手信息，可前往商家后台查看</h3></a-descriptions-item>
        </a-descriptions>
      </div>

      <!-- 商品信息 -->
      <div class="top_line">
        <a-table :columns="skuColumns" :data-source="skuData" :pagination="false">
          <span slot="specsInfo" slot-scope="text">
            {{ text }}
          </span>
          <span slot="materials" slot-scope="text, record">
            {{ record.materials && record.materials.length ? record.materials.map(item => item.name + 'x' + item.num).join(',') : '无' }}
          </span>
          <span slot="action" slot-scope="text, record">
            <a-button type="danger" class="outOrder" @click="reprintLabel(record)">重打杯贴</a-button>
          </span>
        </a-table>
      </div>
      <template slot="footer">
        <!-- <span class="payMoney">支付金额：￥{{ currentOrderInfo.flowPrice || '0.00' }}</span> -->
        <a-button type="danger" :loading="cancelOrderLoading" @click="detailHandleOk" v-if="clientType !== 'ELEME'">取消订单</a-button>
        <a-button type="danger" v-else>请前往饿了么商家后台取消</a-button>
      </template>
    </a-modal>

    <!-- 支付成功失败弹窗 -->
    <a-modal
      :title="null"
      :footer="null"
      class="payResModal"
      :visible="payResVisible"
      @ok="
        () => {
          this.payResVisible = false;
        }
      "
      @cancel="
        () => {
          this.payResVisible = false;
        }
      "
    >
      <template v-if="payStatus === 1">
        <div>
          <img src="../../assets/paySuccess.png" alt="支付成功" />
          <div>支付成功</div>
        </div>
      </template>
      <template v-if="payStatus === 0">
        <div>
          <img src="../../assets/payFail.png" alt="支付失败" />
          <div>支付失败</div>
        </div>
      </template>
      <template v-if="payStatus === '' || payStatus === 2">
        <div>
          <img src="../../assets/payLoading.png" alt="支付中" />
          <div>支付中...</div>
        </div>
      </template>
    </a-modal>

    <Receipt ref="receipt" style="display: none;" />
    <PayInfoModel :confirmOrder="confirmOrder" :totalPrice="price" @cancelOrder="cancelOrder" @payOrder="payOrder" />
  </div>
</template>
<script>
import Util from '@/libs/util';
import moment from 'moment';
import { orderListData, payResult, orderDeliveryList } from '@/api/order';
import { workTableDetailOrder, workTableDeleteOrder } from '@/api/workTable';
import { mapState, mapMutations } from 'vuex';
import api from '@/api/cart';
import Receipt from '@/views/Receipt';
import { printLabel } from '@/libs/print';

import PayInfoModel from '@/components/PayInfoModel';
import { message } from 'ant-design-vue';
import { setStore, getStore, removeStore } from '@/libs/storage';
const todayOrderColumns = [
  {
    title: '订单类型',
    dataIndex: 'orderType',
    key: 'orderType',
    scopedSlots: { customRender: 'orderType' }
  },
  {
    title: '订单数量',
    dataIndex: 'orderCount',
    key: 'orderCount',
    scopedSlots: { customRender: 'orderCount' }
  },
  {
    title: '应收总额',
    dataIndex: 'receivables',
    key: 'receivables',
    scopedSlots: { customRender: 'receivables' }
  },
  {
    title: '实收总额',
    dataIndex: 'actuallyReceived',
    key: 'actuallyReceived',
    scopedSlots: { customRender: 'actuallyReceived' }
  },
  {
    title: '优惠总额',
    dataIndex: 'discounts',
    key: 'discounts',
    scopedSlots: { customRender: 'discounts' }
  },
  {
    title: '优惠订单数',
    dataIndex: 'discountCount',
    key: 'discountCount',
    scopedSlots: { customRender: 'discountCount' }
  },
  {
    title: '退款订单数',
    dataIndex: 'refundCount',
    key: 'refundCount',
    scopedSlots: { customRender: 'refundCount' }
  },
  {
    title: '退款总额',
    dataIndex: 'refunds',
    key: 'refunds',
    scopedSlots: { customRender: 'refunds' }
  }
];

const deliveryOrderColumns = [
  {
    title: '取餐号',
    dataIndex: 'pickUpCode',
    key: 'pickUpCode',
    scopedSlots: { customRender: 'pickUpCode' }
  },
  {
    title: '商品数量',
    dataIndex: 'goodsNum',
    key: 'goodsNum',
    scopedSlots: { customRender: 'goodsNum' }
  },
  {
    title: '下单时间',
    dataIndex: 'createdTime',
    key: 'createdTime',
    scopedSlots: { customRender: 'createdTime' }
  },
  {
    title: '预约时间',
    dataIndex: 'pickUpTime',
    key: 'pickUpTime',
    scopedSlots: { customRender: 'pickUpTime' }
  },
  {
    title: '已等待',
    dataIndex: 'waitTime',
    key: 'waitTime',
    scopedSlots: { customRender: 'waitTime' }
  },
  {
    title: '制作状态',
    dataIndex: 'orderStatus',
    key: 'orderStatus',
    scopedSlots: { customRender: 'orderStatus' }
  },
  {
    title: '配送状态',
    dataIndex: 'deliveryStatus',
    key: 'deliveryStatus',
    scopedSlots: { customRender: 'deliveryStatus' }
  },
  {
    title: '会员联系方式',
    dataIndex: 'mobile',
    key: 'mobile',
    scopedSlots: { customRender: 'mobile' }
  },
  {
    title: '骑手联系方式',
    dataIndex: 'courierPhone',
    key: 'courierPhone',
    scopedSlots: { customRender: 'courierPhone' }
  },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    scopedSlots: { customRender: 'action' }
  }
];
const columns = [
  {
    title: '取餐号',
    dataIndex: 'pickUpCode'
  },
  {
    title: '订单编号',
    dataIndex: 'sn'
  },
  {
    title: '会员手机号',
    dataIndex: 'mobile'
  },
  {
    title: '订单金额',
    dataIndex: 'goodsPrice',
    scopedSlots: { customRender: 'goodsPrice' }
  },
  // {
  //   title: '优惠价格',
  //   dataIndex: 'discountPrice',
  //   scopedSlots: { customRender: 'discountPrice' }
  // },
  // {
  //   title: '配送费',
  //   dataIndex: 'deliveryPrice'
  // },
  {
    title: '实付价格',
    dataIndex: 'flowPrice'
  },

  {
    title: '配送方式',
    dataIndex: 'orderType',
    scopedSlots: { customRender: 'orderType' }
  },
  {
    title: '预约取餐时间',
    dataIndex: 'pickUpTime',
    scopedSlots: { customRender: 'pickUpTime' }
  },

  {
    title: '订单状态',
    dataIndex: 'orderStatus',
    scopedSlots: { customRender: 'orderStatus' }
  },
  {
    title: '配送状态',
    dataIndex: 'deliveryStatus',
    scopedSlots: { customRender: 'deliveryStatus' }
  },
  {
    title: '创建时间',
    dataIndex: 'createTime'
  },
  // {
  //   title: '更新时间',
  //   dataIndex: 'updateTime'
  // }
  {
    title: '支付',
    dataIndex: 'Pay',
    scopedSlots: { customRender: 'Pay' }
  }
];
const skuColumns = [
  {
    title: '商品名称',
    dataIndex: 'name',
    key: 'name',
    width: 100
  },
  {
    title: '数量',
    dataIndex: 'num',
    key: 'num',
    width: 75
  },
  {
    title: '规格',
    key: 'spaceInfo',
    dataIndex: 'spaceInfo',
    scopedSlots: { customRender: 'specsInfo' },
    width: 120
  },
  {
    title: '小料',
    dataIndex: 'materials',
    key: 'materials',
    scopedSlots: { customRender: 'materials' }
  },
  {
    title: '操作',
    key: 'action',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  }
];
export default {
  name: 'orderList',
  components: {
    PayInfoModel,
    Receipt
  },
  data() {
    return {
      detailVisible: false,
      cancelOrderLoading: false,
      clientType: 'WECHAT_MP',
      form: this.$form.createForm(this, { name: 'orderListForm' }),
      takeOutForm: this.$form.createForm(this, { name: 'takeOutForm' }),
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
      dateRangeValue: [moment(this.getCurrentStartData(), this.dateFormat), moment(this.getCurrentEndData(), this.dateFormat)],
      radioValue: '',
      loading: false,
      data: [],
      columns,
      rightSpinning: false,
      paramsData: {
        pageNumber: 1, // 当前页数
        pageSize: 10, // 页面大小
        // orderType: 'TAKE_OUT'
        storeId: ''
      },
      tabIndex: 1,
      total: 0,
      confirmOrder: false,
      sn: '',
      price: 0,
      count: 0,
      payIntervalId: null,
      todayOrderColumns,
      todayOrderData: [],
      deliveryOrderColumns,
      deliveryOrderData: [],
      collapseActiveKey: ['2'],
      deliveryOrderTotal: 0,
      deliveryOrderParams: {
        pageNumber: 1, // 当前页数
        pageSize: 10, // 页面大小
        storeId: '',
        platformClient: 'WECHAT_MP'
      },
      deliveryTabLoading: false,
      appletOrderCount: 0,
      elemeOrderCount: 0,
      detailType: '',
      cancelReason: '',
      cancelReasonId: '',
      currentOrderInfo: {},
      skuData: [],
      skuColumns,
      promotionType: '',
      payResVisible: false,
      payStatus: ''
    };
  },
  mounted() {
    this.tabIndex = 1;
  },
  computed: { ...mapState(['ShopData']) },
  watch: {
    tabIndex: {
      handler(newVal, oldVal) {
        this.paramsData.storeId = this.ShopData.id || '';
        this.deliveryOrderParams.storeId = this.ShopData.id || '';
        if (newVal === 2) {
          this.clientType = 'WECHAT_MP';
          this.clearTakeOutForm('WECHAT_MP');
        } else if (newVal === 1) {
          this.reset();
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapMutations(['SET_MEMBER_TOKEN', 'SET_MEMBER_INFO']),
    moment,
    getCurrentDate() {
      return new Date().toLocaleDateString().replace(/\//g, '-');
    },
    detailHandleCancel() {
      this.detailVisible = false;
    },
    reprintReceipt() {
      this.$refs.receipt.print();
    },
    detailAfterClose() {
      this.detailType = '';
      this.cancelReasonId = this.cancelReason = '';
    },
    getOrderDeliveryList(param) {
      this.deliveryTabLoading = true;
      orderDeliveryList(param).then(res => {
        const { appletOrderCount, elemeOrderCount, orderStatisticsDTO, takeOutOrderDTO } = res;
        let cashierObj = {};
        let elmObj = {};
        let wechatObj = {};
        let totalObj = {};
        for (let key in orderStatisticsDTO) {
          if (key.indexOf('cashier') !== -1) {
            // 收银台数据
            cashierObj[key] = orderStatisticsDTO[key];
          } else if (key.indexOf('elm') !== -1) {
            // 饿了么数据
            elmObj[key] = orderStatisticsDTO[key];
          } else if (key.indexOf('wechat') !== -1) {
            // 小程序数据
            wechatObj[key] = orderStatisticsDTO[key];
          } else if (key.indexOf('total') !== -1) {
            // 总的数据
            totalObj[key] = orderStatisticsDTO[key];
          }
        }
        this.todayOrderData = orderStatisticsDTO ? [cashierObj, elmObj, wechatObj, totalObj] : [];
        this.deliveryOrderData = takeOutOrderDTO && takeOutOrderDTO.records && takeOutOrderDTO.records.length ? takeOutOrderDTO.records : [];
        this.deliveryOrderTotal = takeOutOrderDTO.total || 0;
        this.deliveryOrderParams.pageNumber = takeOutOrderDTO.current || 0;
        this.appletOrderCount = appletOrderCount || 0;
        this.elemeOrderCount = elemeOrderCount || 0;
        this.deliveryTabLoading = false;
      });
    },
    getCurrentStartData() {
      return moment().startOf('day').format(this.dateFormat);
    },
    getCurrentEndData() {
      return moment().endOf('day').format(this.dateFormat);
    },
    dateChange(date, dateString) {
      this.dateRangeValue = dateString;
      // 重新选择日期 重置区间
      this.form.setFieldsValue({ selectValue: '' });
    },
    handleSelectChange(value) {
      const dateFormat = this.dateFormat;
      // 设置订单日期区间
      switch (value) {
        case 'today':
          this.dateRangeValue = [moment(this.getCurrentStartData(), dateFormat), moment(this.getCurrentEndData(), dateFormat)];
          break;
        case 'yesterday':
          this.dateRangeValue = [moment().subtract('days', 1).format(dateFormat), moment().subtract('days', 1).format(dateFormat)];

          break;
        case 'seven':
          // this.dateRangeValue = [
          //   moment()
          //     .subtract('days', 7)
          //     .format(dateFormat),
          //   moment()
          //     .subtract('days', 1)
          //     .format(dateFormat)
          // ];
          this.dateRangeValue = [moment(Date.now() - 6 * 24 * 3600 * 1000).format(dateFormat), moment(Date.now()).format(dateFormat)];
          break;
        default:
          break;
      }
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          if (!this.dateRangeValue[0] && !this.dateRangeValue[1]) {
            return this.$message.warning('请选择日期区间');
          }
          this.paramsData.pageNumber = 1;
          let obj = { ...values, startDate: this.dateRangeValue[0], endDate: this.dateRangeValue[1], ...this.paramsData };
          this.commonFn(obj, param => {
            this.getData(param);
          });
        }
      });
    },
    commonFn(param, fn) {
      for (let key in param) {
        if (!param[key] || key === 'selectValue') {
          delete param[key];
        }
        if (param.startDate && param.endDate) {
          param.startDate = moment(param.startDate).format(this.dateFormat).toString();
          param.endDate = moment(param.endDate).format(this.dateFormat).toString();
        }
      }
      fn(param);
    },
    reset() {
      this.form.resetFields();
      this.paramsData.pageNumber = 1;
      this.dateRangeValue = [moment(this.getCurrentStartData(), this.dateFormat), moment(this.getCurrentEndData(), this.dateFormat)];
      let obj = { ...this.form.getFieldsValue(), startDate: this.getCurrentStartData(), endDate: this.getCurrentEndData(), ...this.paramsData };
      this.commonFn(obj, param => {
        this.getData(param);
      });
    },
    getData(param) {
      this.loading = true;
      orderListData(param).then(res => {
        const result = res.records || [];
        this.data = result.length ? result : [];
        this.total = res.total;
        this.paramsData.pageNumber = res.current;
        this.loading = false;
      });
    },
    pageOnChange(pageNumber) {
      this.paramsData.pageNumber = pageNumber;
      let obj = { ...this.form.getFieldsValue(), startDate: this.dateRangeValue[0], endDate: this.dateRangeValue[1], ...this.paramsData };
      this.commonFn(obj, param => {
        this.getData(param);
      });
    },
    tapTabRow(record, index) {
      return {
        on: {
          dblclick: event => {
            this.$router.push({ path: '/orderDetail', query: { sn: record.sn } });
          }
        }
      };
    },
    showPayModel(obj) {
      // 先调用接口 判断是否支付成功
      payResult(obj.sn).then(res => {
        if (res) {
          // 获取最新的tradeSn替换obj里的tradeSn
          this.confirmOrder = true;
          this.sn = res;
          this.price = obj.flowPrice;
        }
      });
    },
    payOrder(obj) {
      const that = this;
      api
        .getQRCodePay({ sn: that.sn, ...obj })
        .then(res => {
          this.payResVisible = true;
          if (res.payStatus === 1) {
            this.payStatus = res.payStatus;
            message.success(`顾客成功支付${res.payPrice}元`);
            Util.payVoiceTips(`${obj.payType === 'ALIPAY' ? '支付宝' : obj.payType === 'WECHAT' ? '微信' : ''}成功支付${res.payPrice}元`);
          } else if (res.payStatus === 0) {
            message.error('收款失败：' + res.msg);
            Util.payVoiceTips('支付失败，请刷新付款码重新支付');
            this.payStatus = res.payStatus;
          } else if (res.payStatus === 2) {
            this.payStatus = res.payStatus;
            message.warning(res.msg);
            Util.payVoiceTips('请输入支付密码');
            that.payIntervalId = setInterval(() => {
              // 查看是否支付成功 返回支付结果
              api.searchPayRes(that.sn).then(result => {
                if (result === 'SUCCESS') {
                  this.payStatus = 1;
                  // 轮训查询支付结果 保存当前支付的金额 和方式
                  setStore('trainPayInfo', { payType: obj.payType, payPrice: res.payPrice, sn: that.sn });
                  that.count = 0;
                  that.intervalId = setInterval(that.resCallback, 5000);
                  // 用户支付成功
                  clearInterval(that.payIntervalId);
                  this.sn = '';
                } else if (result === 'USERPAYING') {
                  this.payStatus = 2;
                  // 用户支付中 轮训调用
                  // this.getPayRes(order.sn);
                } else if (result === 'PAYERROR') {
                  this.payStatus = 0;
                  clearInterval(this.payIntervalId);
                  message.warning('用户支付失败，请刷新订单列表进行支付');
                  Util.payVoiceTips('用户支付失败，请刷新订单列表进行支付');
                }
              });
            }, 5000);
          }
          this.SET_MEMBER_TOKEN();
          this.SET_MEMBER_INFO();
          this.paramsData.pageNumber = 1;
          this.commonFn({ startDate: this.getCurrentStartData(), endDate: this.getCurrentEndData(), ...this.paramsData }, param => {
            this.getData(param);
          });
          this.cancelOrder();
        })
        .catch(err => {
          console.log('payOrder: ', err);
          message.error('支付失败，请清空付款码后重试');
          Util.payVoiceTips('支付失败，请刷新付款码重新支付');
        });
    },
    // 支付结果查询
    resCallback() {
      // 支付回调接口
      this.count++;
      // 重置信息
      if (this.count >= 9) {
        removeStore('trainPayInfo');
        clearInterval(this.intervalId);
        this.interval = null;
      }
      const trainPayInfo = JSON.parse(getStore('trainPayInfo'));
      api
        .payCallback({
          orderType: 'TRADE',
          sn: trainPayInfo.sn,
          clientType: 'MICRO'
        })
        .then(res => {
          if (res) {
            clearInterval(this.intervalId);
            this.intervalId = null;
            message.success(`顾客成功支付${trainPayInfo.payPrice}元`);
            Util.payVoiceTips(
              `${trainPayInfo.payType === 'ALIPAY' ? '支付宝' : trainPayInfo.payType === 'WECHAT' ? '微信' : ''}成功收款${trainPayInfo.payPrice}元`
            );
            // 重新获取列表
            this.SET_MEMBER_TOKEN();
            this.SET_MEMBER_INFO();
            this.paramsData.pageNumber = 1;
            this.commonFn({ startDate: this.getCurrentStartData(), endDate: this.getCurrentEndData(), ...this.paramsData }, param => {
              this.getData(param);
            });
            this.cancelOrder();
          } else {
            message.warning('正在多次查询，用户支付结果，弹出7次后自动消失，若未显示结果，请刷新列表查看');
          }
        });
    },
    cancelOrder() {
      this.confirmOrder = false;
    },
    clearTakeOutForm(type) {
      if (type) {
        this.clientType = type;
      }
      this.takeOutForm.resetFields();
      this.deliveryOrderParams.pageNumber = 1;
      this.deliveryOrderParams.platformClient = this.clientType;
      this.getOrderDeliveryList({ ...this.takeOutForm.getFieldsValue(), ...this.deliveryOrderParams });
    },
    takeOutListSearch(e) {
      e.preventDefault();
      this.takeOutForm.validateFields((err, values) => {
        if (!err) {
          const obj = {
            ...this.takeOutForm.getFieldsValue(),
            searchDay: moment(this.takeOutForm.getFieldsValue().searchDay).format('YYYY-MM-DD')
          };
          this.deliveryOrderParams.pageNumber = 1;
          this.getOrderDeliveryList({ ...obj, ...this.deliveryOrderParams });
        }
      });
    },
    deliveryOrderOnChange(pageNumber) {
      this.deliveryOrderParams.pageNumber = pageNumber;
      const obj = {
        ...this.takeOutForm.getFieldsValue(),
        searchDay: moment(this.takeOutForm.getFieldsValue().searchDay).format('YYYY-MM-DD')
      };
      this.getOrderDeliveryList({ ...obj, ...this.deliveryOrderParams });
    },
    toDetail(sn, detailItem) {
      this.getOrderDetail(sn, detailItem, data => this.dealOrderDetail(data, this.clientType === 'WECHAT_MP' ? 'TAKE_OUT' : ''));
    },
    dealOrderDetail(res, detailType) {
      this.detailVisible = true;
      this.detailType = detailType;
      let obj = res;
      // 循环过滤出图片
      this.goodsImgArr = [];
      obj.orderItems.forEach(item => {
        this.goodsImgArr.push(JSON.parse(item.specs).images[0]);
        let childObj = JSON.parse(item.specs);
        for (let k in childObj) {
          if (k === 'images') {
            delete childObj[k];
          }
          // 规格提取
        }
        item.specs = childObj;
      });
      this.currentOrderInfo = obj;
    },
    getOrderDetail(sn, detailItem, callback) {
      this.deliveryTabLoading = true;
      workTableDetailOrder(sn, this.clientType).then(res => {
        if (res) {
          let currentOrderInfo = Object.assign({}, res);
          currentOrderInfo.storeName = this.ShopData.storeName;
          currentOrderInfo.address = this.ShopData.storeAddressDetail;
          if (this.clientType !== 'ELEME') {
            currentOrderInfo.num = currentOrderInfo.orderItems.length;
            currentOrderInfo.orderItems = currentOrderInfo.orderItems.map(label => {
              let specName = '';
              let specs = JSON.parse(label.specs);
              let spaceInfo = '';
              this.promotionType = label.promotionType || '';
              let pickUpCode = currentOrderInfo.remark ? currentOrderInfo.pickUpCode + ' 备' : currentOrderInfo.pickUpCode;
              for (const key in specs) {
                if (key !== 'images') {
                  specName += specs[key] + '';
                  spaceInfo += key + ':' + specs[key] + '';
                }
              }
              let matirialStr = '';
              label.materials.map(matirial => {
                matirialStr += matirial.name + ' x ' + matirial.num + ' ';
              });
              return {
                ...label,
                storeName: this.ShopData.storeName,
                paymentTime: res.paymentTime,
                specName,
                matirialStr,
                pickUpCode,
                spaceInfo
              };
            });
            // 赋值详情弹出框小料列表
            this.skuData = currentOrderInfo.orderItems;

            this.$store.commit('setCurrentOrder', currentOrderInfo);
            if (callback) {
              callback(res);
            }
          } else {
            // 用于保存固定格式列表
            let skuList = [];
            JSON.parse(JSON.parse(res.goodsJson)).forEach(itemGoods => {
              // 判断多个items
              itemGoods.items.forEach(item => {
                skuList.push({
                  goodsId: item.id,
                  name: item.name,
                  num: 1,
                  specs: item.attributes && item.attributes.length ? item.attributes.map(it => it.name + ' x ' + it.value) : [],
                  materials: item.ingredients,
                  spaceInfo: item.attributes && item.attributes.length ? item.attributes.map(it => it.name + ' : ' + it.value + ';').join('') : '-',
                  total: item.total,
                  price: item.price,
                  createdTime: detailItem.createdTime || '',
                  sn: detailItem.sn || ''
                });
              });
            });
            res.orderItems = skuList;
            // 赋值详情弹出框小料列表
            this.skuData = skuList;
            this.currentOrderInfo = res;
            this.$store.commit('setCurrentOrder', { ...this.currentOrderInfo, ...detailItem });
            this.detailVisible = true;
          }
        }
        this.deliveryTabLoading = false;
      });
    },
    reprintLabel(item) {
      if (this.clientType !== 'ELEME') {
        let orderItem = Object.assign({}, item);
        let specName = '';
        let specs = JSON.parse(orderItem.specs);
        for (const key in specs) {
          if (key !== 'images') {
            specName += key + ':' + specs[key] + ' ';
          }
        }
        let matirialStr = '';
        orderItem.materials.map(matirial => {
          matirialStr += matirial.name + ' X ' + matirial.num + ' ';
        });
        orderItem = {
          ...orderItem,
          name: orderItem.goodsName,
          storeName: this.ShopData.storeName,
          paymentTime: item.paymentTime,
          specName,
          matirialStr,
          pickUpCode: item.pickUpCode,
          seq: ''
        };
        printLabel([orderItem]);
      } else {
        let orderItem = Object.assign({}, item);
        let matirialStr = '';
        orderItem.materials.map(matirial => {
          matirialStr += matirial.name + ' x 1';
        });
        orderItem = {
          ...orderItem,
          storeName: this.ShopData.storeName,
          paymentTime: item.createdTime,
          specName: orderItem.spaceInfo,
          matirialStr,
          pickUpCode: '',
          seq: ''
        };
        printLabel([orderItem]);
      }
    },
    detailHandleOk() {
      // 判断取消订单原因
      if (this.detailType === 'PICK_UP' && !this.cancelReason.trim()) {
        return this.$message.warning('请选择门店订单取消原因');
      }

      // 判断是否是取消外卖订单
      if (this.detailType === 'TAKE_OUT' && !this.cancelReasonId) {
        return this.$message.warning('请输入外卖订单取消原因');
      }
      let params = {
        reason: this.cancelReason.trim(),
        orderSn: this.currentOrderInfo['sn']
      };

      if (this.detailType === 'TAKE_OUT') {
        params.reasonId = this.cancelReasonId;
      }

      this.cancelOrderLoading = true;
      workTableDeleteOrder(params).then(res => {
        if (res) {
          this.$message.success('订单已取消');
          this.detailVisible = false;
          this.cancelReason = '';
          this.getOrderDeliveryList({ ...this.takeOutForm.getFieldsValue(), ...this.deliveryOrderParams });

          // if (this.detailType === 'TAKE_OUT') {
          //   this.leftGetData();
          // } else if (this.detailType === 'PICK_UP') {
          //   this.rightGetData();
          // }
        }
        // else {
        //   this.$message.warning('订单取消失败，请重试');
        // }
        this.cancelOrderLoading = false;
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.container {
  height: 100%;
  display: flex;
  position: relative;
  padding: 0 10px 15px;
  // overflow: auto;
  flex-direction: column;

  .searchBox {
    flex: 1;
  }
  .title_box {
    display: flex;
  }
  .left,
  .right {
    flex: 1;
    height: 65px;
    padding: 5px 8px 0;
    background-color: #fff;
  }
}
.searchBtn {
  margin: 0 10px 0 20px;
}
.table {
  margin: 10px 0 20px;
}
.red {
  color: red;
  margin-top: 10px;
}
.content_box {
  overflow: auto;
  background: #fff;
  border-radius: 8px;
  margin-top: 10px;
  padding: 18px;
}

.takeFoodBtn {
  padding-left: 20px;
  position: relative;
  height: 53px;
  line-height: 53px;
  font-size: 21px;
  font-family: Alibaba PuHuiTi;
  font-weight: 500;
  margin-bottom: 10px;
  cursor: pointer;
  transition: all 0.8s;
  text-align: center;
  .refresh {
    position: absolute;
    right: 10px;
    font-size: 14px;
    top: 10px;
    height: 34px;
    line-height: 34px;
    width: 60px;
    text-align: center;
    background-color: #cd3939;
    color: #fff;
    border-radius: 20px;
  }
}
.takeFoodBtn.activeTab {
  color: rgba(51, 152, 154, 1);
}
.takeFoodBtn.activeTab::before {
  content: '';
  display: inline-block;
  position: absolute;
  bottom: -4px;
  height: 6px;
  width: 100px;
  left: 50%;
  transform: translateX(-38%);
  border-radius: 10px;
  background-color: rgba(67, 194, 194, 1);
}
.clearBtn {
  margin-left: 10px;
}
.deliveryPage,
.todayOrderTable {
  margin-top: 20px;
}
.takeOutTab {
  display: flex;
  margin-bottom: 10px;
  > div {
    flex: 1;
    text-align: center;
    height: 65px;
    line-height: 65px;
    font-size: 20px;
    position: relative;
    transition: all 0.8s;
    text-align: center;
    font-weight: 500;
    cursor: pointer;
  }
  > div.activeT {
    color: rgba(51, 152, 154, 1);
  }
  > div.activeT::before {
    content: '';
    display: inline-block;
    position: absolute;
    bottom: 4px;
    height: 6px;
    left: 50%;
    width: 90px;
    transform: translateX(-50%);
    border-radius: 10px;
    background-color: #43c2c2;
  }
}
.lookOrderBtn {
  margin-left: 5px;
}
.cancelReasonTip {
  color: red;
  margin-bottom: 5px;
}
.detailModal {
  /deep/ .ant-modal-title {
    text-align: center;
    .pickUpCode {
      float: left;
    }
  }
}
/deep/ .ant-collapse-header {
  background: rgba(234, 249, 249, 1);
  color: rgba(51, 152, 154, 1) !important;
  font-weight: 500;
}

.payResModal {
  /deep/ .ant-modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 350px;
    div {
      font-weight: 500;
      font-size: 20px;
      text-align: center;
    }
  }
}
</style>
