import context from '../main.js';
import { label, receipt } from '@/constants/constants';

const licenseInfo = {
  strCompanyName: '',
  strLicense: 'EF1B22323D58195A5D929538EB7A6466',
  strLicenseA: '',
  strLicenseB: ''
};
// function getPrintList() {
//   let lodop = getLodop();
//   if (!lodop) {
//     return;
//   }

//   let count = lodop.GET_PRINTER_COUNT();
//   let prints = [];
//   Array(count)
//     .fill(null)
//     .map((...args) => {
//       if (lodop.GET_PRINTER_NAME(args[1]) === 'XP-58' || lodop.GET_PRINTER_NAME(args[1]).indexOf('XP-237B') >= 0) {
//         prints.push({
//           value: args[1],
//           label: lodop.GET_PRINTER_NAME(args[1]),
//           index: args[0]
//         });
//       }
//       return {
//         value: args[1],
//         label: lodop.GET_PRINTER_NAME(args[1]),
//         index: args[1]
//       };
//     });
//   return prints;
// }

// function setPrinter(index) {
//   let lodop = getLodop();
//   lodop.SET_PRINTER_INDEX(index);
//   lodop.SET_PRINT_MODE('PRINT_PAGE_PERCENT', 'Auto-Width');
// }

function getLabelPrinter(printList) {
  if (!printList || printList.length === 0) {
    context.printerList = context.$lodop.getPrintList();
    printList = context.printerList;
  }
  let labelPrint = null;
  printList.map(print => {
    // if (print.label.indexOf('XP-237B') >= 0 || print.label.indexOf('XP-T271U') >= 0) {
    if (print.label.indexOf('XP-T271U') >= 0 || print.label.indexOf('XP-271B') >= 0 || print.label.indexOf('XP-239B') >= 0||print.label.indexOf('XP-T202UA') >= 0 ) {
      // if (print.label.indexOf('GP-1124T') >= 0) {
      labelPrint = print;
    }
  });
  return labelPrint;
}

function getReceiptPrinter(printList) {
  if (!printList || printList.length === 0) {
    context.printerList = context.$lodop.getPrintList();
    printList = context.printerList;
  }
  let receiptPrint = null;
  printList.map(print => {
    if (print.label.indexOf('XP-80C') >= 0) {
      receiptPrint = print;
    }
  });
  return receiptPrint;
}

function print(printList, printData, printType) {
  console.log('~~~print~~~~', context.$lodop, context);
  context.$lodop.setLicenses(licenseInfo);
}

function setPrintMode(lodop) {
  lodop.SET_PRINT_MODE('PRINT_PAGE_PERCENT', 'Auto-Width');
}

function printReceipt(printData) {
  let printerList = context.getStore('printerList');
  context.$lodop.setLicenses(licenseInfo);
  let receiptPrinter = getReceiptPrinter(printerList);
  console.log('~~~printReceipt~~~~', context.$lodop, receiptPrinter, printData);

  // context.$lodop.preview(receipt, printData, 'Auto-Width', receiptPrinter.index);
  // context.$lodop.preview(
  //   receipt,
  //   printData,
  //   'Auto-Width',
  //   receiptPrinter.index,
  //   lodop => setPrinter(lodop, receiptPrinter.index),
  //   lodop => setPrinter(lodop, receiptPrinter.index)
  // );
  // print(printList, printData, 'label');
}

function setPrinter(lodop, index) {
  lodop.SET_PRINT_MODE('PRINT_PAGE_PERCENT', 'Auto-Width');
  context.$lodop.setPrinter(lodop, index);
}

function printLabel(printData) {
  let printerList = context.getStore('printerList');
  context.$lodop.setLicenses(licenseInfo);
  let labelPrinter = getLabelPrinter(printerList);
  console.log('~~~打印标签~~~~', context.$lodop, labelPrinter, printData);
  // context.$lodop.preview(label, printData, 'Auto-Width', labelPrinter.index);
  context.$lodop.print(
    label,
    printData,
    'Auto-Width',
    labelPrinter.index,
    lodop => setPrinter(lodop, labelPrinter.index),
    lodop => setPrinter(lodop, labelPrinter.index)
  );
  // print(printList, printData, 'label');
}

// function print() {
//   let lodop = getLodop();
//   let timeHint = '请于2小时内饮用';
//   return new Promise(function (resolve) {
//     setTimeout(() => {
//       lodop.PRINT_INIT('');
//       let html = `<body>测试</body>`;
//       lodop.ADD_PRINT_TEXT(10, 20, '100%', '100%', timeHint);
//       lodop.SET_PRINT_PAGESIZE(1, 400, 300, '');
//       lodop.PREVIEW();
//     }, 1000);
//   });
// }

export { getLabelPrinter, getReceiptPrinter, print, printLabel, printReceipt, setPrintMode };
