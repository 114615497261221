// 会员信息
<template>
  <div class="container">
    <div>
      <!-- 搜索框 -->
      <div class="header">
        <div class="search-area">
          <a-input-search
            allowClear
            :style="{ width: '300px' }"
            size="large"
            v-model="phone"
            placeholder="请输入会员手机号"
            enter-button="查询"
            @search="searchMember"
          />
        </div>
        <div :style="{ display: 'flex', alignItems: 'center' }">
          <button class="Btn-Default" v-if="!startScan" @click="showInput">
            二维码扫描按钮
          </button>
          <a-input
            v-else
            id="startScan"
            allowClear
            bordered
            v-model="getBtnMemberId"
            size="large"
            :style="{ width: '170px' }"
            placeholder="请输入会员id"
            @keyup.enter="getMemberInfo(getBtnMemberId)"
          />
          <button class="Btn-White-Long clearBtn" @click="clearMemberInfo">
            清空当前会员信息
          </button>
        </div>
      </div>
      <!-- 会员信息 -->
      <div class="memberBox" @click="chooseGiftCard">
        <a-spin :spinning="infoLoading">
          <template v-if="Object.keys(userInfo).length">
            <div class="memberInfo">
              <div>
                <span>会员名称：{{ userInfo.nickName || '无' }}</span>
                <span>ID：{{ userInfo.memberId || '无' }}</span>
              </div>
              <div>
                <span>积分：{{ userInfo.point || '未获取到' }}</span>
                <span>会员等级：{{ userInfo.grade || '无' }}</span>
              </div>
              <div v-if="userInfo.memberGiftInfo">
                <span class="lastSpan">会员卡：{{ userInfo.memberGiftInfo.name }}（有效期:{{ userInfo.memberGiftInfo.expireAt }}）</span>
              </div>
              <div :class="PromotionCheckType === 0 ? 'chooseGift choosedGift' : 'chooseGift'">
                <a-icon type="check" v-if="PromotionCheckType === 0" :style="{ fontSize: '20px', color: '#43c2c2' }" />
              </div>
            </div>
          </template>
          <template v-else>
            <div class="emptyBox">
              <img src="../../assets/payFail.png" alt="暂无会员信息图片" />
              <div>暂无会员信息</div>
            </div>
          </template>
        </a-spin>
      </div>
    </div>
    <!-- 优化优惠券显示 -->
    <a-tabs :activeKey="couponType" class="tabs" @change="couponTabsChange">
      <a-tab-pane :key="index" :tab="item" v-for="(item, index) in couponTabs">
        <a-spin :spinning="infoLoading">
          <div class="coupon-list Scroll">
            <template name="component-name" v-if="userInfo.mobile && couponList && couponList.length">
              <div class="coupon-break couponItem" v-for="item in couponList" :key="item.id" @click="useCoupon(item)">
                <div class="couponPrice">
                  <div class="price">￥{{ item.price || 0 }}</div>
                  <div class="scope">
                    <span v-if="item.couponType === 'PRICE'">满￥{{ item.consumeThreshold }}元可用</span>
                    <span v-else-if="item.couponType === 'DISCOUNT'">{{ item.discount }}折</span>
                    <span v-else-if="item.couponType === 'BUY_GIVE'">买{{ item.buy }}赠{{ item.give }}</span>
                    <span v-else-if="item.couponType === 'TARGET_DISCOUNT'">第{{ item.target }}杯打{{ item.discount }}折</span>
                    <span v-else>{{ item.couponName }}</span>
                  </div>
                </div>
                <div class="">
                  <!-- 仅可用于购买该商品 -->
                  <div class="" style="margin-top: 16px;">{{ useScope(item.scopeType, item.storeName) }}</div>
                  <div class="" style="margin-top: 12px;">{{ item.endTime }}</div>
                </div>
                <div class="choose" v-if="couponType === 0">
                  <a-icon type="check" v-if="couponId === item.id" :style="{ fontSize: '20px', color: '#fff' }" />
                </div>
                <div class="choose-space" v-else-if="couponType === 1">
                  <img src="@/assets/used.png" alt="使用过的图片" />
                </div>
                <div class="choose-space" v-else-if="couponType === 2">
                  <img src="@/assets/expire.png" alt="过期的图片" />
                </div>
              </div>
            </template>
            <template name="component-name">
              <div class="emptyBox">
                <img src="../../assets/payFail.png" alt="暂无优惠券图片" />
                <div>暂无优惠券信息</div>
              </div>
            </template>
          </div>
        </a-spin>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
import Member from '@/api/member';
import Cart from '@/api/cart';
import Regular from '@/libs/regular';
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'memberInfo',
  components: {},
  data() {
    return {
      couponType: 0,
      couponList: [],
      userInfo: {},
      memberId: '',
      beforeMemberId: '',
      getBtnMemberId: '',
      phone: '',
      startScan: false,
      infoLoading: false,
      operateTime: 0,
      couponId: '',
      usedCoupon: {},
      couponTabs: ['可使用', '已使用', '已过期']
    };
  },
  computed: {
    ...mapState(['CartGoodInfo', 'MemberToken', 'MemberInfo', 'ShopData', 'PromotionCheckType'])
  },
  created() {
    if (this.MemberInfo.memberId) {
      this.userInfo = this.MemberInfo;
      this.memberId = this.MemberInfo.memberId;
      this.phone = this.MemberInfo.mobile;
      this.postCouponList();
    }
  },
  methods: {
    ...mapMutations(['SET_MEMBER_TOKEN', 'SET_MEMBER_INFO', 'SET_PROMOTION_TYPE']), // 设置优惠类型 0-礼品卡优惠 1-店铺满减优惠&优惠卷优惠 2-单品优惠 3-三方优惠
    getMemberInfo(data = '') {
      if (!data) {
        return this.$message.warning('信息不能为空');
      }
      let param = {
        storeId: this.ShopData.id
      };
      if (data.length === 19) {
        param.memberId = data;
      } else {
        param.mobile = data;
      }
      // 判断是否搜索了会员手机号 如果是会员就需要传递 beforeMemberId
      if (this.MemberInfo.memberId) {
        param.beforeMemberId = this.MemberInfo.memberId;
      }
      param.cartFlag = Object.keys(this.CartGoodInfo).length > 0;
      this.infoLoading = true;
      Member.getUserInfo(param)
        .then(info => {
          this.userInfo = info;
          this.memberId = info.memberId;
          this.SET_MEMBER_TOKEN(info.accessToken);
          this.SET_MEMBER_INFO(info);
          if (info.MemberInfo && info.MemberInfo.memberGiftInfo && info.MemberInfo.memberGiftInfo.id) {
            this.SET_PROMOTION_TYPE(0);
          }
          this.postCouponList();
          if (this.startScan) {
            this.startScan = false;
            this.getBtnMemberId = '';
            this.$root.Bus.$emit('cartChange', {});
          }
          this.infoLoading = false;
        })
        .catch(err => {
          console.log('err: ', err);
          this.infoLoading = false;
        });
    },
    changeCouponType(index) {
      this.couponType = index;
      this.postCouponList();
    },
    couponTabsChange(activeKey) {
      if (this.couponType === activeKey) {
        return;
      }
      this.couponType = activeKey;
      this.postCouponList();
    },
    postCouponList(obj = { pageNumber: 1, pageSize: 1000 }) {
      let params = { memberId: this.memberId, storeId: this.ShopData.id, ...obj };
      params.memberCouponStatus = ['NEW', 'USED', 'EXPIRE'][this.couponType];
      this.couponList = [];

      if (Object.keys(this.CartGoodInfo).length > 0) {
        params.totalPrice = this.CartGoodInfo.totalPrice;
        params.scopeId = this.CartGoodInfo.scopeId;
        params.goodsInfo = this.CartGoodInfo.goodsInfo;
      }

      Member.postCouponList(params)
        .then(res => {
          this.couponList = res.records;
        })
        .catch(err => {
          console.log('err: ', err);
        });
    },
    searchMember(mobile, e) {
      if (mobile.length === 0) {
        if (e.target.innerText.length > 2) {
          return this.$message.warning('会员手机号不能为空');
        }
        return;
      }

      if (this.phone && !/^1[0-9]{10}$/.test(this.phone)) {
        return this.$message.warning('会员手机号格式有误');
      }
      if (Regular.isPhone(this.phone)) {
        this.getMemberInfo(this.phone);
      }
      // 获取会员优惠券
      this.couponTabsChange(0);
    },
    showInput() {
      this.startScan = true;
      setTimeout(() => {
        document.getElementById('startScan').focus();
      }, 100);
    },
    // 切换会员和退出会员都需要调用checked
    clearMemberInfo() {
      this.couponType = 0;
      this.couponList = [];
      this.getBtnMemberId = '';
      this.phone = '';
      this.startScan = false;
      this.userInfo = {};
      this.beforeMemberId = this.memberId;
      this.memberId = '';

      if (Object.keys(this.CartGoodInfo).length > 0) {
        // 购物车空的时候退出会员
        Member.getQuitLogin({ beforeMemberId: this.beforeMemberId, storeId: this.ShopData.id })
          .then(res => {
            console.log('退出会员: ', res);
            this.SET_MEMBER_TOKEN();
            this.SET_MEMBER_INFO();
            this.SET_PROMOTION_TYPE();
            this.$root.Bus.$emit('cartChange', {});
          })
          .catch(err => {
            console.log('退出会员err: ', err);
            this.delClearCart();
            this.SET_MEMBER_TOKEN();
            this.SET_MEMBER_INFO();
            this.SET_PROMOTION_TYPE();
          });
      } else {
        this.$root.Bus.$emit('cartChange', {});
        this.SET_MEMBER_TOKEN();
        this.SET_MEMBER_INFO();
        this.SET_PROMOTION_TYPE();
      }
    },
    useCoupon(item) {
      let used = true;
      if (this.couponType > 0 || !item.enable) {
        return this.$message.warning('优惠券不可用');
      }
      if (new Date().getTime() - this.operateTime < 1000) {
        return this.$message.warning('点击太频繁!');
      }
      this.operateTime = new Date().getTime();

      if (this.couponId === item.id) {
        this.couponId = '';
        this.usedCoupon = {};
        used = false;
      } else {
        this.couponId = item.id;
        this.usedCoupon = item;
      }

      Member.getUseCoupon({
        storeId: this.ShopData.id,
        cartType: 'CASHIER_CART',
        memberId: this.memberId,
        used,
        memberCouponId: item.id
      })
        .then(() => {
          if (used) {
            this.SET_PROMOTION_TYPE(1);
            this.$message.success('使用成功');
          } else {
            this.$message.success('取消使用');
          }
          this.$root.Bus.$emit('cartChange', {});
        })
        .catch(err => {
          console.log(err);
          this.$message.warning(err.toString());
        });
    },
    chooseGiftCard() {
      if (this.PromotionCheckType) {
        this.useCoupon(this.usedCoupon);
        this.SET_PROMOTION_TYPE(0);

        if (this.couponId === '') {
          this.$root.Bus.$emit('cartChange', {});
        }
      }
    },
    // 清空购物车
    delClearCart() {
      Cart.delClearCart({ storeId: this.ShopData.id, cartType: 'CASHIER_CART' });
    },
    useScope(type, storeName) {
      // 根据字段返回 优惠券适用范围
      let shop = '平台';
      let goods = '全部商品';
      if (storeName && storeName !== 'platform') {
        shop = storeName;
      }
      switch (type) {
        case 'ALL':
          goods = '全部商品';
          break;
        case 'PORTION_GOODS':
          goods = '部分商品';
          break;
        case 'PORTION_GOODS_CATEGORY':
          goods = '部分分类商品';
          break;
      }
      return `${shop}${goods}可用`;
    }
  }
};
</script>
<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  padding-bottom: 36px;
}

$BC: #72c5c7;
$padding-side: 40px;

@mixin df-cc {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin df-sbc {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// 搜索框
.header {
  padding: 10px $padding-side;
  @include df-sbc;
  flex-wrap: wrap;
  margin: 0 15px 0;
  background: #fff;
  border-radius: 10px 10px 0 0;
}

// 会员信息
.memberInfo {
  position: relative;
  height: 250px;
  margin: 35px 40px;
  background: #fff url(../../assets/levelBg.png) no-repeat;
  background-size: 100% 100%;
  padding: 30px 28px;
  color: #fff;
  font-size: 23px;
  margin: 0 15px 15px;
  > div {
    margin-bottom: 20px;
    span {
      display: inline-block;
      width: 50%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  .lastSpan {
    overflow: initial;
  }

  .chooseGift {
    width: 44px;
    height: 44px;
    border-radius: 5px;
    border: 2px solid rgba(67, 194, 194, 0.3);
    background: rgba(255, 255, 255, 0.3);
    position: absolute;
    top: 50px;
    right: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .choosedGift {
    border-radius: 5px;
    border: 2px solid #43c2c2;
    background: #ffffff;
  }
}

.tabs {
  width: 100%;
  /deep/ .ant-tabs-bar.ant-tabs-top-bar {
    padding-left: 135px;
    &::before {
      content: '优惠券';
      display: inline-block;
      border-radius: 8px 0px 0px 8px;
      color: #fff;
      position: absolute;
      left: 30px;
      top: 0;
      height: 54px;
      line-height: 54px;
      width: 109px;
      font-size: 20px;
      text-align: center;
      background: linear-gradient(90deg, #78e3e3 0%, #43c2c2 100%);
    }
  }
  /deep/ .ant-tabs-bar {
    border: none;
  }

  /deep/ .ant-tabs-tabpane {
    height: 100%;
  }
  /deep/ .ant-tabs-nav-container {
    box-shadow: 1px 0px 10px rgb(206, 235, 235);
  }
  /deep/ .ant-tabs-nav {
    > div:first-child {
      color: #000;
      background-color: #eaeaea;
      font-size: 20px;
      border-radius: 10px;
      padding: 0 20px;
      font-weight: 500;
      display: flex;
    }
    .ant-tabs-tab-active.ant-tabs-tab {
      color: #72c5c7 !important;
    }
    > .ant-tabs-ink-bar {
      height: 6px;
      border-radius: 7px;
      left: 20px;
    }
  }
}

// 优惠券list
.coupon-list {
  height: calc(100vh - 230px);
  overflow-y: scroll;
}
.couponItem {
  background: #fff;
  .couponPrice {
    color: #ff7a45;
    position: relative;
    min-width: 125px;
    text-align: center;
    &::before {
      content: '';
      display: inline-block;
      position: absolute;
      right: -23px;
      top: -10px;
      height: 95px;
      border-left: 1px solid #ebedf0;
    }
  }
}
.coupon-break {
  width: 90%;
  height: 130px;
  margin-left: 30px;
  margin-top: 28px;
  padding: 15px 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  font-size: 23px;
  color: #060606;

  .price {
    font-size: 28px;
    font-weight: bold;
    text-align: center;
  }

  .scope {
    font-size: 15px;
  }

  .choose {
    width: 40px;
    height: 40px;
    border: 2px solid #43c2c2;
    display: flex;
    justify-content: center;
    border-radius: 6px;
    align-items: center;
    background: #43c2c2;
    margin-right: 35px;
  }

  .choose-space {
    width: 100px;
    height: 100px;
    img {
      height: 100%;
      width: 100%;
    }
  }
}
.Btn-Default {
  padding: 24px;
  background: #43c2c2;
}
.clearBtn {
  padding: 24px 32px;
  background: #ff5050;
  color: #fff;
  border: none;
  margin-left: 25px;
}
.memberBox {
  margin: 0 15px 20px;
  padding: 15px;
  border-radius: 0 0 10px 10px;
  background-color: #fff;
  .emptyBox {
    padding: 0;
    color: #949393;
    img {
      margin: 0;
      margin: 13px 0;
    }
  }
}
.emptyBox {
  text-align: center;
  padding-top: 100px;
  color: #fff;
  font-size: 16px;
  img {
    width: 130px;
    margin-bottom: 20px;
  }
}
</style>
