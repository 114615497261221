// 统一请求路径前缀在libs/axios.js中修改
import {
  commonUrl,
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
  postRequestWithNoForm
} from '@/libs/axios'

// 订单工作台-订单列表
export const workTableOrderList = (param) => {
  return getRequest('/pickUp/orders', param)
}

// 订单工作台-到店自取-出单
export const workTableOutOrder = (orderNumber, platformClient) => {
  return putRequest(`/pickUp/issue/orderSn/${orderNumber}/${platformClient}`)
}

// 订单工作台-到店自取-订单详情
export const workTableDetailOrder = (orderSn, platformClient) => {
  return getRequest(`/pickUp/${orderSn}/${platformClient}`)
}

// 订单工作台-到店自取-删除订单
export const workTableDeleteOrder = (obj) => {
  return postRequestWithNoForm(`/orders/${obj.orderSn}/cancel`, { reason: obj.reason, reasonId: obj.cancelReasonId })
}
