const order = {
  state: {
    currentOrder: {
      orderItems: [
        {
          goodsId: '',
          name: 'ss',
          num: 1,
          specs: [],
          subTotal: 0,
          unitPrice: 0
        }
      ],
      deliveryMethod: 'SELF_PICK_UP',
      deliveryPrice: 0,
      discountPrice: 0,
      flowPrice: 0,
      goodPrice: 0,
      orderType: 'PICK_UP',
      sn: '',
      storeId: '',
      createTime: '',
      paymentTime: '',
      consigneeDetail: '',
      consigneeName: '',
      pickUpCode: ''
    }
  },
  mutations: {
    setCurrentOrder(state, v) {
      state.currentOrder = v;
    }
  }
};

export default order;
