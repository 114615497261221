import Vue from 'vue';
import Router from 'vue-router';
import Login from '@/views/login/login';
import WorkTable from '@/views/workTable/workTable';
import Receipt from '@/views/Receipt';
import MemberInfo from '@/views/memberInfo/memberInfo';
import Goods from '@/views/goods/goods';
import OrderList from '@/views/orderList/orderList';
import OrderDetail from '@/views/orderDetail/orderDetail';
import Template from '@/views/template/template';

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/workTable',
      name: 'workTable',
      component: WorkTable
    },
    {
      path: '/template',
      name: 'template',
      component: Template
    },
    {
      path: '/memberInfo',
      name: 'memberInfo',
      component: MemberInfo
    },
    {
      path: '/goods',
      name: 'goods',
      component: Goods
    },
    {
      path: '/orderList',
      name: 'orderList',
      component: OrderList
    },
    {
      path: '/orderDetail',
      name: 'orderDetail',
      component: OrderDetail
    },
    {
      path: '/receipt',
      name: 'receipt',
      component: Receipt
    },
    {
      path: '/login',
      name: 'login',
      component: Login
    }
  ]
});
