<template>
  <div>
    <a-modal :visible="show" width="45%" :footer="null" class="single-product-info" @cancel="cancel">
      <template slot="title">三方优惠（代金券）</template>
      <div class="">
        <div class="cash-content">
          <div class="tabs">
            <div :class="tab === 0 ? 'tab checked' : 'tab'" @click="changeTab(0)">代金券</div>
            <!-- <div :class="tab === 1 ? 'tab checked' : 'tab'" @click="changeTab(1)">自定义优惠</div> -->
          </div>
          <div v-if="tab === 0">
            <div class="coupon-area row-sb">
              <div class="coupon-single row-s" v-for="item in couponList" :key="item.id">
                <div class="coupon">{{ item.name }}</div>
                <div class="row-s">
                  <a-icon
                    v-if="vouchers[item.id] === 0"
                    type="minus-circle"
                    theme="filled"
                    :style="{ fontSize: '30px', color: '#b9b5b5' }"
                    @click="changeVouchers(item, 'minus')"
                  />
                  <a-icon
                    v-else
                    type="minus-circle"
                    :style="{ fontSize: '30px', color: 'rgba(67, 194, 194, 1)' }"
                    @click="changeVouchers(item, 'minus')"
                  />
                  &nbsp;&nbsp;&nbsp;{{ vouchers[item.id] }}&nbsp;&nbsp;&nbsp;
                  <a-icon type="plus-circle" :style="{ fontSize: '30px', color: 'rgba(67, 194, 194, 1)' }" @click="changeVouchers(item, 'add')" />
                </div>
              </div>
            </div>
          </div>

          <div v-if="tab === 1">
            <div class="title">自定义优惠</div>
            <div class="row-sb">
              <span>券面额</span>
              <input
                ref="faceValue"
                class="Input-Default"
                style="width: 30%; height: 45px; border: 1px solid #ceebeb;"
                type="text"
                placeholder=""
                v-model="faceValue"
                @click="inputChange('faceValue')"
              />
            </div>
            <div class="row-sb">
              <span>购买券金额</span>
              <input
                ref="paidPrice"
                class="Input-Default"
                style="width: 30%; height: 45px; border: 1px solid #ceebeb;"
                type="text"
                placeholder=""
                v-model="paidPrice"
                @click="inputChange('paidPrice')"
              />
            </div>
          </div>

          <div class="row-sb">
            <span>订单总价:</span>
            <span :style="{ color: '#000 ', fontWeight: 500 }">￥ {{ totalPrice }}</span>
          </div>
          <div class="row-sb" v-if="tab === 0">
            <span>券总面额:</span>
            <span :style="{ color: '#000', fontWeight: 500 }">￥ {{ vouchersValue }}</span>
          </div>
          <div class="row-sb">
            <span>实际应付金额:</span>
            <span :style="{ color: 'rgba(255, 122, 69, 1)', fontWeight: 500 }">￥ {{ discountPrice }}</span>
          </div>
        </div>

        <Keyboard :clear="clearInput" @changeNum="changeNum" @delNum="delNum" @clearNum="clearNum" @enterNum="enterNum" />
      </div>
    </a-modal>
  </div>
</template>

<script>
import Vue from 'vue';
import Keyboard from '@/components/Keyboard';
import api from '@/api/cart';
import { mapState } from 'vuex';

export default {
  name: 'ThirdPartyDiscount',
  components: {
    Keyboard
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    totalPrice: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapState(['ShopData']),
    discountPrice() {
      // 优惠券
      let money = 0;
      if (this.tab === 0) {
        if (this.vouchersValue > this.totalPrice) {
          return 0;
        } else if (this.vouchersValue === 0) {
          return this.totalPrice;
        } else {
          money = (this.totalPrice - this.vouchersValue).toFixed(2);
        }
      }
      // 自定义优惠
      if (this.tab === 1) {
        if (this.faceValue > this.totalPrice) {
          return 0;
        } else {
          money = (this.totalPrice - this.faceValue).toFixed(2);
        }
      }
      if (money.includes('.00')) {
        return money.substring(0, money.length - 3);
      }
      if (money.includes('.') && money[money.length - 1] === '0') {
        return money.substring(0, money.length - 1);
      }
      return money;
    }
  },
  data() {
    return {
      tab: 0, // 0 优惠券 1 自定义优惠
      faceValue: '',
      paidPrice: '',
      inputType: 'faceValue',
      clearInput: false,
      couponList: [],
      vouchers: {}, // 三方优惠（代金券）数量
      vouchersValue: 0
    };
  },
  created() {
    this.getData();
    this.autoFocus();
  },
  methods: {
    getData() {
      if (this.ShopData.id) {
        api.getVoucherList({ storeId: this.ShopData.id, pageSize: 100 }).then(res => {
          // price storeCost faceValue
          this.couponList = res.records || [];
          if (res.records && res.records.length > 0) {
            res.records.map(e => {
              Vue.set(this.vouchers, e.id, 0);
            });
          }
        });
      }
    },
    changeTab(index = 0) {
      this.tab = index;
    },
    // 使用优惠券
    changeVouchers(item, type = 'minus') {
      let num = this.vouchers[item.id];
      delete this.vouchers[item.id];
      switch (type) {
        case 'add':
          this.vouchersValue += item.faceValue;
          num += 1;
          break;
        case 'minus':
          if (num > 0) {
            this.vouchersValue -= item.faceValue;
            num -= 1;
          }
          break;
        default:
          break;
      }
      if (String(this.vouchersValue).includes('.') && String(this.vouchersValue).split('.')[1].length > 2) {
        this.vouchersValue = Number(this.vouchersValue.toFixed(2));
      }
      Vue.set(this.vouchers, item.id, num);
    },
    autoFocus() {
      if (this.$refs[this.inputType]) {
        this.$refs[this.inputType].focus();
      }
    },
    // 使用自定义优惠
    inputChange(e) {
      this.inputType = e;
      this.clearInput = !this.clearInput;
      this.autoFocus();
    },
    changeNum(e) {
      if (this.inputType.length > 0) {
        this[this.inputType] = this[this.inputType] + e;
        this.autoFocus();
      }
    },
    delNum(e) {
      this[this.inputType] = this[this.inputType].substring(0, this[this.inputType].length - 1);
      this.autoFocus();
    },
    clearNum() {
      this[this.inputType] = '';
      this.autoFocus();
    },
    enterNum(e) {
      let close = false;
      let obj = {};
      for (let i in this.vouchers) {
        if (this.vouchers[i] > 0) {
          obj[i] = this.vouchers[i];
          close = true;
        }
      }
      if (this.tab === 0) {
        if (close) {
          this.cancel();
          this.$emit('enterNum', { vouchers: obj });
        } else {
          this.$message.warn('未选择优惠券');
        }
      }
      if (this.tab === 1) {
        if (this.faceValue.length > 0 && this.paidPrice.length > 0) {
          this.cancel();
          this.$emit('enterNum', { faceValue: this.faceValue, paidPrice: this.paidPrice });
        } else {
          this.$message.warn('自定义优惠不能为空');
        }
      }
    },
    cancel() {
      this.tab = 0;
      this.couponList.length > 0 &&
        this.couponList.map(e => {
          Vue.set(this.vouchers, e.id, 0);
        });
      this.faceValue = '';
      this.paidPrice = '';
      this.vouchersValue = 0;
      this.$emit('cancel');
    }
  }
};
</script>

<style lang="scss" scoped>
.row-s {
  display: flex;
  justify-content: start;
  align-items: center;
}

.row-sa {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.row-sb {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  line-height: 38px;
}

.single-product-info {
  /deep/ .ant-modal-header {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
  }
  /deep/ .ant-modal-body {
    padding: 0;
    min-height: 300px;
    font-size: 32px;
    text-align: center;
  }
}

.tabs {
  margin: 10px auto;
  display: flex;
  justify-content: center;
  align-items: center;

  .tab {
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    border: 1px solid #bcb4b4;
    transition: all 0.6s;
    cursor: pointer;
  }

  .checked {
    color: #fff;
    background-color: #72c5c7;
  }
}

.cash-content {
  margin: 20px 30px;

  .coupon-area {
    flex-flow: wrap;

    .icon {
      width: 30px;
      height: 30px;
      margin: 0 10px;
    }

    .coupon-single {
      width: 43%;
      margin-bottom: 30px;
    }

    .coupon {
      width: 80%;
      margin-right: 10px;
      padding: 2px 0;
      border: 1px solid #c4c2c2;
      font-size: 19px;
      color: #727272;
    }
  }
}
</style>
