// 统一请求路径前缀在libs/axios.js中修改
import {
  buyerUrl,
  getRequestWithNoToken,
  postRequestWithNoToken,
  postRequest
} from '@/libs/axios'

export const goodsList = (param) => {
  return getRequestWithNoToken(`${buyerUrl}/buyer/goods/goods`, param)
}

export const goodsSkuDetail = (id) => {
  return getRequestWithNoToken(`${buyerUrl}/buyer/goods/get/${id}`)
}

export const joinCart = (param) => {
  return postRequest(`${buyerUrl}/buyer/trade/carts`, param)
}
