<template>
  <div class="container" ref="container">
    <a-spin :spinning="spinning">
      <div class="infoBox">
        <div>
          <a-button type="info" @click="toOrderList" size="large">返回上一步</a-button>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <a-button type="primary" @click="reprintReceipt" size="large">补打小票</a-button>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <a-button
            type="danger"
            @click="refund"
            size="large"
            v-if="orderDetail.order && (orderDetail.order.orderStatus == 'COMPLETED' || orderDetail.order.orderStatus == 'PRODUCED')"
          >
            退款
          </a-button>
          &nbsp;&nbsp;&nbsp;
          <template v-if="isRefund">
            <span>退款原因：</span>
            <a-select v-model="refundReason" size="middle" style="width: 200px" placeholder="请选择退款原因">
              <a-select-option :value="item" v-for="(item, index) in refundReasonArr" :key="index">
                {{ item }}
              </a-select-option>
            </a-select>
            &nbsp;&nbsp;
            <span>退款金额：</span>
            <a-input-number id="inputNumber" v-model="refundPrice" :min="1" />
            &nbsp;&nbsp;
            <a-button type="primary" @click="confirmRefund" size="large" :loading="refundLoading">确认</a-button>
            &nbsp;&nbsp;
            <a-button @click="isRefund = false" size="large">取消</a-button>
          </template>
        </div>
        <div class="descriptionsBox">
          <a-descriptions>
            <a-descriptions-item label="取餐号">
              <span v-if="orderDetail.order">{{ orderDetail.order.pickUpCode }}</span>
              <span v-else>无</span>
            </a-descriptions-item>
            <a-descriptions-item label="订单编号">
              <span v-if="orderDetail.order">{{ orderDetail.order.sn }}</span>
              <span v-else>无</span>
            </a-descriptions-item>
            <a-descriptions-item label="下单时间">
              <span v-if="orderDetail.order">{{ orderDetail.order.createTime }}</span>
              <span v-else>无</span>
            </a-descriptions-item>
            <a-descriptions-item label="会员名称">
              <span v-if="orderDetail.order && orderDetail.order.memberName">{{ orderDetail.order.memberName }}</span>
              <span v-else>无</span>
            </a-descriptions-item>
            <a-descriptions-item label="预约取餐时间">
              <span v-if="orderDetail.order && orderDetail.order.pickUpTime">{{ orderDetail.order.pickUpTime }}</span>
              <span v-else>无</span>
            </a-descriptions-item>
            <a-descriptions-item label="更新时间">
              <span v-if="orderDetail.order && orderDetail.order.updateTime">{{ orderDetail.order.updateTime }}</span>
              <span v-else>无</span>
            </a-descriptions-item>
            <a-descriptions-item label="会员ID">
              <span v-if="orderDetail.order && orderDetail.order.memberId">{{ orderDetail.order.memberId }}</span>
              <span v-else>无</span>
            </a-descriptions-item>
            <a-descriptions-item label="配送费">
              <div v-if="orderDetail.order && orderDetail.order.deliveryPrice">
                {{ orderDetail.order.deliveryPrice | unitPrice('￥') }}
              </div>
              <span v-else>￥0.00</span>
            </a-descriptions-item>
            <a-descriptions-item label="订单金额">
              <div v-if="orderDetail.order && orderDetail.order.goodsPrice">
                {{
                  (orderDetail.order.goodsPrice + orderDetail.order.packPrice + orderDetail.order.deliveryPrice + orderDetail.order.materialPrice)
                    | unitPrice('￥')
                }}
              </div>
              <div v-else>无</div>
            </a-descriptions-item>
            <a-descriptions-item label="会员手机号">
              <span v-if="orderDetail.order && orderDetail.order.consigneeMobile">{{ orderDetail.order.consigneeMobile }}</span>
              <span v-else>无</span>
            </a-descriptions-item>
            <a-descriptions-item label="订单来源">
              <span v-if="orderDetail.order && orderDetail.order.clientType">{{ orderDetail.order.clientType | clientTypeWay }}</span>
              <span v-else>无</span>
            </a-descriptions-item>
            <a-descriptions-item label="实付金额">
              <div v-if="orderDetail.order && orderDetail.order.flowPrice">
                {{ orderDetail.order.flowPrice | unitPrice('￥') }}
              </div>
              <div v-else>无</div>
            </a-descriptions-item>
            <a-descriptions-item label="配送方式">
              <div v-if="orderDetail.deliveryMethodValue">
                {{ orderDetail.deliveryMethodValue }}
              </div>
              <div v-else>暂无配送方式</div>
            </a-descriptions-item>

            <a-descriptions-item label="订单状态">
              <div v-if="orderDetail.order && orderDetail.order.orderStatus === 'PAID'">已付款</div>
              <div v-else-if="orderDetail.order && orderDetail.order.orderStatus === 'UNPAID'">未付款</div>
              <div v-else-if="orderDetail.order && orderDetail.order.orderStatus === 'UNDELIVERED'">待发货</div>
              <div v-else-if="orderDetail.order && orderDetail.order.orderStatus === 'DELIVERED'">已发货</div>
              <div v-else-if="orderDetail.order && orderDetail.order.orderStatus === 'COMPLETED'">已完成</div>
              <div v-else-if="orderDetail.order && orderDetail.order.orderStatus === 'TAKE'">待核验</div>
              <div v-else-if="orderDetail.order && orderDetail.order.orderStatus === 'CANCELLED'">已取消</div>
              <div v-else-if="orderDetail.order && orderDetail.order.orderStatus === 'PRODUCTION'">制作中</div>
              <div v-else-if="orderDetail.order && orderDetail.order.orderStatus === 'REFUNDED'">已退款</div>
              <div v-else-if="orderDetail.order && orderDetail.order.orderStatus === 'PRODUCED'">制作完成</div>
              <div v-else>无</div>
            </a-descriptions-item>
            <a-descriptions-item label="优惠价格">
              <div v-if="orderDetail.order">
                {{ (orderDetail.order.discountPrice + orderDetail.order.couponPrice + orderDetail.order.giftCardPrice) | unitPrice('￥') }}
              </div>
              <div v-else>无</div>
            </a-descriptions-item>
            <a-descriptions-item label="发票信息">
              <div v-if="orderDetail.order && orderDetail.order.needReceipt == false">不需要发票信息</div>
              <div v-else>需要发票信息</div>
            </a-descriptions-item>

            <a-descriptions-item label="收货信息">
              <div v-if="orderDetail.order">
                {{ orderDetail.order.consigneeName }}
                {{ orderDetail.order.consigneeMobile }}
                {{ orderDetail.order.consigneeAddressPath }}
                {{ orderDetail.order.consigneeDetail }}
              </div>
              <div v-else>无</div>
            </a-descriptions-item>
            <a-descriptions-item label="买家留言">
              <div v-if="orderDetail.order">
                {{ orderDetail.order.remark || '无' }}
              </div>
              <div v-else>无</div>
            </a-descriptions-item>
          </a-descriptions>
        </div>
      </div>

      <div class="tabBox">
        <!-- 表格 -->
        <a-table :columns="columns" :data-source="data" class="table" :pagination="false">
          <template slot="goodsSlot" slot-scope="text, record">
            <div class="goodsBox">
              <div class="left">
                <img :src="record.image" />
              </div>
              <div class="right">
                <div class="div-zoom">
                  <span>{{ record.goodsName }}</span>
                </div>
                <div class="skuInfo">
                  <span v-for="(item, key) in JSON.parse(record.specs)" :key="key">
                    <span v-show="key != 'images'" class="skuSpan">{{ '/' + item }}</span>
                  </span>
                  <template v-if="record.materials.length">
                    <span v-for="(item, key) in record.materials" :key="key">
                      <span class="skuSpan">{{ '/' + item.name }}</span>
                    </span>
                  </template>
                </div>
              </div>
            </div>
          </template>
          <template slot="unitPriceSlot" slot-scope="text, record">
            <span v-if="record.unitPrice">{{ record.unitPrice | unitPrice('￥') }}</span>
            <span v-else>￥0.00</span>
          </template>
          <template slot="subTotalSlot" slot-scope="text, record">
            <span v-if="record.subTotal">{{ record.subTotal | unitPrice('￥') }}</span>
            <span v-else>￥0.00</span>
          </template>
          <template slot="operationSlot" slot-scope="record">
            <a-button type="primary" size="large" @click="reprintLabel(record)">补打标签</a-button>
            <!-- <a-button type="info" @click="reprintReceipt(record)">补打小票</a-button> -->
          </template>
        </a-table>

        <!-- 总计 -->
        <ul>
          <li>
            <span class="title">商品总额：</span>
            <span v-if="orderDetail.order && orderDetail.order.priceDetailDTO">
              {{ (orderDetail.order.priceDetailDTO.goodsPrice + orderDetail.order.priceDetailDTO.materialPrice) | unitPrice('￥') }}
            </span>
            <span v-else>￥0.00</span>
          </li>
          <li>
            <span class="title">优惠金额：</span>
            <span v-if="orderDetail.order">
              {{ (orderDetail.order.discountPrice + orderDetail.order.couponPrice + orderDetail.order.giftCardPrice) | unitPrice('￥') }}
            </span>
            <span v-else>￥0.00</span>
          </li>
          <li>
            <span class="title">配送费：</span>
            <span v-if="orderDetail.order && orderDetail.order.deliveryPrice">{{ orderDetail.order.deliveryPrice | unitPrice('￥') }}</span>
            <span v-else>￥0.00</span>
          </li>
          <li>
            <span class="title">应付金额：</span>
            <span v-if="orderDetail.order && orderDetail.order.flowPrice">{{ orderDetail.order.flowPrice | unitPrice('￥') }}</span>
            <span v-else>￥0.00</span>
          </li>
        </ul>
      </div>
    </a-spin>
    <Receipt ref="receipt" style="display: none" />
  </div>
</template>
<script>
import { workTableOrderDetail, backMoney } from '@/api/order';
import { printLabel } from '@/libs/print';
import Receipt from '@/views/Receipt';
import { mapState } from 'vuex';

export default {
  name: 'orderDetail',
  components: {
    Receipt
  },
  computed: {
    ...mapState(['ShopData'])
  },
  data() {
    return {
      sn: '',
      spinning: true,
      refundLoading: false,
      columns: [
        {
          title: '商品',
          dataIndex: 'goodsName',
          scopedSlots: { customRender: 'goodsSlot' }
        },
        {
          title: '优惠',
          dataIndex: 'discount',
          customRender: (text, row, index) => {
            let resultText = '';
            if (row.promotionType) {
              let type = row.promotionType.split(',');
              if (type.indexOf('PINTUAN') !== -1) {
                resultText += '拼团 ';
              }
              if (type.indexOf('SECKILL') !== -1) {
                resultText += '秒杀 ';
              }
              if (type.indexOf('COUPON') !== -1) {
                if (this.orderDetail.coupon) {
                  if (this.orderDetail.coupon.couponType === 'PRICE') {
                    resultText += `优惠券立减￥${this.orderDetail.coupon.price}元券`;
                  } else if (this.orderDetail.coupon.couponType === 'DISCOUNT') {
                    resultText += `优惠券${this.orderDetail.coupon.discount}折券`;
                  } else if (this.orderDetail.coupon.couponType === 'BUY_GIVE') {
                    resultText += `优惠券买${this.orderDetail.coupon.buy}赠${this.orderDetail.coupon.give}券`;
                  } else if (this.orderDetail.coupon.couponType === 'TARGET_DISCOUNT') {
                    resultText += `优惠券第${this.orderDetail.coupon.target}杯，打${this.orderDetail.coupon.discount}折券`;
                  }
                }
              }
              if (type.indexOf('FULL_DISCOUNT') !== -1) {
                resultText += '满减 ';
              }
              if (type.indexOf('POINTS_GOODS') !== -1) {
                resultText += '积分商品 ';
              }
              if (type.indexOf('KANJIA') !== -1) {
                resultText += '砍价 ';
              }
              if (type.indexOf('COUPON_ACTIVITY') !== -1) {
                resultText += '优惠券活动 ';
              }
              if (type.indexOf('SINGLE_DISCOUNT') !== -1) {
                resultText += '单品优惠 ';
              }
              if (type.indexOf('VOUCHER_DISCOUNT') !== -1) {
                resultText += `三方优惠${this.orderDetail.faceValue +
                  this.orderDetail.couponPrice +
                  this.orderDetail.discountPrice +
                  this.orderDetail.giftCardPrice +
                  this.orderDetail.singleDiscountPrice}`;
              }
              if (type.indexOf('POINTS_GOODS') !== -1) {
                resultText += '积分商品 ';
              }
              if (type.indexOf('GIFT_CART') !== -1) {
                resultText += `礼品卡优惠${row.priceDetailDTO.giftCardPrice}元`;
              }
            }
            if (resultText === '') {
              resultText = '暂无未参与任何促销';
            }
            return {
              children: resultText
            };
          }
        },
        {
          title: '单价',
          dataIndex: 'unitPrice',
          scopedSlots: { customRender: 'unitPriceSlot' }
        },
        {
          title: '数量',
          dataIndex: 'num'
        },
        {
          title: '小计',
          dataIndex: 'subTotal',
          scopedSlots: { customRender: 'subTotalSlot' }
        },
        {
          title: '操作',
          scopedSlots: { customRender: 'operationSlot' }
        }
      ],
      data: [],
      orderDetail: {},
      isRefund: false,
      refundReasonArr: [
        '我买少了',
        '临时有事情不想要了',
        '我的地址信息写错了',
        '送达时间选错了',
        '忘记使用优惠券了',
        '忘记使用礼品卡了',
        '忘记写备注了'
      ],
      refundReason: '',
      refundPrice: ''
    };
  },
  mounted() {
    this.sn = this.$route.query.sn || '';
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.spinning = true;
      workTableOrderDetail(this.sn).then(res => {
        if (res) {
          this.orderDetail = res;
          this.data = res.orderItems;
          this.setCurrentOrder(res);
        }
        this.spinning = false;
      });
    },
    toOrderList() {
      this.$router.push({ path: '/orderList' });
    },
    reprintLabel(item) {
      // console.log('item:', item, this.orderDetail);
      let orderItem = Object.assign({}, item);
      let specName = '';
      let specs = JSON.parse(orderItem.specs);
      for (const key in specs) {
        if (key !== 'images') {
          specName += key + ':' + specs[key] + ' ';
        }
      }
      let matirialStr = '';
      orderItem.materials.map(matirial => {
        matirialStr += matirial.name + ' X ' + matirial.num + ' ';
      });
      let pickUpCode = this.orderDetail.order.pickUpCode;
      orderItem = {
        ...orderItem,
        name: orderItem.goodsName,
        storeName: this.ShopData.storeName,
        paymentTime: this.orderDetail.order.paymentTime,
        specName,
        matirialStr,
        pickUpCode,
        seq: ''
      };

      printLabel([orderItem]);
    },
    reprintReceipt() {
      this.$refs.receipt.print();
    },
    setCurrentOrder(order) {
      let orderItems = Object.assign([], order.orderItems);
      let currentOrderInfo = Object.assign({}, order.order);
      currentOrderInfo.storeName = this.ShopData.storeName;
      currentOrderInfo.address = this.ShopData.storeAddressDetail;
      currentOrderInfo.num = orderItems.length;
      orderItems = orderItems.map(label => {
        let specName = '';
        let specs = JSON.parse(label.specs);
        let pickUpCode = currentOrderInfo.remark ? currentOrderInfo.pickUpCode + ' 备' : currentOrderInfo.pickUpCode;
        for (const key in specs) {
          if (key !== 'images') {
            specName += specs[key] + ' ';
          }
        }
        let matirialStr = '';
        label.materials.map(matirial => {
          matirialStr += matirial.name + ' X ' + matirial.num + ' ';
        });
        return {
          ...label,
          name: label.goodsName,
          storeName: this.ShopData.storeName,
          paymentTime: currentOrderInfo.paymentTime,
          specName,
          matirialStr,
          pickUpCode
        };
      });
      currentOrderInfo.orderItems = orderItems;
      this.$store.commit('setCurrentOrder', currentOrderInfo);
    },
    refund() {
      this.isRefund = true;
      this.refundReason = '';
      this.refundPrice = this.orderDetail.order.flowPrice;
    },
    confirmRefund() {
      if (!this.refundReason) {
        return this.$message.error('请选择退款原因');
      }

      if (this.refundPrice == '') {
        return this.$message.error('请输入退款金额');
      }
      this.refundLoading = true;
      backMoney({ orderSn: this.sn, reason: this.refundReason, refundPrice: this.refundPrice }).then(res => {
        if (Object.prototype.toString.call(res) === '[object Object]') {
          this.isRefund = false;
          this.refundReason = '';
          this.$message.success('退款成功');
          this.orderDetail.order.orderStatus = 'REFUNDED';
        }
        this.refundLoading = false;
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.container {
  height: 100%;
  position: relative;
  padding: 0 10px 15px;
  overflow: auto;
  .descriptionsBox {
    margin: 10px;
  }
  ul {
    display: flex;
    flex-direction: column;
    align-items: end;
    padding-right: 50px;
    margin-top: 20px;
  }
  li {
    list-style: none;
    margin-bottom: 10px;
    span {
      margin-right: 20px;
      display: inline-block;
      width: 80px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
    }
    .title {
      text-align: right;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);
    }
  }
}
/deep/ .ant-spin-nested-loading {
  height: 100%;
}
/deep/ .ant-spin-container {
  height: 100%;
}
.skuSpan {
  font-size: 12px;
  color: #999999;
}
.goodsBox {
  margin-top: 5px;
  height: 80px;
  display: flex;
  width: 100px;
  img {
    height: 60px;
    margin-top: 1px;
    width: 60px;
  }
  .right {
    margin-left: 13px;
  }
}
.skuInfo {
  width: 150px;
}
.tabBox,
.infoBox {
  padding: 20px;
  border-radius: 8px;
  background-color: #fff;
  margin-bottom: 20px;
}

/deep/ .ant-descriptions-item-label {
  color: rgba(0, 0, 0, 0.65);
}
/deep/ .ant-descriptions-item-content {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
}
</style>
