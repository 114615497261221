import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import app from './modules/app';
import order from './modules/order';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    Tab: 0, // 状态
    MemberToken: '',
    MemberInfo: {},
    PromotionCheckType: 1, // 优惠类型
    BtnCanUse: true, // 按钮可用
    CartGoodInfo: {}, // 购物车总价和商品id
    AllGoodList: {}, // 全部商品
    AllGoodInfo: {}, // 全部商品详情
    ShopData: {}, // 店铺信息
    noticeInfo: {}
  },
  mutations: {
    // 改变标签选中状态
    SET_TAB(state, val = 0) {
      state.Tab = val;
    },
    // 设置会员登录token
    SET_MEMBER_TOKEN(state, val = '') {
      state.MemberToken = val;
    },
    // 设置会员登录详情
    SET_MEMBER_INFO(state, val = {}) {
      state.MemberInfo = val;
    },
    // 设置优惠类型 0-礼品卡优惠 1-店铺满减优惠&优惠卷优惠 2-单品优惠 3-三方优惠
    SET_PROMOTION_TYPE(state, val = 1) {
      state.PromotionCheckType = val;
    },
    // 改变按钮是否可用
    SET_BTN_USE_STATE(state, val) {
      state.BtnCanUse = val;
    },
    // 设置购物车总价和scopeId: goodsSku.id逗号分隔
    SET_CART_GOOD_INFO(state, val) {
      state.CartGoodInfo = val;
    },
    // 设置全部商品
    SET_ALL_GOOD_LIST(state, val) {
      state.AllGoodList = val;
    },
    // 设置全部商品详情 {goodId:info}
    SET_ALL_GOOD_INFO(state, val) {
      if (val.size === 0) {
        return;
      }
      for (let item of val) {
        if (item.marketEnable === 'UPPER') {
          state.AllGoodInfo[item[0]] = item[1];
        } else {
          delete state.AllGoodInfo[item[0]];
        }
      }
    },
    // 设置店铺信息
    SET_SHOP_DATA(state, val = {}) {
      state.ShopData = val;
    },
    // 设置订单通知
    SET_NOTICE(state, val) {
      state.noticeInfo = val;
    }
  },
  actions: {},
  modules: {
    app,
    order
  },
  plugins: [createPersistedState()]
});
export default store;
