<template>
  <div>
    <a-modal :visible="confirmOrder" width="50%" class="cart-info" :footer="null" @cancel="cancelOrder">
      <template slot="title">支付订单</template>
      <!-- 内容 -->
      <div class="content">
        <!-- TODO -->
        <div class="nav"></div>
        <div class=""></div>
        <div class="payMoneyBox">
          <div class="title">支付金额</div>
          <div class="money">
            <span :style="{ fontSize: '22px' }">￥</span>
            {{ totalPrice || 0 }}
          </div>
        </div>
        <a-input
          ref="payScan"
          v-if="payType.length > 0"
          class="Input-Default"
          size="large"
          style="width: 80%; height: 45px;margin-top: 26px;"
          type="text"
          :placeholder="`请出示${payType == 'ALIPAY' ? '支付宝' : payType == 'WECHAT' ? '微信' : ''}付款码`"
          allowClear
          v-model="payCode"
          @keyup.enter="payOrder"
        />
        <div :style="{ marginTop: '26px' }" v-else>请选择支付方式</div>
        <div class="row-sa btnBox">
          <a-button class="Btn-White" :style="{ background: 'rgba(60, 117, 237, 1)' }" @click="choosePayWay('ALIPAY')">
            <a-icon type="alipay-circle" :style="{ fontSize: '18px' }" />
            支付宝
          </a-button>
          <a-button class="Btn-White" :style="{ background: 'rgba(48, 193, 117, 1)' }" @click="choosePayWay('WECHAT')">
            <a-icon type="wechat" :style="{ fontSize: '18px' }" />
            微信
          </a-button>
          <a-button class="Btn-White" :style="{ background: 'rgba(255, 122, 69, 1)' }" @click="choosePayWay('CASH')">
            <a-icon type="property-safety" :style="{ fontSize: '18px' }" />
            现金支付
          </a-button>
        </div>
      </div>
      <template slot="footer">
        <button class="Btn-Default" @click="cancelOrder">取消</button>
      </template>
    </a-modal>
    <a-modal :visible="cashPay" width="45%" class="cart-info" :footer="null" @cancel="cancelCashPay">
      <template slot="title">现金支付</template>
      <div class="">
        <div class="cash-content">
          <div class="row-sb">
            <span>应收金额:</span>
            <span>￥ {{ totalPrice }}</span>
          </div>
          <div class="row-sb">
            <span>支付现金</span>
            <a-input size="large" ref="payCash" style="width: 30%; height: 45px;" type="text" placeholder="请输入支付现金额度" v-model="payCash" />
          </div>
          <div class="row-sb">
            <span>找零小计:</span>
            <span>￥{{ changeMoney || 0.0 }}</span>
          </div>
        </div>

        <Keyboard :clear="clearInput" @changeNum="changeNum" @delNum="delNum" @clearNum="clearNum" @enterNum="enterNum" />
      </div>
      <!-- <template slot="footer">
        <button class="Btn-Default" @click="cancelCashPay">取消</button>
      </template> -->
    </a-modal>
  </div>
</template>

<script>
import { message } from 'ant-design-vue';
import Util from '@/libs/util';
import Keyboard from '@/components/Keyboard';
// import { AlipayCircleOutlined } from '@ant-design/icons-vue';

export default {
  components: {
    Keyboard
    // AlipayCircleOutlined
  },
  props: {
    confirmOrder: {
      type: Boolean,
      default: false
    },
    totalPrice: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      clearInput: false,
      payType: '', // WECHAT ALIPAY CASH
      payCode: '',
      payCash: '',
      changeMoney: '',
      cashPay: false
    };
  },
  methods: {
    choosePayWay(type) {
      if (type === 'CASH') {
        this.cashPay = true;
        this.$nextTick(() => {
          if (this.$refs.payCash) {
            this.$refs.payCash.focus();
          }
        });
        return;
      }
      this.payType = type;
      type === 'ALIPAY' ? Util.payVoiceTips('请出示支付宝付款码') : Util.payVoiceTips('请出示微信付款码');
      this.$nextTick(() => {
        this.payCode = '';
        if (this.$refs.payScan) {
          this.$refs.payScan.focus();
        }
      });
    },
    cancelOrder() {
      setTimeout(() => {
        this.payType = '';
        this.payCode = '';
        this.payCash = '';
      }, 1000);
      this.$emit('cancelOrder');
    },
    payOrder() {
      let code = parseInt(this.payCode.substring(0, 2));
      if (this.payType === 'ALIPAY' && (code < 25 || code > 30)) {
        message.error('这不是支付宝付款码，请检查后重新扫码~');
        this.payCode = '';
        return;
      }
      if (this.payType === 'WECHAT' && (this.payCode.length !== 18 || code < 10 || code > 15)) {
        message.error('这不是微信付款码，请检查后重新扫码~');
        this.payCode = '';
        return;
      }

      this.$emit('payOrder', { authCode: this.payCode, payType: this.payType });
      this.payCode = '';
    },
    cancelCashPay() {
      this.cashPay = false;
      this.payCash = '';
      this.changeMoney = '';
      this.clearInput = !this.clearInput;
    },
    getChangeMoney() {
      if (this.payCash > this.totalPrice) {
        let num = 10;
        if (this.payCash.length - this.payCash.indexOf('.') === 3) {
          num = 100;
        }
        this.changeMoney = ((Number(this.payCash) * num - this.totalPrice * num) / num).toFixed(2);
      } else {
        this.changeMoney = '';
      }
    },
    changeNum(e) {
      this.payCash = this.payCash + e;
      this.getChangeMoney();
    },
    delNum(e) {
      this.payCash = e;
      this.getChangeMoney();
    },
    clearNum() {
      this.payCash = '';
      this.getChangeMoney();
    },
    enterNum(e) {
      if (this.payCash < this.totalPrice) {
        message.warn('支付现金小于应收金额');
      } else if (e.length > 0) {
        this.$emit('payOrder', { payType: 'CASH' });
        this.cancelCashPay();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.row-sa {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.row-sb {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  line-height: 38px;
}

.cart-info {
  /deep/ .ant-modal-header {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    color: #434343;
  }
  /deep/ .ant-modal-body {
    padding: 0;
    min-height: 300px;
    font-size: 32px;
    text-align: center;
  }
}

.cash-content {
  margin: 20px 30px;
}
.btnBox {
  position: absolute;
  width: 100%;
  bottom: 20px;
  button {
    width: 135px;
    height: 53px;
    color: #fff;
  }
}
.payMoneyBox {
  margin-top: 20px;
  .title {
    font-size: 17px;
  }
  .money {
    color: #33989a;
  }
}
</style>
