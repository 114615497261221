export default {
  /**
   * @description 配置显示在浏览器标签的title
   */
  title: '格格说收银系统',
  /**
   * @description token在Cookie中存储的天数，默认1天
   */
  cookieExpires: 1,
  /**
   * @description 是否使用国际化，默认为false
   *              如果不使用，则需要在路由中给需要在菜单中展示的路由设置meta: {title: 'xxx'}
   *              用来在菜单中显示文字
   */
  useI18n: true,
  /**
   * @description api请求基础路径
   */
  api_dev: {
    // common: 'https://common-api.qieguo.net',
    // buyer: 'https://buyer-api.qieguo.net',
    // seller: 'https://store-api.qieguo.net',
    // manager: 'https://admin-api.qieguo.net'

    common: 'https://capi-dev.gegeshuo.cn',
    buyer: 'https://bapi-dev.gegeshuo.cn',
    seller: 'https://sapi-dev.gegeshuo.cn',
    manager: 'https://aapi-dev.gegeshuo.cn'

    // common: 'https://capi.gegeshuo.cn',
    // buyer: 'https://bapi.gegeshuo.cn',
    // seller: 'https://sapi.gegeshuo.cn',
    // manager: 'https://aapi.gegeshuo.cn'
  },
  api_prod: {
    // common: 'https://cashier.gegeshuo.cn',
    // buyer: 'https://cashier.gegeshuo.cn',
    // seller: 'https://cashier.gegeshuo.cn',
    // manager: 'https://cashier.gegeshuo.cn'

    // common: 'https://capi.gegeshuo.cn',
    // buyer: 'https://bapi.gegeshuo.cn',
    // seller: 'https://sapi.gegeshuo.cn',
    // manager: 'https://aapi.gegeshuo.cn'

    common: '',
    buyer: '',
    seller: '',
    manager: ''
  },
  /**
   *  @description api请求基础路径前缀
   */
  baseUrlPrefix: '/store',
  /**
   * @description 需要加载的插件
   */
  plugin: {
    'error-store': {
      showInHeader: true, // 设为false后不会在顶部显示错误日志徽标
      developmentOff: true // 设为true后在开发环境不会收集错误信息，方便开发中排查错误
    }
  }
};
