// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import App from './App';
import router from './router';
import store from './store';
// import { Button } from 'ant-design-vue';
import { setStore, getStore, removeStore } from '@/libs/storage';
import ViewUI from 'view-design';
import { md5 } from '@/libs/md5.js';
// 新增代码：引入全部组件及样式
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.less';
import * as filters from '@/libs/filters'; // global filter
// import { getLodop } from '@/libs/lodopFuncs';
import KrPrintDesigner from 'vue-lodop-print-extend';
import 'vue-lodop-print-extend/lib/vue-lodop-print-extend.css';
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh-cn');

Vue.use(KrPrintDesigner);
Vue.use(ViewUI);
Vue.config.productionTip = false;
Vue.config.devtools = true;
Vue.prototype.setStore = setStore;
Vue.prototype.getStore = getStore;
Vue.prototype.removeStore = removeStore;
Vue.prototype.md5 = md5;
// Vue.component(Button.name, Button)
// 新增代码：注册全部组件
Vue.use(Antd);
// let lodop = getLodop();
// Vue.prototype.lodop = lodop;
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]);
});
/* eslint-disable no-new */
let vue = new Vue({
  el: '#app',
  router,
  data: {
    Bus: new Vue()
  },
  store,
  render: h => h(App)
  // components: { App },
  // template: '<App/>',
});

export default vue;
