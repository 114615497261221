<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <Title :shop="ShopData.storeName || ''" :tabs="['全部商品', '会员信息', '工作台', '订单列表']" @chooseTab="chooseTab" class="nav" />
      <div class="app-content">
        <div class="left">
          <Cart />
        </div>
        <div class="right">
          <router-view />
          <!-- 订单通知提醒 -->
          <div class="remind" v-if="isShowTip">
            <div>
              <span>通知</span>
              <span @click="isShowTip = false">关闭</span>
            </div>
            <div @click="chooseTab(2)">{{ tipsInfo }}</div>
          </div>

          <Receipt ref="receipt" style="display: none;" />
        </div>
      </div>
    </div>
  </a-config-provider>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import { getNotice } from '@/api/index';
import { workTableOrderList, workTableDetailOrder } from '@/api/workTable';
import { printLabel } from '@/libs/print';
import Receipt from '@/views/Receipt';

import Util from '@/libs/util';
import Title from '@/components/Title';
import Cart from '@/components/Cart';
import zhCN from 'ant-design-vue/es/locale/zh_CN';

import { mapState, mapMutations } from 'vuex';

export default {
  name: 'App',
  components: {
    Title,
    Cart,
    Receipt
  },
  computed: {
    ...mapState(['Tab', 'ShopData', 'noticeInfo']),
    ...mapState({ currentOrder: state => state.order.currentOrder, ShopData: state => state.ShopData })
  },
  data() {
    return {
      locale: zhCN,
      storeName: '',
      isShowTip: false,
      tipsInfo: '你有新的订单',
      clientType: 'COMMON',
      orderDataParams: {
        pageNumber: 1, // 当前页数
        pageSize: 10, // 页面大小
        storeId: '',
        platformClient: 'COMMON' // 平台客户端标志位：ELEME-饿了么，MEITUAN-美团，WECHAT_MP-微信小程序，CASHIER-收银台  COMMON-收银台，小程序自取外卖
      }
    };
  },
  watch: {
    $route(to, from) {
      if (!to.path.includes('login')) {
        this.$root.Bus.$emit('cartChange', {});
      }
    }
  },
  mounted() {
    let uuid = this.getStore('uuid');
    if (!uuid) {
      uuid = uuidv4();
      this.setStore('uuid', uuid);
    }
    // document.getElementById('app').style.height = document.documentElement.clientHeight - 14 + 'px';
    // document.documentElement.style.overflowY = 'hidden';

    let printList = this.getStore('printList');
    if (!printList) {
      let printerList = this.$lodop.getPrintList();
      this.setStore('printList', printerList);
    }

    // 适配 等比例缩放  注意 如果本地测试宽度不对注释即可  提交时需要放开
    document.body.style.zoom = document.documentElement.clientWidth / 1430;

    // 请求订单通知
    setInterval(() => {
      getNotice().then(res => {
        if (res && res.status === '1') {
          this.SET_NOTICE(res);
          this.tipsInfo = `您有${res.message}`;
          this.isShowTip = true;
          Util.payVoiceTips(`${this.tipsInfo}`);
          this.getOrderData();
        }
      });
    }, 15000);
  },
  beforeDestroy() {},
  methods: {
    ...mapMutations(['SET_TAB', 'SET_NOTICE']),
    chooseTab(e) {
      this.SET_TAB(e);
      if (e === 0) {
        // 切换全部商品
        this.$router.push({ path: '/goods' });
      } else if (e === 1) {
        // 切换会员信息
        this.$router.push({ path: '/memberInfo' });
      } else if (e === 2) {
        this.$router.push({ path: '/workTable' });
      } else if (e === 3) {
        // 切换列表
        this.$router.push({ path: '/orderList' });
      }
    },
    getOrderData() {
      this.orderDataParams.storeId = this.ShopData.id;
      this.orderDataParams.platformClient = this.clientType;
      workTableOrderList(this.orderDataParams).then(res => {
        if (res) {
          const { pickUpOrderDTO } = res;
          this.dealNewOrder(pickUpOrderDTO && pickUpOrderDTO.records.length ? pickUpOrderDTO.records : []);
        }
      });
    },
    dealNewOrder(data) {
      let orders = this.getStore('orderMap');
      if (!orders) {
        let newOrderMap = {};
        data &&
          data.map(order => {
            order.printed = false;
            newOrderMap[order.sn] = order;
          });
        this.setStore('orderMap', newOrderMap);
      } else {
        let orderMap = JSON.parse(orders);
        let newOrder = false;
        let newOrderList = [];
        data &&
          data.map(order => {
            if (!orderMap[order.sn] || orderMap[order.sn].printed === false) {
              newOrder = true;
              order.printed = true;
              orderMap[order.sn] = order;
              newOrderList.push(order);
            }
          });
        if (newOrder) {
          // 有新订单则更新订单map并依次获取详情打印 todo: 待优化，暂时先这样，有可能打印失败，此种情况需要手工触发补打，需后面加逻辑判断打印机返回状态
          this.setStore('orderMap', orderMap);
          newOrderList.map(order => {
            this.getOrderDetail(order.sn, order, this.print);
          });
        }
      }
    },
    getOrderDetail(sn, detailItem, callback) {
      workTableDetailOrder(sn, this.clientType).then(res => {
        if (res) {
          let currentOrderInfo = Object.assign({}, res);
          currentOrderInfo.storeName = this.ShopData.storeName;
          currentOrderInfo.address = this.ShopData.storeAddressDetail;
          if (this.clientType !== 'ELEME') {
            currentOrderInfo.num = currentOrderInfo.orderItems.length;
            currentOrderInfo.orderItems = currentOrderInfo.orderItems.map(label => {
              let specName = '';
              let specs = JSON.parse(label.specs);
              let spaceInfo = '';
              let pickUpCode = currentOrderInfo.remark ? currentOrderInfo.pickUpCode + ' 备' : currentOrderInfo.pickUpCode;
              for (const key in specs) {
                if (key !== 'images') {
                  specName += specs[key] + '';
                  spaceInfo += key + ':' + specs[key] + ';';
                }
              }
              let matirialStr = '';
              label.materials.map(matirial => {
                matirialStr += matirial.name + ' x ' + matirial.num + ' ';
              });
              return {
                ...label,
                storeName: this.ShopData.storeName,
                paymentTime: res.paymentTime,
                specName,
                matirialStr,
                pickUpCode,
                spaceInfo
              };
            });
            this.$store.commit('setCurrentOrder', currentOrderInfo);
            if (callback) {
              callback(res);
            }
          } else {
            // 用于保存固定格式列表
            let skuList = [];
            JSON.parse(JSON.parse(res.goodsJson)).forEach(itemGoods => {
              // 判断多个items
              itemGoods.items.forEach(item => {
                skuList.push({
                  goodsId: item.id,
                  name: item.name,
                  num: 1,
                  specs: item.attributes && item.attributes.length ? item.attributes.map(it => it.name + ' x ' + it.value) : [],
                  materials: item.ingredients,
                  spaceInfo: item.attributes && item.attributes.length ? item.attributes.map(it => it.name + ' : ' + it.value + ';').join('') : '-',
                  total: item.total,
                  price: item.price,
                  createdTime: detailItem.createdTime || '',
                  sn: detailItem.sn || ''
                });
              });
            });
            res.orderItems = skuList;
            // 赋值详情弹出框小料列表
            this.skuData = skuList;
            this.$store.commit('setCurrentOrder', { ...res, ...detailItem });
            if (callback) {
              callback(res);
            }
          }
        }
      });
    },
    print() {
      if (this.currentOrder && this.currentOrder.orderItems.length > 0) {
        let orderItems = Object.assign([], this.currentOrder.orderItems);
        let newOrderItems = [];
        let seq = 1;
        let total = 0;
        orderItems.map(item => {
          total += item.num;
        });
        orderItems.map((orderItem, index) => {
          if (orderItem.num > 1) {
            for (let i = 0; i < orderItem.num; i++) {
              let tempOrder = Object.assign({}, orderItem);
              tempOrder.seq = `${seq}/${total}`;
              newOrderItems.push(tempOrder);
              seq++;
            }
          } else {
            orderItem.seq = seq + '/' + total;
            newOrderItems.push(orderItem);
            seq++;
          }
        });
        printLabel(newOrderItems);
      }
      // todo：临时处理，待优化
      setTimeout(() => {
        console.log('准备打印完成小票：', this.currentOrder);
        this.$refs.receipt.print();
      }, 2000);
    }
  }
};
</script>

<style lang="scss">
body {
  margin: 7px;
  overflow: hidden;
}
#app {
  padding: 6px 7px;
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background-color: #33989a;
  height: 100%;
}
.nav {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}
.app-content {
  display: flex;
  justify-content: space-between;
  height: calc(100% - 75px);
  margin-top: $TITLE_HEIGHT;

  > .left {
    width: $LEFT_BODY_WIDTH;
    height: 100%;
    border-radius: 8px;
    overflow: hidden;
  }

  > .right {
    width: $RIGHT_BODY_WIDTH;
  }
}
.remind {
  position: fixed;
  bottom: 64px;
  right: 38px;
  height: 85px;
  width: 230px;
  z-index: 999;
  background-color: #fff;
  padding: 7px 20px;
  border-radius: 8px;
  font-weight: 500;
  div {
    display: flex;
    justify-content: space-between;
    span {
      color: #807c7c;
      font-size: 17px;
      cursor: pointer;
    }

    margin-bottom: 10px;
  }
  div:last-child {
    color: #df1d1d;
    font-size: 17px;
  }
}
</style>
