// 统一请求路径前缀在libs/axios.js中修改
import { buyerUrl, getRequest, postRequest, putRequest, deleteRequest, getRequestWithNoToken, postRequestWithNoToken } from '@/libs/axios';
import store from '@/store/index';

let header = {};

const setHeaderToken = () => {
  if (store.state.MemberToken.length > 0) {
    header.accessToken = store.state.MemberToken;
  } else {
    delete header.accessToken;
  }
};

setHeaderToken();

/**
 * 获取购物车页面购物车详情
 * @param {string} params.cartType * CASHIER_CART
 * @param {string} params.storeId *
 * @param {*} header.accessToken member token
 * @returns
 */
const getCartAll = (params = {}) => {
  setHeaderToken();
  return getRequest(buyerUrl + '/buyer/trade/carts/all', params, header);
};

/**
 * 获取购物车页面购物车结算
 * @param {string} params.cartType * CASHIER_CART
 * @param {string} params.storeId *
 * @param {string} params.deliveryPrice * 配送费
 * @param {string} params.promotionCheckType * 优惠类型 0-礼品卡优惠 1-店铺满减优惠&优惠卷优惠 2-单品优惠 3-三方优惠
 * @param {*} header.accessToken member token
 * @returns
 *  @ApiModelProperty(value = "单品优惠金额")
    private Double singleReducedPrice;

    @ApiModelProperty(value = "券面金额")
    private Double faceValue;

    @ApiModelProperty(value = "券已付金额")
    private Double paidPrice;

    @ApiModelProperty(value = "代金券优惠金额")
    private Double voucherPrice;
 */
const getCartCheck = (params = {}) => {
  setHeaderToken();
  return getRequest(buyerUrl + '/buyer/trade/carts/checked', params, header);
};

/**
 * 添加商品到购物车
 * @param {string} cartType * CASHIER_CART
 * @param {string} storeId *
 * @param {string} num *
 * @param {string} skuId * goodsSku.id
 * @param {string} uuId sku唯一ID 修改的时候传，新增不传
 * @param {string} materials 	小料参数map{materialsId:materialsNum}
 */
const postChangeCart = (params = {}) => {
  setHeaderToken();
  return postRequest(buyerUrl + '/buyer/trade/carts', params, { 'Content-Type': 'application/json', ...header });
};

/**
 * 清空购物车
 * @param {string} params.cartType * CASHIER_CART
 * @param {string} params.storeId *
 * @param {*} header.accessToken member token
 */
const delClearCart = (params = {}) => {
  setHeaderToken();
  return deleteRequest(buyerUrl + '/buyer/trade/carts', params, header);
};

/**
 * 选择优惠券
 * @param {string} cartType * CASHIER_CART
 * @param {string} storeId *
 * @param {string} memberCouponId *
 * @param {string} used * true
 */
const getUseCoupon = (params = {}) => {
  return getRequest(buyerUrl + '/buyer/trade/carts/select/coupon', params);
};

/**
 * 生成订单
 * @param {string} cartType * CASHIER_CART
 * @param {string} storeId *
 * @param {string} way * CASHIER_CART
 * @param {string} client * CASHIER
 */
const postBookOrder = (params = {}) => {
  setHeaderToken();
  return postRequest(buyerUrl + '/buyer/trade/carts/create/trade', params, { 'Content-Type': 'application/json', ...header });
};

/**
 * 二维码付款 + 现金支付
 * @param {string} sn * sn
 * @param {string} payType * WECHAT ALIPAY CASH
 * @param {string} orderType  TRADE
 * @param {string} clientType  CASHIER
 * @param {string} authCode * 二维码信息
 */
const getQRCodePay = (params = {}) => {
  let payType = params.payType;
  let urlLast = '/MICRO';
  if (payType === 'CASH') {
    urlLast = '/CASH';
  }
  delete params.payType;
  params.orderType = 'TRADE';
  params.clientType = 'CASHIER';
  return getRequest(buyerUrl + '/buyer/cashier/pay/' + payType + urlLast, params);
};

/**
 * 支付结果查询
 * @param orderType 交易类型,可用值:TRADE,ORDER,RECHARGE
 * @param sn   订单编号
 */
export function payCallback(params) {
  return getRequest(`${buyerUrl}/buyer/cashier/result`, params);
}

/**
 * 单品优惠 调用完需要调用购物车 getCartCheck 方法
 * @param cartType * CASHIER_CART
 * @param updatePrice 单品改价（改后的价格）
 * @param updateDiscount 单品折扣(1-10之间的数字折扣)
 * @param uuid 单品优惠uuid
 * @param storeId 店铺id
 */
export function postSReduced(params) {
  setHeaderToken();
  return postRequest(`${buyerUrl}/buyer/trade/carts/single/reduced`, params, { ...header, 'Content-Type': 'application/json' });
}

/**
 * 三方优惠（代金券） 调用完需要调用购物车 getCartCheck 方法
 * @param cartType * CASHIER_CART
 * @param faceValue 券面金额
 * @param paidPrice 券已付金额
 * @param vouchers   Map : key - 代金券ID,value - 数量
 * @param storeId 店铺id
 */
export function postTReduced(params) {
  setHeaderToken();
  return postRequest(`${buyerUrl}/buyer/trade/carts/third/reduced`, params, { ...header, 'Content-Type': 'application/json' });
}

/**
 * 获取店铺代金券列表
 * @param storeId
 */
export function getVoucherList(params) {
  return getRequest(`${buyerUrl}/buyer/voucher/list`, params);
}

/**
 * 轮训查询支付结果
 * @param storeId
 */
export function searchPayRes(tradeSn) {
  return getRequest(`/orders/query/${tradeSn}`);
}

export default {
  getCartAll,
  getCartCheck,
  postChangeCart,
  delClearCart,
  getUseCoupon,
  postBookOrder,
  getQRCodePay,
  payCallback,
  postSReduced,
  postTReduced,
  getVoucherList,
  searchPayRes
};
