<template>
  <div class="container" ref="container">
    <div class="title_box">
      <div class="left" ref="left" :style="{ borderRadius: '10px 0 0 10px' }">
        <div :class="['takeFoodBtn', clientType === 'CASHIER' ? 'activeTab' : '']" @click="clientType = 'CASHIER'">
          收银台
          <span class="orderNum">{{ cashierOrderCount || 0 }}</span>
          <span class="refresh" @click.stop="orderRefresh('CASHIER')" v-show="clientType === 'CASHIER'">
            <img src="../../assets/refresh.png" alt="刷新" />
          </span>
        </div>
      </div>
      <div class="right" ref="right" :style="{ borderRadius: '0' }">
        <div :class="['takeFoodBtn', clientType === 'WECHAT_MP' ? 'activeTab' : '']" @click="clientType = 'WECHAT_MP'">
          小程序
          <span class="orderNum">{{ appletOrderCount || 0 }}</span>

          <span class="refresh" @click.stop="orderRefresh('WECHAT_MP')" v-show="clientType === 'WECHAT_MP'">
            <img src="../../assets/refresh.png" alt="刷新" />
          </span>
        </div>
      </div>
      <div class="right" ref="right" :style="{ borderRadius: '0 10px 10px 0', background: 'linear-gradient(270deg, #eaffff 0%, #ffffff 100%) ' }">
        <div :class="['takeFoodBtn', clientType === 'ELEME' ? 'activeTab' : '']" @click="clientType = 'ELEME'">
          饿了么
          <span class="orderNum">{{ elemeOrderCount || 0 }}</span>
          <span class="refresh" @click.stop="orderRefresh('ELEME')" v-show="clientType === 'ELEME'">
            <img src="../../assets/refresh.png" alt="刷新" />
          </span>
        </div>
      </div>
    </div>
    <div class="content_box">
      <div>
        <a-spin tip="获取中" :spinning="spinning">
          <!-- list -->
          <div class="orderList">
            <template v-if="orderData.length">
              <div
                class="orderItem"
                :style="{ backgroundColor: item.orderType === 'PICK_UP' ? '' : 'rgba(206, 235, 235, 1)' }"
                v-for="(item, index) in orderData"
                :key="index"
              >
                <div class="top">
                  <div class="remarkTip" v-if="item.remark">顾客有备注</div>
                  <div>
                    <template v-if="clientType !== 'ELEME'">
                      <span>订单号：</span>
                      <span>{{ item.pickUpCode || '无' }}</span>
                    </template>
                    <template v-else>
                      <span>取餐号：</span>
                      <span>{{ item.sn || '无' }}</span>
                    </template>
                  </div>
                  <div>
                    <span>饮品数量：</span>
                    <span>{{ item.num || '0' }}</span>
                  </div>
                  <div>
                    <span>下单时间：</span>
                    <span>{{ item.createdTime || '无' }}</span>
                  </div>
                  <div>
                    <span>预约时间：</span>
                    <span>{{ item.pickUpTime || '无' }}</span>
                  </div>
                </div>
                <div class="bottom">
                  <div class="price">
                    <span>
                      价格：&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <span :style="{ color: 'rgba(255, 122, 69, 1)' }">{{ `￥${item.flowPrice || 0.0} ` }}</span>
                    </span>
                    <span v-show="clientType === 'WECHAT_MP'">
                      订单类型：{{ item.orderType === 'TAKE_OUT' ? '外卖' : item.orderType === 'PICK_UP' ? '自取' : '饿了么外卖' }}
                    </span>
                  </div>
                  <div class="btnList">
                    <a-button class="outOrder" type="primary" @click="showOutOrderConfirm(item.pickUpCode, item.sn, item.orderType)">
                      出单
                    </a-button>
                    <a-button class="toDetail" type="primary" @click="toDetail(item.sn, item.orderType, item)">
                      详情
                    </a-button>
                    <!-- <a-button class="print" type="primary" @click="toPrint(item.orderType)">
                    打印
                  </a-button> -->
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="emptyBox">
                <img src="../../assets/payFail.png" alt="暂无数据图片" />
                <div>暂无订单</div>
              </div>
            </template>
          </div>
        </a-spin>
      </div>
    </div>

    <div class="pageBox">
      <a-pagination :current="orderDataParams.pageNumber" :total="orderDataTotal" @change="orderOnChange" :hideOnSinglePage="true" />
    </div>
    <!-- 详情modal -->
    <a-modal
      :visible="detailVisible"
      @cancel="detailHandleCancel"
      :maskClosable="false"
      destroyOnClose
      closable
      :afterClose="detailAfterClose"
      class="detailModal"
      :width="750"
      :getContainer="() => $refs.container"
    >
      <template slot="title">
        <span class="pickUpCode" v-show="clientType !== 'ELEME'">{{ currentOrderInfo.pickUpCode || '无' }}</span>
        <span>订单详情</span>
      </template>
      <!-- 订单信息 -->
      <div>
        <a-descriptions>
          <a-descriptions-item label="订单类型">
            <span v-if="detailType === 'TAKE_OUT'">外卖</span>
            <span v-else-if="detailType === 'PICK_UP'">自提</span>
            <span v-else>饿了么外卖</span>
          </a-descriptions-item>
          <a-descriptions-item label="制作状态">制作中</a-descriptions-item>
          <a-descriptions-item>
            <a-button type="primary" @click="reprintReceipt" class="btnStyle">
              重打小票
            </a-button>
          </a-descriptions-item>
          <a-descriptions-item label="订单总额">
            {{ '￥' + (currentOrderInfo.goodPrice + currentOrderInfo.materialPrice) || '0.00' }}
          </a-descriptions-item>
          <a-descriptions-item label="实收金额">￥{{ currentOrderInfo.flowPrice || '0.00' }}</a-descriptions-item>
          <a-descriptions-item label="优惠金额">
            {{
              '￥' + (currentOrderInfo.discountPrice + currentOrderInfo.couponPrice + currentOrderInfo.giftCardPrice).toString().replace('-', '') ||
                '0.00'
            }}
          </a-descriptions-item>
          <a-descriptions-item label="运费">{{ '￥' + currentOrderInfo.deliveryPrice || '0.00' }}</a-descriptions-item>
          <a-descriptions-item label="下单时间">{{ currentOrderInfo.createTime || '-' }}</a-descriptions-item>
          <!-- <a-descriptions-item label="预计送达时间">2022-01-09 18:10</a-descriptions-item>
          <a-descriptions-item label="订单完成时间">2022-01-09 18:10</a-descriptions-item> -->
          <a-descriptions-item label="预约时间">{{ currentOrderInfo.pickUpTime || '立即取餐' }}</a-descriptions-item>
          <a-descriptions-item label="支付方式">
            <span v-if="currentOrderInfo.paymentMethod === 'CASH'">现金支付</span>
            <span v-else-if="currentOrderInfo.paymentMethod === 'WECHAT'">微信</span>
            <span v-else-if="currentOrderInfo.paymentMethod === 'ALIPAY'">支付宝</span>
            <span v-else-if="currentOrderInfo.paymentMethod === 'WALLET'">余额支付</span>
            <span v-else-if="currentOrderInfo.paymentMethod === 'BANK_TRANSFER'">线下转账</span>
            <span v-else-if="currentOrderInfo.paymentMethod === 'ELEME'">饿了么（跳转支付宝支付）</span>
            <span v-else>-</span>
          </a-descriptions-item>
          <a-descriptions-item label="下单平台" v-if="clientType === 'ELEME'">饿了么</a-descriptions-item>
          <a-descriptions-item label="优惠内容" v-if="clientType !== 'ELEME' && promotionType">
            <span v-if="promotionType.indexOf('PINTUAN') !== -1">
              拼团
            </span>
            <span v-else-if="promotionType.indexOf('SECKILL') !== -1">
              秒杀
            </span>
            <span v-else-if="promotionType.indexOf('FULL_DISCOUNT') !== -1">
              满减
            </span>
            <span v-else-if="promotionType.indexOf('VOUCHER_DISCOUNT') !== -1">
              三方优惠{{ this.currentOrderInfo.totalReducedPrice || '0.00' }}元
            </span>
            <span v-else-if="promotionType.indexOf('POINTS_GOODS') !== -1">
              积分商品
            </span>
            <span v-else-if="promotionType.indexOf('KANJIA') !== -1">
              砍价
            </span>
            <span v-else-if="promotionType.indexOf('COUPON_ACTIVITY') !== -1">
              优惠券活动
            </span>
            <span v-else-if="promotionType.indexOf('SINGLE_DISCOUNT') !== -1">
              单品优惠
            </span>
            <span v-else-if="promotionType.indexOf('COUPON') !== -1">
              <span v-if="currentOrderInfo.coupon.couponType == 'PRICE'">
                {{ `优惠券立减￥${this.currentOrderInfo.coupon.price}元券` }}
              </span>
              <span v-if="currentOrderInfo.coupon.couponType == 'PRICE'">
                {{ `优惠券立减￥${this.currentOrderInfo.coupon.price}元券` }}
              </span>
              <span v-else-if="currentOrderInfo.coupon.couponType == 'DISCOUNT'">
                {{ `优惠券${this.currentOrderInfo.coupon.discount}折券` }}
              </span>
              <span v-else-if="currentOrderInfo.coupon.couponType == 'BUY_GIVE'">
                {{ `优惠券买${this.currentOrderInfo.coupon.buy}赠${this.currentOrderInfo.coupon.give}券` }}
              </span>
              <span v-else-if="currentOrderInfo.coupon.couponType == 'TARGET_DISCOUNT'">
                {{ `优惠券第${this.currentOrderInfo.coupon.target}杯，打${this.currentOrderInfo.coupon.discount}折券` }}
              </span>
              <!-- {{ `优惠券优惠￥${currentOrderInfo.discountPrice || 0}元` }} -->
            </span>
            <span v-else-if="promotionType.indexOf('GIFT_CART') !== -1">
              {{ `${currentOrderInfo.giftCardName}礼品卡优惠￥${currentOrderInfo.giftCardPrice || '0.00'}元` }}
            </span>
            <span v-else>-</span>
          </a-descriptions-item>
        </a-descriptions>
        <!-- 门店订单取消原因 -->
        <div v-if="detailType === 'PICK_UP'">
          <div class="cancelReasonTip">注意：门店订单取消原因（取消订单必选）；</div>
          <a-select v-model="cancelReason" style="width: 100%;" placeholder="请选择门店订单取消原因">
            <a-select-option :value="item" v-for="(item, index) in reason" :key="index">
              {{ item }}
            </a-select-option>
          </a-select>
        </div>
        <!-- 外卖订单取消原因 -->
        <div v-if="detailType === 'TAKE_OUT'">
          <div class="cancelReasonTip">注意：外卖订单取消原因（取消订单必选）；</div>
          <a-select v-model="cancelReasonId" style="width: 100%;" placeholder="请选择外卖订单取消原因">
            <a-select-option :value="101">
              顾客主动取消
            </a-select-option>
            <a-select-option :value="102">
              顾客更改配送时间/地址
            </a-select-option>
            <a-select-option :value="103">
              备货、包装、货品质量问题取消
            </a-select-option>
            <a-select-option :value="199">
              其他接入方原因
            </a-select-option>
            <a-select-option :value="201">
              配送服务态度问题取消
            </a-select-option>
            <a-select-option :value="202">
              骑手配送不及时
            </a-select-option>
            <a-select-option :value="203">
              骑手取货不及时
            </a-select-option>
            <a-select-option :value="299">
              其他美团配送原因
            </a-select-option>
            <a-select-option :value="399">
              其他原因
            </a-select-option>
          </a-select>
        </div>
      </div>
      <br />
      <!-- 会员信息 -->
      <div class="top_line">
        <a-descriptions title="会员信息">
          <a-descriptions-item label="会员昵称">{{ currentOrderInfo.consigneeName }}</a-descriptions-item>
          <a-descriptions-item label="联系方式">{{ currentOrderInfo.mobile || '-' }}</a-descriptions-item>
          <a-descriptions-item label="备注" v-if="clientType !== 'CASHIER'">
            {{ currentOrderInfo.remark || '请提前跟商家协商好' }}
          </a-descriptions-item>
          <a-descriptions-item label="收货地址">{{ currentOrderInfo.consigneeDetail || '-' }}</a-descriptions-item>
        </a-descriptions>
      </div>

      <!-- 配送信息 -->
      <div class="top_line" v-show="detailType === 'TAKE_OUT' || clientType === 'ELEME'">
        <a-descriptions title="配送信息" v-show="detailType === 'TAKE_OUT'">
          <template v-if="currentOrderInfo.meiTuan">
            <a-descriptions-item label="配送状态" v-if="currentOrderInfo.meiTuan.status === 1">商户未接单</a-descriptions-item>
            <a-descriptions-item label="配送状态" v-if="currentOrderInfo.meiTuan.status === 2">商户已接单</a-descriptions-item>
            <a-descriptions-item label="配送状态" v-if="currentOrderInfo.meiTuan.status === 3">订单取消（接单前）</a-descriptions-item>
            <a-descriptions-item label="配送状态" v-if="currentOrderInfo.meiTuan.status === 4">订单置为无效（接单后）</a-descriptions-item>
            <a-descriptions-item label="配送状态" v-if="currentOrderInfo.meiTuan.status === 5">
              订单强制无效（商家主动取消已接订单）
            </a-descriptions-item>
            <a-descriptions-item label="配送状态" v-if="currentOrderInfo.meiTuan.status === 6">订单完结</a-descriptions-item>
            <a-descriptions-item label="配送状态" v-if="currentOrderInfo.meiTuan.status === 0">待调度</a-descriptions-item>
            <a-descriptions-item label="配送状态" v-if="currentOrderInfo.meiTuan.status === 20">已接单</a-descriptions-item>
            <a-descriptions-item label="配送状态" v-if="currentOrderInfo.meiTuan.status === 30">已取货</a-descriptions-item>
            <a-descriptions-item label="配送状态" v-if="currentOrderInfo.meiTuan.status === 50">已送达</a-descriptions-item>
            <a-descriptions-item label="配送状态" v-if="currentOrderInfo.meiTuan.status === 99">已取消</a-descriptions-item>
            <a-descriptions-item label="骑手姓名">{{ currentOrderInfo.meiTuan.courierName || '-' }}</a-descriptions-item>
            <a-descriptions-item label="骑手联系方式">{{ currentOrderInfo.meiTuan.courierPhone || '-' }}</a-descriptions-item>
            <!-- <a-descriptions-item label="接单时间">{{ currentOrderInfo.meiTuan.courierName || '-' }}</a-descriptions-item>
            <a-descriptions-item label="到店时间">{{ currentOrderInfo.meiTuan.courierName || '-' }}</a-descriptions-item> -->
            <a-descriptions-item label="期望送达时间" v-if="currentOrderInfo.meiTuan.expectedDeliveryTime">
              {{ currentOrderInfo.meiTuan.expectedDeliveryTime | formatDateMill }}
            </a-descriptions-item>
            <a-descriptions-item label="期望送达时间" v-else>-</a-descriptions-item>
          </template>
        </a-descriptions>
        <a-descriptions title="配送信息" v-show="clientType === 'ELEME'">
          <a-descriptions-item>
            <h4>因三方平台限制，无法展示骑手信息，可前往商家后台查看</h4>
          </a-descriptions-item>
        </a-descriptions>
      </div>

      <!-- 商品信息 -->
      <div class="top_line">
        <div class="goodsTitle">商品信息</div>
        <a-table :columns="skuColumns" :data-source="skuData" :pagination="false">
          <span slot="specsInfo" slot-scope="text">
            {{ text }}
          </span>
          <span slot="materials" slot-scope="text, record" v-if="clientType !== 'ELEME'">
            {{ record.materials && record.materials.length ? record.materials.map(item => item.name + 'x' + item.num).join(',') : '无' }}
          </span>
          <span slot="materials" slot-scope="text, record" v-else>
            {{ record.materials && record.materials.length ? record.materials.map(item => item.name + 'x1').join(',') : '无' }}
          </span>
          <span slot="action" slot-scope="text, record">
            <a-button type="primary" class="outOrder btnStyle" @click="reprintLabel(record)" :style="{ background: 'rgba(67, 194, 194, 1)' }">
              重打杯贴
            </a-button>
          </span>
        </a-table>
      </div>
      <template slot="footer">
        <!-- <span class="payMoney">支付金额：￥{{ currentOrderInfo.flowPrice || '0.00' }}</span> -->
        <a-button type="danger" class="btnStyle" :loading="cancelOrderLoading" @click="detailHandleOk" v-if="clientType !== 'ELEME'">
          取消订单
        </a-button>
        <a-button type="text" class="btnStyle" :style="{ background: 'rgba(255, 154, 154, 1)', color: '#fff' }" v-else>
          请前往饿了么商家后台取消
        </a-button>
      </template>
    </a-modal>

    <!-- 打印modal -->
    <a-modal
      v-model="printVisible"
      @cancel="printHandleCancel"
      destroyOnClose
      :maskClosable="false"
      closable
      class="printModal"
      width="350px"
      :getContainer="() => $refs.container"
      :afterClose="printAfterClose"
    >
      <template slot="title"></template>
      <template slot="footer">
        <a-button key="submit" :loading="printLoading" @click="printHandleOk">
          打印
        </a-button>
      </template>
      <a-radio-group v-model="printValue" @change="printOnChange">
        <a-radio :value="1">
          奶茶贴纸
        </a-radio>
        <a-radio :value="2">
          外卖小票
        </a-radio>
        <a-radio :value="3">
          门店发票
        </a-radio>
      </a-radio-group>
    </a-modal>
    <Receipt ref="receipt" style="display: none;" />
  </div>
</template>
<script>
import { workTableOrderList, workTableOutOrder, workTableDetailOrder, workTableDeleteOrder } from '@/api/workTable';
import { getNotice } from '@/api/index';

import { printLabel, printReceipt } from '@/libs/print';
import { mapState, mapMutations } from 'vuex';
import Receipt from '@/views/Receipt';
import TableComponent from '@/components/TableComponent';
import { setTimeout } from 'timers';
// import Template from '../template/template.vue';

const leftColumns = [
  {
    title: '取餐号',
    dataIndex: 'pickUpCode',
    scopedSlots: { customRender: 'pickUpCode' }
  },
  {
    title: '商品数量',
    dataIndex: 'num'
  },
  // {
  //   title: '下单时间',
  //   dataIndex: 'createTime'
  // },
  {
    title: '制作状态',
    dataIndex: 'orderStatus',
    scopedSlots: { customRender: 'orderStatus' }
  },
  {
    title: '配送状态',
    dataIndex: 'deliveryStatus',
    scopedSlots: { customRender: 'deliveryStatus' }
  },
  {
    title: '操作',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  }
];
const rightColumns = [
  {
    title: '取餐号',
    dataIndex: 'pickUpCode',
    scopedSlots: { customRender: 'pickUpCode' }
  },
  {
    title: '商品数量',
    dataIndex: 'num'
  },
  // {
  //   title: '下单时间',
  //   dataIndex: 'createTime'
  // },
  {
    title: '制作状态',
    dataIndex: 'orderStatus',
    scopedSlots: { customRender: 'orderStatus' }
  },
  {
    title: '操作',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  }
];
const skuColumns = [
  {
    title: '商品名称',
    dataIndex: 'name',
    key: 'name',
    width: 200
  },
  {
    title: '数量',
    dataIndex: 'num',
    key: 'num',
    width: 60
  },
  {
    title: '规格',
    key: 'spaceInfo',
    dataIndex: 'spaceInfo',
    scopedSlots: { customRender: 'specsInfo' },
    width: 150
  },
  {
    title: '小料',
    dataIndex: 'materials',
    key: 'materials',
    width: 130,
    scopedSlots: { customRender: 'materials' }
  },
  {
    title: '操作',
    key: 'action',
    dataIndex: 'action',
    width: 120,
    scopedSlots: { customRender: 'action' }
  }
];
export default {
  name: 'workTable',
  components: {
    Receipt,
    TableComponent
  },
  data() {
    return {
      detailVisible: false,
      cancelOrderLoading: false,
      printVisible: false,
      printLoading: false,
      spinning: false,
      orderMoney: '',
      orderCount: '',
      printValue: 1,
      appletOrderCount: 0,
      elemeOrderCount: 0,
      cashierOrderCount: 0,
      reason: ['我买少了', '临时有事情不想要了', '我的地址信息写错了', '送达时间选错了', '忘记使用优惠券了', '忘记使用礼品卡了', '忘记写备注了'],
      orderDataParams: {
        pageNumber: 1, // 当前页数
        pageSize: 10, // 页面大小
        storeId: '',
        platformClient: 'CASHIER' // 平台客户端标志位：ELEME-饿了么，MEITUAN-美团，WECHAT_MP-微信小程序，CASHIER-收银台
      },
      cancelReason: '',
      cancelReasonId: '',
      leftOrderTotal: 0,
      leftData: [],
      rightData: [],
      currentOrderInfo: {},
      goodsImgArr: [],
      detailType: '',
      intervalId: null,
      leftColumns,
      rightColumns,
      skuColumns,
      skuData: [],
      orderData: [],
      promotionType: '',
      clientType: 'CASHIER',
      orderDataTotal: 0,
      todayOrderTotal: 0
    };
  },
  mounted() {
    this.clientType = 'CASHIER';
    // if (this.intervalId) {
    //   clearInterval(this.intervalId);
    // }
    // // 请求订单通知
    // this.intervalId = setInterval(() => {
    //   this.getNotice();
    // }, 20000);
  },
  computed: {
    ...mapState({ currentOrder: state => state.order.currentOrder, ShopData: state => state.ShopData }),
    ...mapState(['noticeInfo'])
  },
  watch: {
    // 判断是否有新订单
    // noticeInfo: {
    //   handler(newVal, oldVal) {
    //     if (newVal.status === '1') {
    //       this.getOrderData();
    //     }
    //   }
    // },
    clientType: {
      handler(newVal, oldVal) {
        this.orderDataParams.platformClient = newVal;
        this.orderDataParams.pageNumber = 1;
        this.getOrderData();
      },
      immediate: true
    }
  },
  methods: {
    ...mapMutations(['SET_NOTICE']),
    // 获取订单通知
    getNotice() {
      getNotice().then(res => {
        if (res) {
          this.SET_NOTICE(res);
        }
      });
    },
    showOutOrderConfirm(pickUpCode, sn, type) {
      const that = this;
      this.$confirm({
        title: `确认出单?`,
        okText: '确认',
        cancelText: '取消',
        getContainer: () => (type === 'PICK_UP' ? that.$refs.right : that.$refs.left),
        content: `${type === 'PICK_UP' ? '自取' : type === 'TAKE_OUT' ? '外卖' : ''}${type ? '订单号' : '取餐号'}${type ? pickUpCode : sn}`,
        onOk() {
          that.spinning = true;
          // 出单操作
          workTableOutOrder(sn, that.clientType).then(res => {
            if (res) {
              that.$message.success('出单成功');
              // 获取订单列表
              that.getOrderData();
              // if (type === 'TAKE_OUT') {
              //   that.leftGetData();
              // } else if (type === 'PICK_UP') {
              //   that.rightGetData();
              // }
            } else {
              that.$message.warning('订单出单失败，请重试');
            }
          });
        },
        onCancel() {}
      });
    },
    getOrderDetail(sn, detailItem, callback) {
      this.spinning = true;
      workTableDetailOrder(sn, this.clientType).then(res => {
        if (res) {
          let currentOrderInfo = Object.assign({}, res);
          currentOrderInfo.storeName = this.ShopData.storeName;
          currentOrderInfo.address = this.ShopData.storeAddressDetail;
          if (this.clientType !== 'ELEME') {
            currentOrderInfo.num = currentOrderInfo.orderItems.length;
            currentOrderInfo.orderItems = currentOrderInfo.orderItems.map(label => {
              let specName = '';
              let specs = JSON.parse(label.specs);
              let spaceInfo = '';
              this.promotionType = label.promotionType || '';
              let pickUpCode = currentOrderInfo.remark ? currentOrderInfo.pickUpCode + ' 备' : currentOrderInfo.pickUpCode;
              for (const key in specs) {
                if (key !== 'images') {
                  specName += specs[key] + '';
                  spaceInfo += key + ':' + specs[key] + ';';
                }
              }
              let matirialStr = '';
              label.materials.map(matirial => {
                matirialStr += matirial.name + ' x ' + matirial.num + ' ';
              });
              return {
                ...label,
                storeName: this.ShopData.storeName,
                paymentTime: res.paymentTime,
                specName,
                matirialStr,
                pickUpCode,
                spaceInfo
              };
            });
            // 赋值详情弹出框小料列表
            this.skuData = currentOrderInfo.orderItems;

            this.$store.commit('setCurrentOrder', currentOrderInfo);
            if (callback) {
              callback(res);
            }
          } else {
            // 用于保存固定格式列表
            let skuList = [];
            JSON.parse(JSON.parse(res.goodsJson)).forEach(itemGoods => {
              // 判断多个items
              itemGoods.items.forEach(item => {
                skuList.push({
                  goodsId: item.id,
                  name: item.name,
                  num: 1,
                  specs: item.attributes && item.attributes.length ? item.attributes.map(it => it.name + ' x ' + it.value) : [],
                  materials: item.ingredients,
                  spaceInfo: item.attributes && item.attributes.length ? item.attributes.map(it => it.name + ' : ' + it.value + ';').join('') : '-',
                  total: item.total,
                  price: item.price,
                  createdTime: detailItem.createdTime || '',
                  sn: detailItem.sn || ''
                });
              });
            });
            res.orderItems = skuList;
            // 赋值详情弹出框小料列表
            this.skuData = skuList;
            this.currentOrderInfo = res;
            this.$store.commit('setCurrentOrder', { ...this.currentOrderInfo, ...detailItem });
            if (callback) {
              callback(res);
            }
          }
          this.spinning = false;
        }
      });
    },
    dealOrderDetail(res, detailType) {
      this.detailVisible = true;
      this.detailType = detailType;
      let obj = res;
      if (this.clientType !== 'ELEME') {
        // 循环过滤出图片
        this.goodsImgArr = [];
        obj.orderItems.forEach(item => {
          this.goodsImgArr.push(JSON.parse(item.specs).images[0]);
          let childObj = JSON.parse(item.specs);
          for (let k in childObj) {
            if (k === 'images') {
              delete childObj[k];
            }
            // 规格提取
          }
          item.specs = childObj;
        });
      }
      this.currentOrderInfo = obj;
    },
    toDetail(sn, detailType, detailItem) {
      this.spinning = true;
      this.getOrderDetail(sn, detailItem, data => this.dealOrderDetail(data, detailType));
    },
    print() {
      if (this.currentOrder && this.currentOrder.orderItems.length > 0) {
        let orderItems = Object.assign([], this.currentOrder.orderItems);
        let newOrderItems = [];
        let seq = 1;
        let total = 0;
        orderItems.map(item => {
          total += item.num;
        });
        orderItems.map((orderItem, index) => {
          if (orderItem.num > 1) {
            for (let i = 0; i < orderItem.num; i++) {
              let tempOrder = Object.assign({}, orderItem);
              tempOrder.seq = `${seq}/${total}`;
              newOrderItems.push(tempOrder);
              seq++;
            }
          } else {
            orderItem.seq = seq + '/' + total;
            newOrderItems.push(orderItem);
            seq++;
          }
        });
        printLabel(newOrderItems);
      }
      // todo：临时处理，待优化
      setTimeout(() => {
        console.log('准备打印完成小票：', this.currentOrder);
        this.$refs.receipt.print();
      }, 1000);
    },
    reprintLabel(item) {
      if (this.clientType !== 'ELEME') {
        let orderItem = Object.assign({}, item);
        let specName = '';
        let specs = JSON.parse(orderItem.specs);
        for (const key in specs) {
          if (key !== 'images') {
            specName += key + ':' + specs[key] + ' ';
          }
        }
        let matirialStr = '';
        orderItem.materials.map(matirial => {
          matirialStr += matirial.name + ' X ' + matirial.num + ' ';
        });
        orderItem = {
          ...orderItem,
          name: orderItem.goodsName,
          storeName: this.ShopData.storeName,
          paymentTime: item.paymentTime,
          specName,
          matirialStr,
          pickUpCode: item.pickUpCode,
          seq: ''
        };
        printLabel([orderItem]);
      } else {
        let orderItem = Object.assign({}, item);
        let matirialStr = '';
        orderItem.materials.map(matirial => {
          matirialStr += matirial.name + ' x 1';
        });
        orderItem = {
          ...orderItem,
          storeName: this.ShopData.storeName,
          paymentTime: item.createdTime,
          specName: orderItem.spaceInfo,
          matirialStr,
          pickUpCode: '',
          seq: ''
        };
        printLabel([orderItem]);
      }
    },
    reprintReceipt() {
      this.$refs.receipt.print();
    },
    // againPrint(sn) {
    //   this.getOrderDetail(sn, this.print);
    // },
    // toPrint(type, sn) {
    //   const that = this;
    //   this.$confirm({
    //     title: `确认打印?`,
    //     okText: '确认',
    //     cancelText: '取消',
    //     getContainer: () => (type === 'PICK_UP' ? that.$refs.right : that.$refs.left),
    //     content: '打印有延迟，点击确认即开始打印',
    //     onOk() {
    //       that.getOrderDetail(sn, that.print);
    //     },
    //     onCancel() {}
    //   });
    // },
    detailHandleOk() {
      // 判断取消订单原因
      if (this.detailType === 'PICK_UP' && !this.cancelReason.trim()) {
        return this.$message.warning('请选择门店订单取消原因');
      }

      // 判断是否是取消外卖订单
      if (this.detailType === 'TAKE_OUT' && !this.cancelReasonId) {
        return this.$message.warning('请输入外卖订单取消原因');
      }

      let params = {
        reason: this.cancelReason.trim() || '其他原因',
        orderSn: this.currentOrderInfo['sn']
      };
      if (this.detailType === 'TAKE_OUT') {
        params.reasonId = this.cancelReasonId;
      }

      this.cancelOrderLoading = true;
      workTableDeleteOrder(params).then(res => {
        if (res) {
          this.$message.success('订单已取消');
          this.detailVisible = false;
          this.cancelReason = '';
          this.getOrderData();
        }
        this.cancelOrderLoading = false;
      });
    },

    detailHandleCancel() {
      this.detailVisible = false;
    },
    printHandleOk() {
      if (this.printValue === 1) {
        printLabel(this.currentOrder.orderItems);
      } else if (this.printValue === 2) {
        printReceipt(this.currentOrder);
      }
    },
    printHandleCancel() {
      this.printVisible = false;
    },
    printOnChange(e) {
      this.printValue = e.target.value;
    },
    printAfterClose() {
      this.printValue = 1;
    },
    orderOnChange(pageNumber) {
      this.orderDataParams.pageNumber = pageNumber;
      this.getOrderData();
    },

    orderRefresh(clientType) {
      this.orderDataParams.pageNumber = 1;
      this.orderDataParams.platformClient = clientType;
      // 获取订单列表
      this.getOrderData();
    },
    getOrderData() {
      this.spinning = true;
      this.orderDataParams.storeId = this.ShopData.id;
      this.orderDataParams.platformClient = this.clientType;
      workTableOrderList(this.orderDataParams).then(res => {
        if (res) {
          const { pickUpOrderDTO, appletOrderCount, elemeOrderCount, cashierOrderCount } = res;
          if (this.clientType === 'ELEME') {
            pickUpOrderDTO.records.forEach(item => {
              JSON.parse(item.goodsJson).forEach(it => {
                if (it.name === '其它费用') {
                  item.num = JSON.parse(item.goodsJson).length - 1;
                } else {
                  item.num = JSON.parse(item.goodsJson).length;
                }
              });
            });
          }
          this.orderData = pickUpOrderDTO && pickUpOrderDTO.records.length ? pickUpOrderDTO.records : [];
          this.orderDataTotal = (pickUpOrderDTO && pickUpOrderDTO.total) || 0;
          this.orderDataParams.pageNumber = (pickUpOrderDTO && pickUpOrderDTO.current) || 0;
          this.cashierOrderCount = cashierOrderCount;
          this.elemeOrderCount = elemeOrderCount;
          this.appletOrderCount = appletOrderCount;
          this.dealNewOrder(this.orderData);
        }
        this.spinning = false;
      });
    },
    dealNewOrder(data) {
      let orders = this.getStore('orderMap');
      if (!orders) {
        let newOrderMap = {};
        data &&
          data.map(order => {
            order.printed = false;
            newOrderMap[order.sn] = order;
          });
        this.setStore('orderMap', newOrderMap);
      } else {
        let orderMap = JSON.parse(orders);
        let newOrder = false;
        let newOrderList = [];
        data &&
          data.map(order => {
            if (!orderMap[order.sn] || orderMap[order.sn].printed === false) {
              newOrder = true;
              order.printed = true;
              orderMap[order.sn] = order;
              newOrderList.push(order);
            }
          });
        if (newOrder) {
          // 有新订单则更新订单map并依次获取详情打印 todo: 待优化，暂时先这样，有可能打印失败，此种情况需要手工触发补打，需后面加逻辑判断打印机返回状态
          this.setStore('orderMap', orderMap);
          newOrderList.map(order => {
            this.getOrderDetail(order.sn, order, this.print);
          });
        }
      }
    },
    detailAfterClose() {
      this.detailType = '';
      this.cancelReasonId = this.cancelReason = '';
    }
  }
};
</script>
<style lang="scss" scoped>
.container {
  height: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  margin-left: 12px;
  /deep/ .ant-spin-container {
    height: 100%;
  }

  .title_box {
    display: flex;
  }
  .left,
  .right {
    flex: 1;
    height: 65px;
    padding: 5px 0 0;
    background-color: #fff;
  }
  .remarkTip {
    position: absolute;
    top: 0;
    right: 0;
    background-color: red;
    height: 22px;
    color: #fff;
    line-height: 22px;
    font-size: 12px;
    padding: 0 5px;
  }
  .money,
  .orderNum {
    padding-left: 5px;
    font-size: 18px;
  }
  .takeFoodBtn {
    padding-left: 20px;
    position: relative;
    height: 53px;
    line-height: 53px;
    font-size: 21px;
    font-family: Alibaba PuHuiTi;
    font-weight: 500;
    margin-bottom: 10px;
    cursor: pointer;
    transition: all 0.8s;
    text-align: center;
    .refresh {
      position: absolute;
      right: 10px;
      font-size: 14px;
      top: 10px;
      line-height: 34px;
    }
  }
  .takeFoodBtn.activeTab {
    color: rgba(51, 152, 154, 1);
  }
  .takeFoodBtn.activeTab::before {
    content: '';
    display: inline-block;
    position: absolute;
    bottom: 0;
    height: 6px;
    left: 50%;
    width: 90px;
    transform: translateX(-38%);
    border-radius: 10px;
    background-color: #43c2c2;
  }
  .orderList {
    height: calc(100% - 115px);
    min-height: calc(100vh - 24vh);
    overflow: auto;
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
    align-content: flex-start;
    padding: 12px;
    position: relative;
    .emptyBox {
      position: absolute;
      left: 50%;
      top: 30%;
      transform: translateX(-50%);
      div {
        margin-top: 15px;
        color: #fff;
        text-align: center;
        font-size: 18px;
      }
    }
  }
  .orderItem {
    // width: 24%;
    width: 30%;
    min-width: 30%;
    height: 250px;
    margin-bottom: 10px;
    margin: 0 20px 10px 0;
    position: relative;
    background: #fff;
    border-radius: 8px;
    .price {
      display: flex;
      justify-content: space-between;
      span {
        color: rgba(0, 0, 0, 0.65);
      }
    }
    > div {
      span {
        font-weight: 500;
        color: #000;
        font-size: 16px;
      }

      > div {
        margin-bottom: 10px;
      }
    }
    .top {
      padding: 20px 10px 0;
      > div {
        span:first-child {
          display: inline-block;
          min-width: 80px;
          color: rgba(0, 0, 0, 0.65);
        }
      }
    }
    .bottom {
      padding: 0px 10px;
    }
    .btnList {
      display: flex;
      justify-content: space-around;
      margin-top: 18px;
      > button {
        width: 30%;
        height: 44px;
      }
    }
  }
}
.listBox {
  padding: 10px 20px;
  margin: 20px 0;
  border: 1px solid;

  .listInfo {
    display: flex;
    justify-content: space-between;
    font-weight: 500;
  }

  > div:not(:last-child) {
    margin-bottom: 15px;
  }
}

.cancelReasonTip {
  color: rgba(255, 122, 69, 1);
  margin-bottom: 5px;
}
.skuInfo {
  margin-top: 10px;
  .center {
    display: flex;
    .left {
      width: 100px;
      height: 100px;
      background: #e8dddd;
      margin-right: 20px;
      border-radius: 10px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .right {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }
  }
  .name {
    font-weight: 600;
    margin: 4px 0;
    font-size: 16px;
  }
  .sku {
    div {
      display: flex;
      span:first-child {
        white-space: nowrap;
      }
    }
  }
  .sku,
  .characteristic {
    margin-bottom: 4px;
  }
  .priceInfo {
    display: flex;
    justify-content: space-between;
    .price {
      color: rgba(205, 57, 57, 1);
    }
    .num {
      display: inline-block;
      width: 86px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      border-radius: 25px;
      font-weight: 500;
      color: #72c5c7;
      background-color: #fff;
    }
  }
}
.payMoney {
  // color: rgba(205, 57, 57, 1);
  font-size: 16px;
  font-weight: 500;
  display: inline-block;
  margin-right: 40px;
}
.printModal {
  /deep/ .ant-modal-footer {
    text-align: center;
    // background-color: rgba(114, 197, 199, 1);
    border: none;
  }
  /deep/ .ant-modal-body {
    padding: 50px 12px 12px;
    // background-color: rgba(114, 197, 199, 1);
    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      > label {
        margin-bottom: 15px;
        font-size: 18px;
        display: flex;
        align-items: center;
        color: #fff;
      }
    }
  }
}
/deep/ .ant-modal-mask {
  position: absolute;
}
.detailModal {
  .goodsTitle {
    color: #33989a;
    padding: 7px 5px;
    font-size: 16px;
    background: rgba(234, 249, 249, 1);
    font-weight: 500;
    margin-bottom: 8px;
  }
  /deep/ .ant-descriptions-item-content {
    font-weight: 500;
    color: #000;
  }
  /deep/ .ant-descriptions-title {
    color: rgba(51, 152, 154, 1);
    background-color: rgba(234, 249, 249, 1);
    padding: 7px 2px;
  }
  /deep/ .ant-modal-title {
    text-align: center;
    .pickUpCode {
      float: left;
      color: rgba(51, 152, 154, 1);
    }
  }
  /deep/ .ant-modal-body {
    padding: 12px;
  }
}
.infoBox {
  padding: 0 20px 0 20px;
  .goods_price {
    color: rgba(226, 226, 226, 1);
    margin-right: 20px;
  }
  .discount {
    color: rgba(198, 72, 72, 1);
  }
  .marginTopBtm {
    margin: 12px 0;
  }
  .price_info {
    margin-top: 15px;
  }
}
.flex {
  display: flex;
  justify-content: space-between;
}
/deep/ .ant-modal-wrap {
  position: absolute;
}

.remarkBox {
  .title {
    width: 80px;
  }
}
.top_line {
  padding-top: 10px;
}
.content_box {
  overflow: auto;
  // min-height: 80vh;
  padding-bottom: 80px;
}

.ant-collapse {
  width: 100%;
}

.ant-spin-nested-loading {
  min-height: 80vh;
}
.pageBox {
  padding-top: 10px;
  padding: 10px 30px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  // background-color: #fff;

  /deep/ .ant-pagination-item,
  /deep/ .ant-pagination-prev,
  /deep/ .ant-pagination-next,
  /deep/ .ant-pagination-jump-prev,
  /deep/ .ant-pagination-jump-next {
    min-width: 44px;
    height: 44px;
    line-height: 44px;
  }
}
.btnStyle {
  height: 37px;
}
.emptyBox /deep/ .ant-btn-primary:hover,
/deep/ .ant-btn-primary:focus {
  background-color: #46bcbe;
}
</style>
