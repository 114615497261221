const label = {
  title: '标签',
  width: 400,
  height: 300,
  pageWidth: 40,
  pageHeight: 30,
  tempItems: [
    {
      type: 'braid-txt',
      isEdit: true,
      dragable: true,
      resizable: true,
      width: 257,
      height: 30,
      left: 14,
      top: 211,
      title: '饮用时间提示',
      value: '请于2小时内饮用',
      defaultValue: '请于2小时内饮用',
      name: '',
      style: { zIndex: 0, FontSize: 18, FontColor: '#000000', Bold: true, Italic: false, Underline: false, Alignment: 'left', ItemType: 0 },
      lodopStyle: { FontName: '思源黑体 CN Normal' },
      uuid: 'aa428c9a4a'
    },
    {
      type: 'braid-image',
      title: 'logo',
      isEdit: false,
      dragable: true,
      resizable: true,
      width: 115,
      height: 39,
      left: 273,
      top: 12,
      value: 'https://s0.xinhuo.life/label-logo.png',
      defaultValue: 'https://s0.xinhuo.life/label-logo.png',
      name: '',
      style: { zIndex: 0, ItemType: 0 },
      uuid: '0fe1fb9c06'
    },
    {
      type: 'braid-txt',
      isEdit: true,
      dragable: true,
      resizable: true,
      width: 376,
      height: 40,
      left: 14,
      top: 68,
      title: '商品名称',
      value: '{商品名称}',
      defaultValue: '幽兰蝶恋花酸奶',
      name: 'name',
      style: { zIndex: 0, FontSize: 22, FontColor: '#000000', Bold: true, Italic: false, Underline: false, Alignment: 'left', ItemType: 0 },
      lodopStyle: { FontName: '思源黑体 CN Normal' },
      uuid: '4d2e9cf438'
    },
    {
      type: 'braid-txt',
      isEdit: true,
      dragable: true,
      resizable: true,
      width: 287,
      height: 40,
      left: 15,
      top: 244,
      title: '付款时间',
      value: '{付款时间}',
      defaultValue: '',
      name: 'paymentTime',
      style: { zIndex: 0, FontSize: 18, FontColor: '#000000', Bold: false, Italic: false, Underline: false, Alignment: 'left', ItemType: 0 },
      lodopStyle: { FontName: '思源黑体 CN Normal' },
      uuid: '94ca8bdd61'
    },
    {
      type: 'braid-txt',
      isEdit: true,
      dragable: true,
      resizable: true,
      width: 382,
      height: 40,
      left: 15,
      top: 280,
      title: '门店名称',
      value: '{门店名称}',
      defaultValue: '',
      name: 'storeName',
      style: { zIndex: 0, FontSize: 18, FontColor: '#000000', Bold: true, Italic: false, Underline: false, Alignment: 'left', ItemType: 0 },
      lodopStyle: { FontName: '思源黑体 CN Normal' },
      uuid: '67d7e595fe'
    },
    {
      type: 'braid-txt',
      isEdit: true,
      dragable: true,
      resizable: true,
      width: 380,
      height: 77,
      left: 16,
      top: 113,
      title: '规格',
      value: '{规格}',
      defaultValue: '',
      name: 'specName',
      style: { zIndex: 0, FontSize: 18, FontColor: '#000000', Bold: false, Italic: false, Underline: false, Alignment: 'left', ItemType: 0 },
      lodopStyle: { FontName: '思源黑体 CN Normal' },
      uuid: 'db179d60e3'
    },
    {
      type: 'braid-txt',
      isEdit: true,
      dragable: true,
      resizable: true,
      width: 195,
      height: 32,
      left: 88,
      top: 0,
      title: '取餐号',
      value: '{取餐号}',
      defaultValue: '',
      name: 'pickUpCode',
      style: { zIndex: 0, FontSize: 38, FontColor: '#000000', Bold: true, Italic: false, Underline: false, Alignment: 'right', ItemType: 0 },
      lodopStyle: { FontName: '思源黑体 CN Normal' },
      uuid: 'f77a141173'
    },
    {
      type: 'braid-txt',
      isEdit: true,
      dragable: true,
      resizable: true,
      width: 371,
      height: 58,
      left: 15,
      top: 147,
      title: '小料',
      value: '{小料}',
      defaultValue: '',
      name: 'matirialStr',
      style: { zIndex: 0, FontSize: 18, FontColor: '#000000', Bold: false, Italic: false, Underline: false, Alignment: 'left', ItemType: 0 },
      lodopStyle: { FontName: '思源黑体 CN Normal' },
      uuid: '1acf6f7f44'
    },
    {
      type: 'braid-txt',
      isEdit: true,
      dragable: true,
      resizable: true,
      width: 98,
      height: 40,
      left: 312,
      top: 210,
      title: '序号',
      value: '{序号}',
      defaultValue: '1/1',
      name: 'seq',
      style: { zIndex: 0, FontSize: 18, FontColor: '#000000', Bold: true, Italic: false, Underline: false, Alignment: 'left', ItemType: 0 },
      lodopStyle: { FontName: '思源黑体 CN Normal' },
      uuid: '230c73f6e8'
    }
  ]
};

const widgetOptions = [
  {
    type: 'braid-txt',
    title: '饮用时间提示',
    value: '请于2小时内饮用',
    defaultValue: '请于2小时内饮用'
  },
  {
    type: 'braid-image',
    title: '标签logo',
    value: 'https://s0.xinhuo.life/label-logo.png',
    defaultValue: 'https://s0.xinhuo.life/label-logo.png'
  },
  {
    type: 'braid-image',
    title: '收据logo',
    value: 'https://s0.xinhuo.life/receipt-logo.png',
    defaultValue: 'https://s0.xinhuo.life/receipt-logo.png'
  },
  {
    type: 'braid-txt',
    title: '商品名称',
    value: '{商品名称}',
    defaultValue: '',
    name: 'name',
    lodopStyle: {
      FontName: '思源黑体 CN Normal'
    }
  },
  {
    type: 'braid-txt',
    title: '序号',
    value: '{序号}',
    defaultValue: '1/1',
    name: 'seq',
    lodopStyle: {
      FontName: '思源黑体 CN Normal'
    }
  },
  {
    type: 'braid-txt',
    title: '规格',
    value: '{规格}',
    defaultValue: ' ',
    name: 'specName',
    lodopStyle: {
      FontName: '思源黑体 CN Normal'
    }
  },
  {
    type: 'braid-txt',
    title: '小料',
    value: '{小料}',
    defaultValue: '',
    name: 'matirialStr',
    lodopStyle: {
      FontName: '思源黑体 CN Normal'
    }
  },
  {
    type: 'braid-txt',
    title: '付款时间',
    value: '{付款时间}',
    defaultValue: '',
    name: 'paymentTime',
    lodopStyle: {
      FontName: '思源黑体 CN Normal'
    }
  },
  {
    type: 'braid-txt',
    title: '门店名称',
    value: '{门店名称}',
    defaultValue: '',
    name: 'storeName',
    lodopStyle: {
      FontName: '思源黑体 CN Normal'
    }
  },
  {
    type: 'braid-txt',
    title: '门店地址',
    value: '{门店地址}',
    defaultValue: '',
    name: 'address',
    lodopStyle: {
      FontName: '思源黑体 CN Normal'
    }
  },
  {
    type: 'braid-txt',
    title: '取餐号',
    value: '{取餐号}',
    defaultValue: '',
    name: 'pickUpCode',
    lodopStyle: {
      FontName: '思源黑体 CN Normal'
    }
  },
  {
    type: 'braid-txt',
    title: '订单状态',
    value: '{订单状态}',
    defaultValue: '',
    name: 'status',
    lodopStyle: {
      FontName: '思源黑体 CN Normal'
    }
  },
  {
    type: 'braid-txt',
    title: '商品单价',
    value: '{商品单价}',
    defaultValue: '',
    name: 'unitPrice',
    lodopStyle: {
      FontName: '思源黑体 CN Normal'
    }
  },
  {
    type: 'braid-txt',
    title: '商品数量',
    value: '{商品数量}',
    defaultValue: '',
    name: 'num',
    lodopStyle: {
      FontName: '思源黑体 CN Normal'
    }
  },
  {
    type: 'braid-txt',
    title: '商品小计',
    value: '{商品小计}',
    defaultValue: '',
    name: 'subTotal',
    lodopStyle: {
      FontName: '思源黑体 CN Normal'
    }
  },
  {
    type: 'braid-txt',
    title: '折扣',
    value: '{折扣}',
    defaultValue: 0,
    name: 'discountPrice',
    lodopStyle: {
      FontName: '思源黑体 CN Normal'
    }
  },
  {
    type: 'braid-txt',
    title: '优惠券',
    value: '{优惠券}',
    defaultValue: 0,
    name: 'couponPrice',
    lodopStyle: {
      FontName: '思源黑体 CN Normal'
    }
  },
  {
    type: 'braid-txt',
    title: '礼品卡',
    value: '{礼品卡}',
    defaultValue: 0,
    name: 'giftCardPrice',
    lodopStyle: {
      FontName: '思源黑体 CN Normal'
    }
  },
  {
    type: 'braid-txt',
    title: '合计',
    value: '{合计}',
    defaultValue: '',
    name: 'flowPrice',
    lodopStyle: {
      FontName: '思源黑体 CN Normal'
    }
  },
  {
    type: 'braid-txt',
    title: '自定义文本',
    value: '{自定义文本}',
    defaultValue: '',
    name: '',
    lodopStyle: {
      FontName: '思源黑体 CN Normal'
    }
  },
  {
    type: 'braid-image',
    title: '公众号二维码',
    value: 'https://s0.xinhuo.life/qrcode.jpg',
    defaultValue: 'https://s0.xinhuo.life/qrcode.jpg'
  },
  {
    type: 'braid-txt',
    title: '全宽分割线',
    value: '---------------------------------------------------------------------------------------------------------------------------------',
    defaultValue: '---------------------------------------------------------------------------------------------------------------------------------',
    name: '',
    lodopStyle: {
      FontName: '思源黑体 CN Normal'
    }
  },
  {
    type: 'braid-table',
    title: '商品明细',
    name: 'orderItems',
    value: '{orderItems}',
    defaultValue: [
      {
        name: '幽兰蝶恋花酸奶1',
        unitPrice: 36,
        num: 1,
        subTotal: 36
      }
    ],
    columnsAttr: [
      { title: '商品Id', value: '{商品Id}', name: 'goodsId' },
      { title: '小料', value: '{小料}', name: 'materials' },
      { title: '商品', value: '{商品}', name: 'name' },
      { title: '数量', value: '{数量}', name: 'num' },
      { title: '付款时间', value: '{付款时间}', name: 'paymentTime' },
      { title: '规格', value: '{规格}', name: 'specName' },
      { title: '店名', value: '{店名}', name: 'storeName' },
      { title: '单价', value: '{单价}', name: 'unitPrice' },
      { title: '价格￥', value: '{价格￥}', name: 'subTotal' }
    ]
  }
];

const receipt = {
  title: '收据',
  width: 800,
  height: 2240,
  pageWidth: 80,
  pageHeight: 224,
  tempItems: [
    {
      type: 'braid-txt',
      isEdit: true,
      dragable: true,
      resizable: true,
      width: 735,
      height: 40,
      left: 45,
      top: 1919,
      title: '付款时间',
      value: '下单：{付款时间}',
      defaultValue: '',
      name: 'paymentTime',
      style: { zIndex: 0, FontSize: 23, FontColor: '#2C2B25', Bold: false, Italic: false, Underline: false, Alignment: 'left', ItemType: 0 },
      uuid: '94ca8bdd61',
      lodopStyle: { FontName: '思源黑体 CN Normal' }
    },
    {
      type: 'braid-image',
      title: '收据logo',
      isEdit: false,
      dragable: true,
      resizable: true,
      width: 220,
      height: 95,
      left: 13,
      top: 15,
      value: 'https://s0.xinhuo.life/receipt-logo.png',
      defaultValue: 'https://s0.xinhuo.life/receipt-logo.png',
      name: '',
      style: { zIndex: 0, ItemType: 0 },
      uuid: '0cfabbec6e'
    },
    {
      type: 'braid-txt',
      isEdit: true,
      dragable: true,
      resizable: true,
      width: 255,
      height: 40,
      left: 282,
      top: 138,
      title: '订单状态',
      value: '订单已付款',
      defaultValue: '',
      name: 'status',
      style: { zIndex: 0, FontSize: 30, FontColor: '#000000', Bold: true, Italic: false, Underline: false, Alignment: 'left', ItemType: 0 },
      lodopStyle: { FontName: '思源黑体 CN Normal' },
      uuid: 'a79b128446'
    },
    {
      type: 'braid-txt',
      isEdit: true,
      dragable: true,
      resizable: true,
      width: 779,
      height: 21,
      left: 7,
      top: 327,
      title: '全宽分割线',
      value:
        '--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------',
      defaultValue: '-------------------------------------',
      name: '',
      style: { zIndex: 0, FontSize: 9, FontColor: '#000000', Bold: false, Italic: false, Underline: false, Alignment: 'left', ItemType: 0 },
      lodopStyle: { FontName: '思源黑体 CN Normal' },
      uuid: '1bda069604'
    },
    {
      type: 'braid-txt',
      isEdit: true,
      dragable: true,
      resizable: true,
      width: 366,
      height: 40,
      left: 27,
      top: 258,
      title: '取餐号',
      value: '取餐号：{取餐号}',
      defaultValue: '',
      name: 'pickUpCode',
      style: { zIndex: 0, FontSize: 24, FontColor: '#000000', Bold: true, Italic: false, Underline: false, Alignment: 'left', ItemType: 0 },
      lodopStyle: { FontName: '思源黑体 CN Normal' },
      uuid: '35845c61a6'
    },
    {
      type: 'braid-txt',
      isEdit: true,
      dragable: true,
      resizable: true,
      width: 787,
      height: 21,
      left: 5,
      top: 211,
      title: '全宽分割线',
      value:
        '----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------',
      defaultValue: '-------------------------------------',
      name: '',
      style: { zIndex: 0, FontSize: 9, FontColor: '#000000', Bold: false, Italic: false, Underline: false, Alignment: 'left', ItemType: 0 },
      lodopStyle: { FontName: '思源黑体 CN Normal' },
      uuid: '36c43c689e'
    },
    {
      type: 'braid-txt',
      isEdit: true,
      dragable: true,
      resizable: true,
      width: 786,
      height: 26,
      left: 6,
      top: 750,
      title: '全宽分割线',
      value:
        '---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------',
      defaultValue: '-------------------------------------',
      name: '',
      style: { zIndex: 0, FontSize: 9, FontColor: '#000000', Bold: false, Italic: false, Underline: false, Alignment: 'left', ItemType: 0 },
      lodopStyle: { FontName: '思源黑体 CN Normal' },
      uuid: '1b2a58a833'
    },
    {
      type: 'braid-image',
      title: '公众号二维码',
      isEdit: false,
      dragable: true,
      resizable: true,
      width: 311,
      height: 288,
      left: 229,
      top: 782,
      value: 'https://s0.xinhuo.life/qrcode.jpg',
      defaultValue: 'https://s0.xinhuo.life/qrcode.jpg',
      name: '',
      style: { zIndex: 0, ItemType: 0 },
      uuid: '23791bbfa1'
    },
    {
      type: 'braid-txt',
      isEdit: true,
      dragable: true,
      resizable: true,
      width: 274,
      height: 40,
      left: 17,
      top: 1145,
      title: '自定义文本',
      value: '【格格说的承诺】',
      defaultValue: '',
      name: '',
      style: { zIndex: 0, FontSize: 21, FontColor: '#000000', Bold: true, Italic: false, Underline: false, Alignment: 'left', ItemType: 0 },
      lodopStyle: { FontName: '思源黑体 CN Normal' },
      uuid: '6103f8ab69'
    },
    {
      type: 'braid-txt',
      isEdit: true,
      dragable: true,
      resizable: true,
      width: 762,
      height: 160,
      left: 18,
      top: 1197,
      title: '自定义文本',
      value:
        '格格说采用草原牧场奶源生牛乳发酵的酸奶，坚持全产业链冷链配送。我们坚持使用鲜果、鲜牛乳、新鲜酸奶等原材料现点现制，食材制作过程中难免口味有差异，如果您对出品有任何不满意，联系店内小伙伴或到任意门店，我们会立即为您免费重新制作一杯。',
      defaultValue: '',
      name: '',
      style: { zIndex: 0, FontSize: 20, FontColor: '#000000', Bold: false, Italic: false, Underline: false, Alignment: 'left', ItemType: 0 },
      lodopStyle: { FontName: '思源黑体 CN Normal' },
      uuid: '6e5c1a8ec2'
    },
    {
      type: 'braid-txt',
      isEdit: true,
      dragable: true,
      resizable: true,
      width: 636,
      height: 40,
      left: 14,
      top: 1396,
      title: '自定义文本',
      value: '【格格说，奶源来自大草原】',
      defaultValue: '',
      name: '',
      style: { zIndex: 0, FontSize: 21, FontColor: '#000000', Bold: true, Italic: false, Underline: false, Alignment: 'left', ItemType: 0 },
      lodopStyle: { FontName: '思源黑体 CN Normal' },
      uuid: 'f0ce7412a5'
    },
    {
      type: 'braid-txt',
      isEdit: true,
      dragable: true,
      resizable: true,
      width: 737,
      height: 37,
      left: 19,
      top: 1441,
      title: '自定义文本',
      value: '欢迎您来喝这一杯格格说酸奶！',
      defaultValue: '',
      name: '',
      style: { zIndex: 0, FontSize: 20, FontColor: '#000000', Bold: false, Italic: false, Underline: false, Alignment: 'left', ItemType: 0 },
      lodopStyle: { FontName: '思源黑体 CN Normal' },
      uuid: 'd6310b2b5f'
    },
    {
      type: 'braid-txt',
      isEdit: true,
      dragable: true,
      resizable: true,
      width: 768,
      height: 124,
      left: 16,
      top: 1489,
      title: '自定义文本',
      value:
        '格格说是始创于上海的新中式创意酸奶茶饮品牌。2021年我们创造了第一杯酸奶格乐冰，随后我们又创造出【国风意境酸奶】【格格家的牛乳茶】等多款高人气心意饮品！',
      defaultValue: '',
      name: '',
      style: { zIndex: 0, FontSize: 20, FontColor: '#000000', Bold: false, Italic: false, Underline: false, Alignment: 'left', ItemType: 0 },
      lodopStyle: { FontName: '思源黑体 CN Normal' },
      uuid: '7bdd074914'
    },
    {
      type: 'braid-txt',
      isEdit: true,
      dragable: true,
      resizable: true,
      width: 281,
      height: 40,
      left: 271,
      top: 2117,
      title: '自定义文本',
      value: '欢迎下次光临',
      defaultValue: '',
      name: '',
      style: { zIndex: 0, FontSize: 23, FontColor: '#000000', Bold: false, Italic: false, Underline: false, Alignment: 'center', ItemType: 0 },
      lodopStyle: { FontName: '思源黑体 CN Normal' },
      uuid: '7bdd074912'
    },
    {
      type: 'braid-txt',
      isEdit: true,
      dragable: true,
      resizable: true,
      width: 788,
      height: 40,
      left: 7,
      top: 508,
      title: '全宽分割线',
      value:
        '--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------',
      defaultValue:
        '---------------------------------------------------------------------------------------------------------------------------------',
      name: '',
      style: { zIndex: 0, FontSize: 9, FontColor: '#000000', Bold: false, Italic: false, Underline: false, Alignment: 'left', ItemType: 0 },
      lodopStyle: { FontName: '思源黑体 CN Normal' },
      uuid: '18385bbf8a'
    },
    {
      type: 'braid-txt',
      isEdit: true,
      dragable: true,
      resizable: true,
      width: 127,
      height: 40,
      left: 666,
      top: 543,
      title: '折扣',
      value: '- {折扣}',
      defaultValue: '0',
      name: 'discountPrice',
      style: { zIndex: 0, FontSize: 23, FontColor: '#000000', Bold: false, Italic: false, Underline: false, Alignment: 'left', ItemType: 0 },
      lodopStyle: { FontName: '思源黑体 CN Normal' },
      uuid: '66dfd993a9'
    },
    {
      type: 'braid-txt',
      isEdit: true,
      dragable: true,
      resizable: true,
      width: 120,
      height: 40,
      left: 684,
      top: 682,
      title: '合计',
      value: '{合计}',
      defaultValue: '0',
      name: 'flowPrice',
      style: { zIndex: 0, FontSize: 23, FontColor: '#000000', Bold: false, Italic: false, Underline: false, Alignment: 'left', ItemType: 0 },
      lodopStyle: { FontName: '思源黑体 CN Normal' },
      uuid: '32f461db26'
    },
    {
      type: 'braid-txt',
      isEdit: true,
      dragable: true,
      resizable: true,
      width: 120,
      height: 40,
      left: 16,
      top: 538,
      title: '自定义文本',
      value: '折扣：',
      defaultValue: '0',
      name: '',
      style: { zIndex: 0, FontSize: 23, FontColor: '#000000', Bold: true, Italic: false, Underline: false, Alignment: 'left', ItemType: 0 },
      lodopStyle: { FontName: '思源黑体 CN Normal' },
      uuid: 'bd3fff2fd8'
    },
    {
      type: 'braid-txt',
      isEdit: true,
      dragable: true,
      resizable: true,
      width: 148,
      height: 40,
      left: 13,
      top: 680,
      title: '自定义文本',
      value: '合计：',
      defaultValue: '0',
      name: '',
      style: { zIndex: 0, FontSize: 23, FontColor: '#000000', Bold: true, Italic: false, Underline: false, Alignment: 'left', ItemType: 0 },
      lodopStyle: { FontName: '思源黑体 CN Normal' },
      uuid: '2a68a908fb'
    },
    {
      type: 'braid-table',
      isEdit: false,
      dragable: true,
      resizable: true,
      width: 776,
      height: 134,
      left: 10,
      top: 370,
      title: '商品明细',
      value: '{orderItems}',
      defaultValue: [{ name: '幽兰蝶恋花酸奶1', unitPrice: 36, num: 1, subTotal: 36 }],
      tabelHtml: '',
      columnsAttr: [
        { title: '商品Id', value: '{商品Id}', name: 'goodsId' },
        { title: '小料', value: '{小料}', name: 'materials' },
        { title: '商品', value: '{商品}', name: 'name' },
        { title: '数量', value: '{数量}', name: 'num' },
        { title: '付款时间', value: '{付款时间}', name: 'paymentTime' },
        { title: '规格', value: '{规格}', name: 'specName' },
        { title: '店名', value: '{店名}', name: 'storeName' },
        { title: '单价', value: '{单价}', name: 'unitPrice' },
        { title: '价格￥', value: '{价格￥}', name: 'subTotal' }
      ],
      columns: [
        { title: '商品', value: '{商品}', name: 'name' },
        { title: '单价', value: '{单价}', name: 'unitPrice' },
        { title: '数量', value: '{数量}', name: 'num' },
        { title: '价格￥', value: '{价格￥}', name: 'subTotal' }
      ],
      selectCol: ['name', 'unitPrice', 'num', 'subTotal'],
      name: 'orderItems',
      style: { zIndex: 0, Alignment: 'center', FontSize: 22, FontColor: '#000000', BorderColor: '#FFFFFF', AutoHeight: false, BottomMargin: 0 },
      uuid: 'fff7da5415'
    },
    {
      type: 'braid-txt',
      isEdit: true,
      dragable: true,
      resizable: true,
      width: 156,
      height: 40,
      left: 667,
      top: 591,
      title: '优惠券',
      value: '- {优惠券}',
      defaultValue: 0,
      name: 'couponPrice',
      style: { zIndex: 0, FontSize: 23, FontColor: '#000000', Bold: false, Italic: false, Underline: false, Alignment: 'left', ItemType: 0 },
      lodopStyle: { FontName: '思源黑体 CN Normal' },
      uuid: '13d2418796'
    },
    {
      type: 'braid-txt',
      isEdit: true,
      dragable: true,
      resizable: true,
      width: 162,
      height: 40,
      left: 667,
      top: 639,
      title: '礼品卡',
      value: '- {礼品卡}',
      defaultValue: 0,
      name: 'giftCardPrice',
      style: { zIndex: 0, FontSize: 23, FontColor: '#000000', Bold: false, Italic: false, Underline: false, Alignment: 'left', ItemType: 0 },
      lodopStyle: { FontName: '思源黑体 CN Normal' },
      uuid: '60ee83ea23'
    },
    {
      type: 'braid-txt',
      isEdit: true,
      dragable: true,
      resizable: true,
      width: 145,
      height: 40,
      left: 14,
      top: 584,
      title: '自定义文本',
      value: '优惠券：',
      defaultValue: '',
      name: '',
      style: { zIndex: 0, FontSize: 23, FontColor: '#000000', Bold: true, Italic: false, Underline: false, Alignment: 'left', ItemType: 0 },
      lodopStyle: { FontName: '思源黑体 CN Normal' },
      uuid: '2c8c3001e1'
    },
    {
      type: 'braid-txt',
      isEdit: true,
      dragable: true,
      resizable: true,
      width: 149,
      height: 40,
      left: 13,
      top: 632,
      title: '自定义文本',
      value: '礼品卡：',
      defaultValue: '',
      name: '',
      style: { zIndex: 0, FontSize: 22, FontColor: '#000000', Bold: true, Italic: false, Underline: false, Alignment: 'left', ItemType: 0 },
      lodopStyle: { FontName: '思源黑体 CN Normal' },
      uuid: 'b39b86710d'
    },
    {
      type: 'braid-txt',
      isEdit: true,
      dragable: true,
      resizable: true,
      width: 173,
      height: 23,
      left: 28,
      top: 1090,
      title: '全宽分割线',
      value: '-----------------------------------------',
      defaultValue:
        '---------------------------------------------------------------------------------------------------------------------------------',
      name: '',
      style: { zIndex: 0, FontSize: 9, FontColor: '#000000', Bold: false, Italic: false, Underline: false, Alignment: 'left', ItemType: 0 },
      lodopStyle: { FontName: '思源黑体 CN Normal' },
      uuid: '3c9ab41fef'
    },
    {
      type: 'braid-txt',
      isEdit: true,
      dragable: true,
      resizable: true,
      width: 347,
      height: 40,
      left: 222,
      top: 1078,
      title: '自定义文本',
      value: '更多优惠请关注官方公众号',
      defaultValue: '',
      name: '',
      style: { zIndex: 0, FontSize: 20, FontColor: '#000000', Bold: false, Italic: false, Underline: false, Alignment: 'left', ItemType: 0 },
      lodopStyle: { FontName: '思源黑体 CN Normal' },
      uuid: '4427b376c0'
    },
    {
      type: 'braid-txt',
      isEdit: true,
      dragable: true,
      resizable: true,
      width: 214,
      height: 29,
      left: 560,
      top: 1090,
      title: '全宽分割线',
      value: '---------------------------------------------------',
      defaultValue:
        '---------------------------------------------------------------------------------------------------------------------------------',
      name: '',
      style: { zIndex: 0, FontSize: 9, FontColor: '#000000', Bold: false, Italic: false, Underline: false, Alignment: 'left', ItemType: 0 },
      lodopStyle: { FontName: '思源黑体 CN Normal' },
      uuid: '729a41421d'
    },
    {
      type: 'braid-txt',
      isEdit: true,
      dragable: true,
      resizable: true,
      width: 642,
      height: 87,
      left: 97,
      top: 2021,
      title: '门店地址',
      value: '{门店地址}',
      defaultValue: '',
      name: 'address',
      style: { zIndex: 0, FontSize: 20, FontColor: '#000000', Bold: false, Italic: false, Underline: false, Alignment: 'center', ItemType: 0 },
      lodopStyle: { FontName: '思源黑体 CN Normal' },
      uuid: '543dc53a96'
    },
    {
      type: 'braid-txt',
      isEdit: true,
      dragable: true,
      resizable: true,
      width: 539,
      height: 40,
      left: 280,
      top: 31,
      title: '门店名称',
      value: '【{门店名称}】',
      defaultValue: '',
      name: 'storeName',
      style: { zIndex: 0, FontSize: 25, FontColor: '#000000', Bold: true, Italic: false, Underline: false, Alignment: 'left', ItemType: 0 },
      lodopStyle: { FontName: '思源黑体 CN Normal' },
      uuid: '62678eb661'
    },
    {
      type: 'braid-txt',
      isEdit: true,
      dragable: true,
      resizable: true,
      width: 774,
      height: 167,
      left: 17,
      top: 1616,
      title: '自定义文本',
      value:
        '格格说关怀新时代养生青年的声音与诉求，让每一杯美好的饮品，都承载满满的关怀心意。格格说的每一杯饮品都有料、有趣、有态度，诠释一种新式朋克养生的年轻态度。让精神放松点，让生活健康化，一杯好酸奶，让你每天拥有元气满满的好脸色～',
      defaultValue: '',
      name: '',
      style: { zIndex: 0, FontSize: 20, FontColor: '#000000', Bold: false, Italic: false, Underline: false, Alignment: 'left', ItemType: 0 },
      lodopStyle: { FontName: '思源黑体 CN Normal' },
      uuid: '2a90541e0b'
    },
    {
      type: 'braid-txt',
      isEdit: true,
      dragable: true,
      resizable: true,
      width: 754,
      height: 40,
      left: 14,
      top: 1780,
      title: '自定义文本',
      value: '诸位小伙伴～以后请多关照啦～',
      defaultValue: '',
      name: '',
      style: { zIndex: 0, FontSize: 20, FontColor: '#000000', Bold: false, Italic: false, Underline: false, Alignment: 'left', ItemType: 0 },
      lodopStyle: { FontName: '思源黑体 CN Normal' },
      uuid: '888ba2d5c7'
    }
  ]
};
export { label, widgetOptions, receipt };
