<template name="component-name">
  <div class="box">
    <!-- 修改表格 -->
    <a-table :columns="columns" :data-source="data" :loading="loading" :pagination="false">
      <span slot="pickUpCode" slot-scope="text, record">
        <div>
          <span>{{ text }}</span>
          <a-icon type="message" v-if="record.remark" />
          <!-- <a-icon type="message" class="messageIcon" /> -->
        </div>
      </span>
      <span slot="deliveryStatus" slot-scope="text">
        <span v-if="text == 1">待接单</span>
        <span v-else-if="text == 2">待取货</span>
        <span v-else-if="text == 3">配送中</span>
        <span v-else-if="text == 4">已完成</span>
        <span v-else-if="text == 5">已取消</span>
        <span v-else-if="text == 8">指派单</span>
        <span v-else-if="text == 9">妥投异常之物品返回中</span>
        <span v-else-if="text == 10">妥投异常之物品返回完成</span>
        <span v-else-if="text == 100">骑士到店</span>
        <span v-else-if="text == 1000">创建达达运单失败</span>
        <span v-else>无</span>
      </span>
      <span slot="orderStatus" slot-scope="text">
        <span v-if="text === 'PAID'">已下单</span>
        <span v-else-if="text === 'UNPAID'">待支付</span>
        <span v-else-if="text === 'PRODUCTION'">制作中</span>
        <span v-else-if="text === 'COMPLETED'">已完成</span>
        <span v-else-if="text === 'PRODUCED'">制作完成</span>
        <span v-else-if="text === 'CANCELLED'">已取消</span>
        <span v-else-if="text === 'REFUNDED'">已退款</span>
        <span v-else>无</span>
      </span>
      <span slot="action" slot-scope="text, record">
        <a-button type="primary" size="small" class="outOrder" @click="showOutOrderConfirm(record.pickUpCode, record.sn, record.orderType)">
          出单
        </a-button>
        <a-button class="resetBtn" size="small" @click="toDetail(record.sn, record.orderType)">
          查看
        </a-button>
      </span>
    </a-table>
    <a-pagination :current="currentPage" :total="totalPage" @change="pageOnChange" :hideOnSinglePage="true" style="marginTop: 20px" />
  </div>
</template>
<script>
export default {
  props: {
    columns: {
      type: Array,
      default: () => {
        return [];
      }
    },
    loading: {
      type: Boolean,
      default: false
    },
    data: {
      type: Array,
      default: () => {
        return [];
      }
    },
    currentPage: {
      type: Number,
      default: 0
    },
    totalPage: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {};
  },
  methods: {
    pageOnChange(pageNumber) {
      this.$emit('pageChange', pageNumber);
    },
    toDetail(sn, detailType) {
      this.$emit('toDetail', sn, detailType);
    },
    showOutOrderConfirm(pickUpCode, sn, detailType) {
      this.$emit('showOutOrderConfirm', pickUpCode, sn, detailType);
    }
  }
};
</script>
<style lang="scss" scoped>
.box {
  width: 100%;
}
.outOrder {
  margin-right: 4px;
}
.messageIcon {
  vertical-align: super;
  color: red;
  margin-left: 5px;
}
</style>
